import '../../assets/css/signUp.css';

import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import IconLogo from '@/assets/svg/IconLogo';
import { useSubscriptionState } from '@/context/Subscription';

const ProtectionBadge: FC = () => {
  return (
    <div className='relative h-auto'>
      <div className='w-auto' id='stars-wrapper'>
        <img src='/img/stars.svg' />
      </div>
      <div className='w-auto' id='protection-badge-wrapper'>
        <img src='/img/protection.svg' />
      </div>
    </div>
  );
};

const ManInsideCar: FC = () => <img id='man-in-car-img' src='/img/manCarLarge.svg' alt='' />;

const SignUpLayout: FC = ({ children }) => {
  const { t } = useTranslation();
  const [{ driver, quote_pricing }] = useSubscriptionState();

  return (
    <>
      {(driver || quote_pricing) && (
        <div
          className='py-7 px-6 sm:px-14 md:pt-24 md:pr-0 md:pl-14 lg:pl-32 min-h-screen'
          id='signup-wrapper-container'
        >
          <div className='flex md:grid md:grid-cols-2 md:gap-x-0 lg:gap-x-0'>
            <div className='w-full signup-form-wrapper'>
              <div className='flex justify-between items-center'>
                <IconLogo width={165} height={48} />
                <div className='flex sm:hidden gap-x-6 mr-3.5 min-h-min'>
                  <img src='/img/stars.svg' />
                  <img src='/img/protection.svg' />
                </div>
              </div>
              <div className='mt-10 w-full'>
                <h1>{t('signUp.header')}</h1>
                <div className='mt-2'>
                  <p className='mb-2'>
                    {t('signUp.description', {
                      firstName: driver?.qt_first_name || quote_pricing?.quote_out?.qt_first_name,
                    })}
                  </p>
                  <p>{t('signUp.subDescription')}</p>
                </div>
              </div>
              {children}
            </div>

            {/* Right section */}
            <div className=' hidden md:flex md:flex-col md:justify-center'>
              <div className='flex flex-row justify-end items-end'>
                <div className='flex justify-end items-end'>
                  <div className=' w-auto'>
                    <ProtectionBadge />
                    <ManInsideCar />
                  </div>
                  <div className='flex'>
                    <img src='/img/building.svg' alt='building-image' />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default SignUpLayout;
