import { FC } from 'react';

import Progress from './Progress';

interface HeaderWithProgressProps {
  from: number;
  to: number;
}

const HeaderWithProgress: FC<HeaderWithProgressProps> = ({ from, to, children }) => {
  return (
    <div className='md:flex justify-between items-end md:space-x-4'>
      <h2 className='text-lg sm:text-xl'>{children}</h2>
      <Progress from={from} to={to} />
    </div>
  );
};

export default HeaderWithProgress;
