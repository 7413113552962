const IconMessage = ({ width = 24, height = 24 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox={'0 0 24 24'}
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M7 10L10.0813 12.3758C10.5956 12.7723 11.2247 12.9913 11.874 12.9998C12.5234 13.0083 13.158 12.8058 13.6824 12.4228L17 10'
        stroke='#F45B69'
        strokeWidth='1.8'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M16 3.5H8C5.23858 3.5 3 5.73858 3 8.5V15.5C3 18.2614 5.23858 20.5 8 20.5H16C18.7614 20.5 21 18.2614 21 15.5V8.5C21 5.73858 18.7614 3.5 16 3.5Z'
        stroke='#1E2044'
        strokeWidth='1.8'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export default IconMessage;
