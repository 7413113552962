const IconClipboard = ({ width = 16, height = 16 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox={'0 0 16 16'}
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M10.0026 2H6.0026C5.63441 2 5.33594 2.29848 5.33594 2.66667V4C5.33594 4.36819 5.63441 4.66667 6.0026 4.66667H10.0026C10.3708 4.66667 10.6693 4.36819 10.6693 4V2.66667C10.6693 2.29848 10.3708 2 10.0026 2Z'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M10.668 3.33337H12.0013C12.3549 3.33337 12.6941 3.47385 12.9441 3.7239C13.1942 3.97395 13.3346 4.31309 13.3346 4.66671V12.6667C13.3346 13.0203 13.1942 13.3595 12.9441 13.6095C12.6941 13.8596 12.3549 14 12.0013 14H4.0013C3.64768 14 3.30854 13.8596 3.05849 13.6095C2.80844 13.3595 2.66797 13.0203 2.66797 12.6667V4.66671C2.66797 4.31309 2.80844 3.97395 3.05849 3.7239C3.30854 3.47385 3.64768 3.33337 4.0013 3.33337H5.33464'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export default IconClipboard;
