import React, { createContext, useContext } from 'react';

import useLocalStorage from '../hooks/useLocalStorage';

export type Auth = {
  isLogged: boolean;
  isReady: boolean;
  token: string | undefined;
  driver_id: number | undefined;
};

const storageKey = 'authState';

export const initialAuthState: Auth = {
  isLogged: false,
  isReady: true,
  token: undefined,
  driver_id: undefined,
};

type AuthContextType = readonly [Auth, (value: Auth | ((val: Auth) => Auth)) => void];
export const AuthStateContext = createContext<AuthContextType>([initialAuthState, () => {}]);

export const AuthProvider: React.FC = ({ children }) => {
  const [authState, setAuthState] = useLocalStorage<Auth>(storageKey, initialAuthState);
  const setAuthStateWithSegment = (auth: Auth | ((q: Auth) => Auth)) => {
    let authValue;
    if (typeof auth === 'function') {
      authValue = auth(authState);
    } else {
      authValue = auth;
    }
    // window.analytics.track(`auth`, {
    //   ts: new Date().toISOString(),
    //   ...authValue
    // });
    setAuthState(authValue);
  };
  return (
    <AuthStateContext.Provider value={[authState, setAuthStateWithSegment]}>
      {children}
    </AuthStateContext.Provider>
  );
};

export const useAuth = () => useContext(AuthStateContext);
