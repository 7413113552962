import { ActionListItem, ModalSlider, Select, TextField } from '@flitter-insurance/nessui';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useDashboardState } from '@/context/DashBoard';
import useCourrierStatus, {
  CourrierStatusDataResult,
} from '@/hooks/api/dashboard/useCourrierStatus';
import useUpdatePolicy from '@/hooks/api/dashboard/useUpdatePolicy';
import useInsurer from '@/hooks/api/useInsurer';

import { getStoredValue } from '../../../../hooks/useLocalStorage';
import WhiteCard from '../_shared/WhiteCard';
import HeaderWithProgress from './HeaderWithProgress';

const ContractCancel = (props: any) => {
  const { t } = useTranslation();
  const { letterSent, contractCancelled } = props;
  const [{ policyData }] = useDashboardState();

  const [infoComplete, setInfoComplete] = useState<boolean>(
    policyData?.policy_out?.uw_past_insurer_name !== undefined &&
      policyData?.policy_out?.uw_past_insurer_name !== null &&
      policyData?.policy_out?.uw_past_insurer_policy_id !== undefined &&
      policyData?.policy_out?.uw_past_insurer_policy_id !== null
  );
  const [letterDone, setLetterDone] = useState<boolean>(
    policyData?.policy_out?.uw_is_cancelationdone !== undefined &&
      policyData?.policy_out?.uw_is_cancelationdone !== null &&
      policyData?.policy_out?.uw_is_cancelationdone !== false
  );
  const { data: courrierData, isLoading: isLoadingCourrier } = useCourrierStatus({
    policy_id: policyData?.policy_out.id as number,
  });

  const [openModal, setOpenModal] = useState(false);

  let nbItemsDone = 0;
  if (infoComplete) nbItemsDone++;
  if (letterDone) nbItemsDone++;
  if (letterSent) nbItemsDone++;
  if (contractCancelled) nbItemsDone++;

  const onCloseModal = () => {
    setOpenModal(false);
  };
  const onOpenModal = () => {
    setOpenModal(true);
  };

  function isContractCanceled(data: CourrierStatusDataResult | undefined): boolean {
    if (data !== undefined) {
      const newData = data.status.filter((x) => x.status === 'letter.distributed');
      return newData.length > 0;
    } else return false;
  }

  /*
  function isSent(data: Array<CourrierStatus>): boolean {
    data.filter((x) => x.status === 'letter.created');
    return data.length > 0;
  }
  */

  return (
    <>
      {!isLoadingCourrier && (
        <div>
          <div className='mb-4'>
            <HeaderWithProgress from={nbItemsDone} to={4}>
              {t('home.contractCancel.header')}
            </HeaderWithProgress>
          </div>
          <div className='py-6 px-4 sm:px-8 bg-white rounded-xl shadow-sm'>
            <ActionListItem label='1' done={infoComplete}>
              <div className='sm:flex flex-1 justify-between ml-2'>
                <p className='mt-1 font-bold text-gray-900'>{t('home.contractCancel.infoFill')}</p>
                {!infoComplete && (
                  <button
                    onClick={onOpenModal}
                    className='mt-2 sm:-mt-1 sm:ml-2 btn btn-primary btn-xs lg:btn-sm btn-fluid sm:btn-solid'
                  >
                    {t('fill')}
                  </button>
                )}
              </div>
            </ActionListItem>
            <ActionListItem label='2' done={letterDone}>
              <div className='sm:flex flex-1 justify-between ml-2'>
                <p className='mt-1 font-bold text-gray-900'>
                  {t('home.contractCancel.letterDone')}
                </p>
              </div>
            </ActionListItem>
            <ActionListItem label='3' done={letterSent}>
              <div className='sm:flex flex-1 justify-between ml-2'>
                <p className='mt-1 font-bold text-gray-900'>
                  {t('home.contractCancel.letterSent')}
                </p>
              </div>
            </ActionListItem>
            <ActionListItem label='4' done={isContractCanceled(courrierData)} isLast>
              <div className='sm:flex flex-1 justify-between ml-2'>
                <p className='mt-1 font-bold text-gray-900'>{t('home.contractCancel.cancelled')}</p>
              </div>
            </ActionListItem>
          </div>
          <ModalSlider
            open={openModal}
            onClose={onCloseModal}
            title={t('home.contractCancel.formHeader')}
          >
            <ContractCancelForm onClose={onCloseModal} setInfoComplete={setInfoComplete} />
          </ModalSlider>
        </div>
      )}
    </>
  );
};

const useContractCancelForm = ({ onClose, setInfoComplete }: ContractCancelFormProps) => {
  const { mutate: updatePolicy } = useUpdatePolicy();
  const { t } = useTranslation();
  const [{ policyData }] = useDashboardState();

  const [error, setError] = useState<string>();
  const [insurancOption, setInsuranceOption] = useState<any>([]);
  const [insuranceValue, setInsuranceValue] = useState({
    label: t('home.contractCancel.insurancePlaceholder'),
    value: '',
  });
  const { data } = useInsurer();
  const [contractNumber, setContractNumber] = useState('');
  const [subscriber, setSubscriber] = useState(
    policyData?.policy_out.uw_past_subscriber_name
      ? policyData?.policy_out.uw_past_subscriber_name
      : ''
  );

  useEffect(() => {
    if (data) {
      const _insuranceOption: any[] = [];
      data.map((insurer: any) => {
        _insuranceOption.push({ value: insurer.insurer, label: insurer.insurer });
      });
      setInsuranceOption(
        _insuranceOption.length > 0 ? _insuranceOption.sort((x) => x.label) : _insuranceOption
      );
    }
  }, [data]);

  const onChangeContractNumber: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    setContractNumber(e.currentTarget.value);
  };
  const onSelectInsurance = (value: any) => {
    setInsuranceValue(value);
  };
  const onChangeSubscriber: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    setSubscriber(e.currentTarget.value);
  };

  const canSubmit = Boolean(
    insuranceValue.value !== '' && contractNumber?.length > 0 && subscriber?.length > 0
  );

  const onSubmit: React.FormEventHandler = async (e) => {
    e.preventDefault();
    setError('');
    updatePolicy({
      uw_past_insurer_name: insuranceValue.value,
      uw_past_insurer_policy_id: contractNumber,
      uw_past_subscriber_name: subscriber,
    });
    setTimeout(() => {
      const res = getStoredValue('updatePolicyRes', false)();
      if (res) {
        setInfoComplete(true);
        onClose();
      } else setError(t('home.contractCancel.error.general'));
    }, 500);
  };

  return {
    insuranceValue,
    insurancOption,
    onSelectInsurance,
    contractNumber,
    onChangeContractNumber,
    subscriber,
    onChangeSubscriber,
    onSubmit,
    canSubmit,
    error,
  };
};

interface ContractCancelFormProps {
  onClose: () => void;
  setInfoComplete: any;
}
const ContractCancelForm = ({ onClose, setInfoComplete }: ContractCancelFormProps) => {
  const {
    insuranceValue,
    insurancOption,
    onSelectInsurance,
    contractNumber,
    onChangeContractNumber,
    subscriber,
    onChangeSubscriber,
    onSubmit,
    canSubmit,
    error,
  } = useContractCancelForm({ onClose, setInfoComplete });
  const { t } = useTranslation();
  return (
    <form onSubmit={onSubmit} className='my-6 space-y-6'>
      <div className='form-control'>
        {insuranceValue && (
          <Select
            value={insuranceValue}
            options={insurancOption}
            onChange={onSelectInsurance}
            label={t('home.contractCancel.insuranceLabel')}
          />
        )}
      </div>
      <TextField
        value={contractNumber}
        label={t('home.contractCancel.contractNumberLabel')}
        placeholder={t('home.contractCancel.contractNumberPlaceholder')}
        onChange={onChangeContractNumber}
      />
      <TextField
        value={subscriber}
        label={t('home.contractCancel.subscriberLabel')}
        placeholder='Prénom Nom'
        onChange={onChangeSubscriber}
      />
      <p className='text-primary-500'>{t('home.contractCancel.helpText')}</p>
      <button
        className=' btn-fluid btn btn-primary btn-xs lg:btn-sm'
        type='submit'
        disabled={!canSubmit}
      >
        {t('home.contractCancel.formSubmit')}
      </button>
      {error && <p className='mt-1 mb-6 text-red-500'>{error}</p>}
    </form>
  );
};

export default ContractCancel;
