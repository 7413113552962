const IconHeadphone = ({ width = 16, height = 16 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox={'0 0 16 16'}
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M14 8C14 9.5913 13.3679 11.1174 12.2426 12.2426C11.1174 13.3679 9.5913 14 8 14'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M2 8C2 6.4087 2.63214 4.88258 3.75736 3.75736C4.88258 2.63214 6.4087 2 8 2'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M2 7.99996C2.00009 8.70277 2.1251 9.39996 2.36917 10.059C2.42326 10.2073 2.52773 10.3319 2.66434 10.4111C2.80094 10.4902 2.96101 10.5188 3.11657 10.492L3.77403 10.376C4.02966 10.331 4.25774 10.1883 4.41007 9.9781C4.5624 9.76793 4.62704 9.50677 4.59033 9.24981L4.39245 7.86467C4.37349 7.73195 4.32804 7.6044 4.25879 7.4896C4.18954 7.3748 4.09792 7.2751 3.98937 7.19642C3.88082 7.11774 3.75755 7.06169 3.6269 7.03161C3.49625 7.00152 3.36089 6.99802 3.22886 7.0213L2.05513 7.22825'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M14 8C14 6.4087 13.3679 4.88258 12.2426 3.75736C11.1174 2.63214 9.5913 2 8 2'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M13.9988 7.99996C13.9987 8.70277 13.8737 9.39996 13.6297 10.059C13.5756 10.2073 13.4711 10.3319 13.3345 10.4111C13.1979 10.4902 13.0378 10.5188 12.8823 10.492L12.2248 10.376C11.9692 10.331 11.7411 10.1883 11.5888 9.9781C11.4364 9.76793 11.3718 9.50677 11.4085 9.24981L11.6064 7.86467C11.6253 7.73195 11.6708 7.6044 11.74 7.4896C11.8093 7.3748 11.9009 7.2751 12.0095 7.19642C12.118 7.11774 12.2413 7.06169 12.3719 7.03161C12.5026 7.00152 12.6379 6.99802 12.77 7.0213L13.9437 7.22825'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export default IconHeadphone;
