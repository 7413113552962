import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import WhiteCard from '@/components/pages/dashboard/_shared/WhiteCard';
import { Formule, PaymentMethodType } from '@/components/utils/constants';
import { useAuth } from '@/context/Auth';
import { useDashboardState } from '@/context/DashBoard';
import useDashboardData from '@/hooks/api/dashboard/useDashboardData';
import routes from '@/routes';
import IsRibAdded from '@/services/isRibAdded';
import { toFixed } from '@/services/utils';

import PageContainer from '../../layouts/PageContainer';
import HeadTitle from '../../utils/HeadTitle';
import DocumentsUpload from './_documents/DocumentsUpload';
import ContractCancel from './_home/ContractCancel';
import RibPanel from './_payment/RibPanel';
import GreenCard from './_shared/GreenCard';

const Home = () => {
  const { t } = useTranslation();
  //const [{ driver_id }] = useAuth();

  //const { isLoading } = useDashboardData({ driver_id: driver_id as number });
  const isLoading = false;
  const [
    {
      permisRecto,
      permisVerso,
      cartegrise,
      releveInformation,
      compteurKilometrique,
      permisSecondDriverRecto,
      permisSecondDriverVerso,
      policyData,
      //paymentMethodData,
    },
    setDashBoardState,
  ] = useDashboardState();

  const name = policyData?.driver_out.qt_first_name;
  const distance = Number(policyData?.policy_out.qt_km);
  const premiumTotalTTC = Number(policyData?.policy_out.qt_flitter_total_prime_ttc);
  const premiumAnnualTTC =
    policyData?.policy_out.qt_paymentfrequency_type === 'mensuel'
      ? premiumTotalTTC / 12
      : premiumTotalTTC;
  const price = Number(toFixed(Number(premiumAnnualTTC), 2));
  const paymentfrequency =
    policyData?.policy_out.qt_paymentfrequency_type === 'mensuel' ? 'mois' : 'an';

  const [displayRibPanel] = useState(!IsRibAdded());

  function getFormule(formule: string): Formule {
    switch (formule) {
      case 'tiers':
        return Formule.TIERS;
      case 'vol_incendie':
        return Formule.VOL_ICENDIE;
      case 'tous_risques':
        return Formule.TOUS_RISQUES;
      default:
        return Formule.TOUS_RISQUES;
    }
  }

  const formule = getFormule(policyData?.policy_out?.qt_formule as string);

  return (
    <>
      {!isLoading && (
        <PageContainer>
          <HeadTitle titleKey='home.pageTitle' />
          <div className='space-y-8 sm:space-y-12'>
            <div>
              <h1>{t('home.title', { name })}</h1>
              <p className='text-base text-gray-600'>{t('home.subtitle')}</p>
            </div>

            <div>
              <GreenCard></GreenCard>
              <div className='mt-4'>
                <WhiteCard>
                  <div className='grid grid-cols-7 py-6 md:py-8 px-5 sm:px-8 pb-5'>
                    <div className='col-span-4 sm:col-span-2 xl:col-span-1 mb-5 lg:mb-0'>
                      <img src='/img/manCarLarge.svg' className='w-20 sm:w-28' />
                    </div>
                    <div className='col-span-7 sm:col-span-3 xl:col-span-4 xl:pl-8'>
                      <h2 className='text-lg sm:text-xl'>{t(`home.quoteName.${formule}`)}</h2>
                      <p className='-mt-1 text-base sm:text-sm'>
                        {t('home.forfait', { distance })}
                        <span className='mx-1 sm:mx-2 font-bodyBold text-xl '>.</span>
                        {t('home.forfaitPrice', { price, paymentfrequency })}
                      </p>
                    </div>
                    <div className='col-span-7 sm:col-span-2 text-left sm:text-right'>
                      <Link to={routes.GARANTIES}>
                        <button className='mt-4 sm:mt-0 md:max-w-max btn btn-white btn-xs lg:btn-sm btn-fluid'>
                          {t('home.garanties')}
                        </button>
                      </Link>
                    </div>
                  </div>
                </WhiteCard>
              </div>
            </div>

            {policyData?.policy_out.uw_status === 'insured_pending' && (
              <DocumentsUpload
                permisRectoFileName={permisRecto?.name || null}
                permisVersoFileName={permisVerso?.name || null}
                permisSecondDriverRectoFileName={permisSecondDriverRecto?.name || null}
                permisSecondDriverVersoFileName={permisSecondDriverVerso?.name || null}
                CGFileName={cartegrise?.name || null}
                hasInfoFileName={releveInformation?.name || null}
                odometerPhotoFileName={compteurKilometrique?.name || null}
                onDeleteCG={() =>
                  setDashBoardState((previousState) => ({
                    ...previousState,
                    cartegrise: { ...previousState.cartegrise, name: undefined, uploaded: false },
                  }))
                }
                onDeleteOdometer={() =>
                  setDashBoardState((previousState) => ({
                    ...previousState,
                    compteurKilometrique: {
                      ...previousState.compteurKilometrique,
                      name: undefined,
                      uploaded: false,
                    },
                  }))
                }
                onDeletePermisRecto={() =>
                  setDashBoardState((previousState) => ({
                    ...previousState,
                    permisRecto: { ...previousState.permisRecto, name: undefined, uploaded: false },
                  }))
                }
                onDeletePermisVerso={() =>
                  setDashBoardState((previousState) => ({
                    ...previousState,
                    permisVerso: { ...previousState.permisVerso, name: undefined, uploaded: false },
                  }))
                }
                onDeletePermisSecondDriverRecto={() =>
                  setDashBoardState((previousState) => ({
                    ...previousState,
                    permisSecondDriverRecto: {
                      ...previousState.permisSecondDriverRecto,
                      name: undefined,
                      uploaded: false,
                    },
                  }))
                }
                onDeletePermisSecondDriverVerso={() =>
                  setDashBoardState((previousState) => ({
                    ...previousState,
                    permisSecondDriverVerso: {
                      ...previousState.permisSecondDriverVerso,
                      name: undefined,
                      uploaded: false,
                    },
                  }))
                }
                onDeleteInfo={() =>
                  setDashBoardState((previousState) => ({
                    ...previousState,
                    releveInformation: {
                      ...previousState.releveInformation,
                      name: undefined,
                      uploaded: false,
                    },
                  }))
                }
              />
            )}
            {policyData?.policy_out?.qt_cancel_previousinsurance && <ContractCancel />}
            {displayRibPanel && <RibPanel />}
            {/* 
            <ReferralCard />
            */}
          </div>
        </PageContainer>
      )}
    </>
  );
};
{
  /*<ContractCancel contractCancelled={isContractCanceled} letterSent={isSent} />*/
}

export default Home;
