import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { initialSubscriptionState, useSubscriptionState } from '@/context/Subscription';
import useSignUpApi from '@/hooks/auth/useSignUp';
import routes from '@/routes';

import SignUpLayout from '../../layouts/SignUpLayout';
import PasswordFields from './_login/PasswordFields';

const useSignUp = () => {
  const { push } = useHistory();
  const [subscriptionState, setSubscriptionState] = useSubscriptionState();

  const [pwd, setPwd] = useState('');
  const [confirmPwd, setConfirmPwd] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');

  const { t } = useTranslation();
  const onSignUpApi = useSignUpApi();

  const onSignUp = async () => {
    setIsLoading(true);
    setError('');
    try {
      window.analytics.track('sign_up', {
        ...subscriptionState,
        qt_quote_uuid: localStorage.getItem('paymentQuoteId'),
        uw_policy_uuid: subscriptionState?.policy?.uw_policy_uuid,
        ts: new Date().toISOString(),
      });
      await onSignUpApi(pwd);
      setSubscriptionState(initialSubscriptionState);
      push(routes.ROOT);
      setIsLoading(false);
    } catch (error) {
      setError(t('signUp.error.general'));
      setIsLoading(false);
    }
  };

  return {
    pwd,
    setPwd,
    confirmPwd,
    setConfirmPwd,
    isLoading,
    onSignUp,
    error,
  };
};

const SignUp = () => {
  const { pwd, setPwd, confirmPwd, setConfirmPwd, isLoading, onSignUp, error } = useSignUp();

  return (
    <SignUpLayout>
      <form className='mt-8' onSubmit={onSignUp}>
        {error.length > 0 && <p className='mt-1 mb-6 text-red-500'>{error}</p>}
        <PasswordFields
          pwd={pwd}
          setPwd={setPwd}
          confirmPwd={confirmPwd}
          setConfirmPwd={setConfirmPwd}
          onSubmit={onSignUp}
          displayHelper={true}
          isLoading={isLoading}
        />
      </form>
    </SignUpLayout>
  );
};

export default SignUp;
