import { useQuery } from 'react-query';

import { useAuth } from '@/context/Auth';
import { useDashboardState } from '@/context/DashBoard';

import { paths } from '../../../../generated/publicApi';
import { usePublicApiAxios } from '../../../context/PublicApiAxios';

const documentDataPath = '/api/v1/dashboard/uploaded_document';

const cacheOptions = {
  staleTime: Infinity,
  refetchOnWindowFocus: false,
  cacheTime: Infinity,
  refetchOnMount: false,
};

export type DocumentDataQuery =
  paths['/api/v1/dashboard/uploaded_document']['get']['parameters']['query'];
export type DocumentDataResult =
  paths['/api/v1/dashboard/uploaded_document']['get']['responses']['200']['content']['application/json'];

const useDocumentData = ({ driver_id }: DocumentDataQuery) => {
  const axios = usePublicApiAxios();
  const [, setDashboardState] = useDashboardState();
  const [{ token }] = useAuth();

  const getDocumentData = async () => {
    const { data } = await axios.get<DocumentDataResult>(documentDataPath, {
      params: { driver_id },
      headers: { Authorization: `Bearer ${token}` },
    });
    if (data && data !== null) {
      setDashboardState((prevState) => ({
        ...prevState,
        documentData: data,
        cartegrise: {
          type: 'carte-grise',
          uploaded: data.carte_grise_out?.storage_path ? true : false,
          name: data.carte_grise_out?.local_path,
          validated: data.carte_grise_out?.status === 'valid',
        },
        releveInformation: {
          type: 'releve-information',
          uploaded: data.releve_information_out?.storage_path ? true : false,
          name: data.releve_information_out?.local_path,
          validated: data.releve_information_out?.status === 'valid',
        },
        permisRecto: {
          type: 'permis-recto',
          uploaded: data.driving_license_recto_out?.storage_path ? true : false,
          name: data.driving_license_recto_out?.local_path,
          validated: data.driving_license_recto_out?.status === 'valid',
        },
        permisVerso: {
          type: 'permis-verso',
          uploaded: data.driving_license_verso_out?.storage_path ? true : false,
          name: data.driving_license_verso_out?.local_path,
          validated: data.driving_license_verso_out?.status === 'valid',
        },
        permisSecondDriverRecto: {
          type: 'permis-recto',
          uploaded: data.driving_license_recto_second_out?.storage_path ? true : false,
          name: data.driving_license_recto_second_out?.local_path,
          validated: data.driving_license_recto_second_out?.status === 'valid',
        },
        permisSecondDriverVerso: {
          type: 'permis-verso',
          uploaded: data.driving_license_verso_second_out?.storage_path ? true : false,
          name: data.driving_license_verso_second_out?.local_path,
          validated: data.driving_license_verso_second_out?.status === 'valid',
        },
      }));
      return data;
    }
  };

  return useQuery(documentDataPath, getDocumentData, cacheOptions);
};

export default useDocumentData;
