import { Email, LottieAnimation, ModalSlider, TextField } from '@flitter-insurance/nessui';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import useForgotPwdApi from '@/hooks/auth/useForgotPwd';

import SignInLayout from '../../layouts/SignInLayout';

const useReset = () => {
  const { t } = useTranslation();
  const [email, setEmail] = useState('');
  const [displayPopup, setDisplayPopup] = useState(false);
  const [emailError, setEmailError] = useState<string>();
  const [isLoading, setIsLoading] = useState(false);

  const onForgotPwd = useForgotPwdApi();

  const onForgot: React.FormEventHandler = (e) => {
    e.preventDefault();

    setEmailError('');

    if (email.length === 0) setEmailError(t('signIn.error.AUTH03'));
    //if (email.length > 0 && !regEmail.test(email)) setEmailError(t('signIn.error.AUTH05'));

    if (email.length > 0 /* && regEmail.test(email)*/) {
      setIsLoading(true);
      const data = onForgotPwd(email);
      data
        .then(() => {
          setDisplayPopup(true);
          setIsLoading(false);
        })
        .catch(() => {
          setEmailError(t('signIn.error.AUTH02'));
          setIsLoading(false);
        });
    } else return null;
  };

  const onChangeEmail: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    setEmail(e.currentTarget.value);
  };

  return {
    onForgot,
    email,
    onChangeEmail,
    emailError,
    displayPopup,
    setDisplayPopup,
    isLoading,
  };
};

const ResetPasswordInvite = () => {
  const { t } = useTranslation();
  const { email, onChangeEmail, onForgot, emailError, displayPopup, setDisplayPopup, isLoading } =
    useReset();
  const linkURL = import.meta.env.VITE_LINK_APP_DOMAIN as string;

  return (
    <SignInLayout>
      <h1 className='mb-5 text-center'>{t('resetPwdInvite.header')}</h1>
      <p className='mt-1 mb-10 text-sm'>{t('resetPwdInvite.headerHelper')}</p>

      <form className='space-y-6' onSubmit={onForgot}>
        <TextField
          value={email}
          onChange={onChangeEmail}
          type='email'
          name='email'
          placeholder={t('signIn.emailPlaceholder')}
          leftIcon={<Email size={24} />}
          autoComplete='username'
          error={emailError}
          label={t('signIn.emailLabel')}
        />

        <button className='btn btn-primary btn-xs lg:btn-sm' type='button' onClick={onForgot}>
          {isLoading ? <LottieAnimation type='white-dots' /> : t('resetPwdInvite.receiveLinkBtn')}
        </button>
      </form>
      <p className='mt-10 font-bodyMedium text-xs text-gray-600'>
        {t('resetPwdInvite.textLink1')}{' '}
        <a href={linkURL} className='text-primary-500'>
          {' '}
          {t('resetPwdInvite.textLink2')}{' '}
        </a>
      </p>

      {displayPopup && (
        <ModalSlider
          title={t('resetPwdInvite.modal.title')}
          open={true}
          onClose={() => setDisplayPopup(false)}
          onCloseOutside={false}
        >
          <p className='pt-4 text-gray-600'>{t('resetPwdInvite.modal.text')}</p>

          <div className='grid grid-cols-2 grid-flow-col gap-2 pt-11'>
            <button
              className='btn btn-white btn-xs lg:btn-sm'
              onClick={() => setDisplayPopup(false)}
            >
              {t('resetPwdInvite.modal.closebtn')}
            </button>
            <button className='btn btn-primary btn-xs lg:btn-sm' onClick={onForgot}>
              {isLoading ? (
                <LottieAnimation type='white-dots' />
              ) : (
                t('resetPwdInvite.modal.resendbtn')
              )}
            </button>
          </div>
        </ModalSlider>
      )}
    </SignInLayout>
  );
};

export default ResetPasswordInvite;
