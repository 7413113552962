const IconHelp = ({ width = 24, height = 24 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox={'0 0 24 24'}
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z'
        stroke='#1E2044'
        strokeWidth='1.8'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M9.6875 9.68565C9.80234 9.19068 10.0865 8.75136 10.4908 8.44359C10.8951 8.13581 11.3941 7.97891 11.9018 7.99998C12.4649 7.96879 13.0175 8.16156 13.439 8.53624C13.8606 8.91092 14.1168 9.4371 14.1518 9.99998C14.1518 11.5039 12.0018 12 12.0018 13'
        stroke='#F45B69'
        strokeWidth='1.8'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M12.125 15.75C12.125 15.7747 12.1177 15.7989 12.1039 15.8194C12.0902 15.84 12.0707 15.856 12.0478 15.8655C12.025 15.8749 11.9999 15.8774 11.9756 15.8726C11.9514 15.8678 11.9291 15.8559 11.9116 15.8384C11.8941 15.8209 11.8822 15.7986 11.8774 15.7744C11.8726 15.7501 11.8751 15.725 11.8845 15.7022C11.894 15.6793 11.91 15.6598 11.9306 15.6461C11.9511 15.6323 11.9753 15.625 12 15.625'
        stroke='#1E2044'
        strokeWidth='1.8'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M12 15.625C12.0332 15.625 12.0649 15.6382 12.0884 15.6616C12.1118 15.6851 12.125 15.7168 12.125 15.75'
        stroke='#F45B69'
        strokeWidth='1.8'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export default IconHelp;
