import { BillingMode, Formule, QuoteGaranties } from '@/components/utils/constants';

const common = {
  confirm: 'Confirmer',
  add: 'Ajouter',
  fill: 'Compléter',
  save: 'Enregistrer',
  update: 'Mettre à jour',
  progress: '{{from}} sur {{to}}',
  thousandsSeparator: ' ',
  dateFormat: 'DD/MM/YYYY',
  number: '{{value, number}}',
  date: '{{date, datetime}}', // see https://www.i18next.com/translation-function/formatting#datetime
  pageTitle: '{{title}} - Flitter',
  signUp: {
    header: 'Votre carte verte est prête !',
    description: 'Nous sommes ravis de vous compter parmi nos membres {{firstName}}.',
    subDescription:
      'Vous pouvez dès à présent configurer votre mot de passe et accéder à votre espace assuré pour télécharger votre carte verte temporaire.',
    passwordLabel: 'Mot de passe',
    confirmLabel: 'Confirmer le mot de passe',
    rules: {
      length: '8 caractères',
      uppercase: '1 majuscule',
      lowercase: '1 minuscule',
      special: '1 caractère spécial',
      number: '1 chiffre',
    },
    error: {
      mismatch: "Aie, vous n'avez pas entré le même nouveau mot de passe!",
      general: 'Une erreur est survenue, veuillez réessayer plus tard.',
    },
  },
  signIn: {
    header: 'Me connecter à mon espace',
    emailPlaceholder: 'Votre email',
    emailLabel: 'Email',
    pwdPlaceholder: 'Votre mot de passe',
    pwdLabel: 'Mot de passe',
    forgotPasswordLink: 'Je n’ai pas mon mot de passe',
    confirmBtn: 'Me connecter',
    error: {
      AUTH01: 'La combinaison email / mot de passe est incorrecte.',
      AUTH02: 'Email inconnu.',
      AUTH03: 'Votre email est requis.',
      AUTH04: 'Votre mot de passe est requis.',
      AUTH05: 'Adresse email invalide.',
    },
  },
  resetPwdInvite: {
    header: 'Vous n’avez pas votre mot de passe ?',
    headerHelper:
      'Veuillez saisir votre email de connexion afin de recevoir le lien de réinitialisation de votre mot de passe.',
    receiveLinkBtn: 'Recevoir le lien',
    textLink1: 'Pas de compte ?',
    textLink2: 'Découvrez Flitter.',
    modal: {
      title: 'Vous avez reçu le lien par email',
      text: 'Nous venons de vous envoyer un email contenant le lien de réinitialisation de votre mot de passe.',
      resendbtn: 'Renvoyer le lien',
      closebtn: 'Fermer',
    },
  },
  resetPwd: {
    header: 'Réinitialisez votre mot de passe',
    headerHelper: 'Saisissez un nouveau mot de passe pour {{email}}',
    label1: 'Nouveau mot de passe',
    label2: 'Confirmation du nouveau mot de passe',
    pwdHelper: 'Votre mot de passe doit contenir au moins:',
    confirmBtn: 'Réinitialiser mon mot de passe',
    error: {
      general: 'Une erreur est survenue, veuillez réessayer plus tard.',
      tokenError: 'Lien de réinitialisation du mot de passe non reconnu.',
      pwdChangeError:
        'Une erreur est survenue lors de la modification du mot de passe, veuillez réessayer plus tard.',
      mismatch: "Aie, vous n'avez pas entré le même nouveau mot de passe!",
    },
    modal: {
      title: 'Votre mot de passe a été mis à jour',
      text: ' Bien joué! Votre mot de passe a bien été mis à jour et vous pouvez dès à présent accéder à votre espace assuré.',
      linkBtn: 'Continuer',
    },
  },
  helloSign: {
    header: 'Signature de votre contrat',
    popupTitle: 'Votre souscription est presque finalisée !',
    popupP1:
      '{{firstName}}, votre paiement est confirmé et vous serez assuré chez Flitter à partir du {{date}}!',
    popupP2:
      "Il ne vous reste plus qu'une dernière étape pour finaliser votre souscription: la signature de votre contrat",
    popupP3: 'Si vous avez des questions, notre équipe est là pour vous répondre !',
    popupBtnSign: 'Signer mon contrat',
    popupBtnCancel: 'Signer mon contrat plus tard',
    loadingText: 'Un instant, nous préparons votre contrat.',
  },
  payment: {
    progressLabel: 'Paiement',
    back: 'Retour',
    formule: {
      tiers: 'Tiers',
      vol_incendie: 'Vol Incendie',
      tous_risques: 'Tous Risques',
    },
  },
  nav: {
    home: 'Accueil',
    forfait: 'Forfait',
    sinistres: 'Sinistres',
    documents: 'Documents',
    factures: 'Factures',
    garanties: 'Garanties',
    payments: 'Moyens de paiement',
    advantages: 'Avantages',
    contact: 'Nous contacter',
    declareSinistre: 'Déclarer un sinistre',
    myAccount: 'Mon compte',
    profile: 'Mon profil',
    logout: 'Déconnexion',
  },
  topNav: {
    formule: 'Formule',
    recapitulatif: 'Récapitulatif',
    paiement: 'Paiement',
    signature: 'Signature',
  },
  home: {
    title: 'Bonjour {{name}} ! Belle journée n’est-ce pas ?',
    pageTitle: 'Accueil',
    subtitle: "Retrouvez dans cette page une vue d'ensemble de votre assurance Flitter.",
    quoteName: {
      [Formule.TIERS]: 'Formule Tiers',
      [Formule.VOL_ICENDIE]: 'Formule Confort',
      [Formule.TOUS_RISQUES]: 'Formule Tous Risques',
    },
    forfait: 'Forfait {{distance}} km par an ',
    forfaitPrice: '{{price}}€/{{paymentfrequency}}',
    garanties: 'Voir mes garanties',
    documentsTitle: 'Finalisez votre inscription en téléchargeant vos pièces justificatives',
    documentUpload: {
      permisRecto: 'Permis de conduire (recto)',
      permisVerso: 'Permis de conduire (verso)',
      permisSecondDriverRecto: 'Permis de conduire du conducteur secondaire (recto)',
      permisSecondDriverVerso: 'Permis de conduire du conducteur secondaire (verso)',
      permisModalTitle: 'Votre permis de conduire',
      cg: 'Carte grise',
      cgModalTitle: 'Votre carte grise',
      info: "Relevé d'informations",
      infoModalTitle: "Votre relevé d'informations",
      odometer: 'Prendre une photo de votre compteur',
      odometerModalTitle: 'Votre relevé de compteur kilométrique',
      fileComplete: 'Votre dossier est complet et en cours de validation',
      permisrectoModalTitle: 'Votre permis de conduire',
      permisversoModalTitle: 'Votre permis de conduire',
      permisrectosecondconducteurModalTitle: 'Permis de conduire du conducteur secondaire (recto)',
      permisversosecondconducteurModalTitle: 'Permis de conduire du conducteur secondaire (verso)',
      cartegriseModalTitle: 'Votre carte grise',
      releveinformationModalTitle: "Votre relevé d'informations",
    },
    contractCancel: {
      header: 'Résiliation de votre ancien contrat',
      infoFill: 'Renseignez les informations de votre ancien contrat',
      letterDone:
        'Nous écrivons la lettre de résiliation pour que vous puissiez vérifier les informations de résiliation.',
      letterSent: 'Nous envoyons le courrier de résiliation à votre ancien assureur.',
      cancelled: 'Votre ancien contrat est résilié.',
      formHeader: 'Informations pour résilier votre contrat',
      insuranceLabel: "Compagnie d'assurance",
      insurancePlaceholder: 'Sélectionnez votre ancien assureur',
      contractNumberLabel: 'Numéro de contrat',
      contractNumberPlaceholder: '0123456',
      immatriculationLabel: "Plaque d'immatriculation du véhicule",
      immatriculationPlaceholder: 'AA-123-AA',
      subscriberLabel: 'Nom du souscripteur',
      formSubmit: 'Mettre à jour',
      helpText: 'Vous ne trouvez pas votre assureur? Ecrivez-nous sur le chat.',
      error: {
        general: "Une erreur s'est produite lors de la mise à jour de vos informations.",
      },
    },
    odometer: {
      title: 'La prise de photo se fait par téléphone',
      description: 'Un lien sécurisé sera envoyé au',
      phoneNumber: '{{phone_number}}',
      send: 'Envoyer',
    },
  },
  fileUpload: {
    fileLabel: 'Choisir un fichier',
    photoLabel: 'Choisir une photo',
    cameraLabel: 'Prendre une photo',
  },
  greenCard: {
    greenCardTitle: 'Votre carte verte',
    temporaryGreenCardTitle: 'Votre carte verte temporaire',
    temporaryGreenCardContent: 'Votre carte verte temporaire est diponible ',
    point: '.',
    temporaryGreenCardContentBis:
      'Pour recevoir votre carte verte définitive, finalisez cotre souscription en ajoutant les pièces ci-dessous. Vos documents doivent être ajoutés dans un délai de 30 jours afin de nous permettre de finaliser votre souscription.',
    temporaryGreenCardContentTer: "N'hésitez pas à ",
    temporaryGreenCardContentQua: " si vous avez besoin d'aide pour finaliser votre dossier.",
    completedFileTitle: 'Votre dossier est complet !',
    completedFileContent:
      'Bonne nouvelle {{firstName}}, votre dossier de souscription est désormais complet. Nos équipes se mettent en ordre de bataille pour revoir vos pièces et les valider sous 48h. Votre carte verte définitive vous sera envoyée par e-mail et par courrier dès que vos documents sont validés.',
    greenCardIncomingTitle: 'Votre carte verte est en route',
    greenCardIncomingContent:
      'Bonne nouvelle {{firstName}}, votre dossier de souscription a été validé par nos équipes !',
    greenCardIncomingContentBis: 'Votre carte verte définitive numérique est disponible ',
    greenCardIncomingContentTer:
      ' et sa version papier vous arrivera d’ici quelques jours dans votre boîte aux lettres.',
    here: 'ici',
    contactUs: 'nous écrire',
  },
  ribPanel: {
    header: 'Ajouter un R.I.B',
    cta: 'Ajouter un R.I.B',
    description:
      'Payez votre cotisation et soyez remboursé plus rapidement en partageant les coordonnées de votre compte bancaire',
    modalTitle: 'Renseignez votre IBAN',
    submitRib: 'Valider',
    useNewAsDefault: 'Utiliser ce moyen de paiement par défaut',
  },
  referral: {
    title: 'Parrainez un ami',
    header: 'Jusqu’à 12 mois d’assurance offerts',
    description:
      "Le Lorem Ipsum est simplement du faux texte employé dans la composition et la mise en page avant impression. Le Lorem Ipsum est le faux texte standard de l'imprimerie depuis les années 1500, quand un imprimeur anonyme assembla ensemble des morceaux de texte pour réaliser un livre spécimen de polices de texte.",
    copy: 'Copier',
    copyDone: 'Copié!',
  },
  subscribe: {
    infoBulle: {
      text: "Votre premier paiement de {{price_2_times}}€ couvre vos 2 premiers mois d'assurance. Votre prochaine mensualité de {{price}}€ sera prélevée plus tard, à partir de votre 3ème mois d'assurance.",
    },
    infoBulleAutohero: {
      text: 'Si vous choisissez de retourner votre voiture à Autohero dans les 15 jours suivant la livraison, votre contrat Flitter sera automatiquement résilié et vous serez remboursé de l’intégralité de votre paiement de {{priceAfterDiscount}}€.',
    },
    first: '{{price}} €',
    title: 'Paiement',
    price: {
      [BillingMode.Monthly]: '{{price}} €/mois',
      [BillingMode.Yearly]: '{{price}} €/an',
    },
    back: 'Récapitulatif',
    cotisationTitle: 'Votre cotisation',
    cardNumberPlaceholder: 'Numéro de carte',
    cardCvcPlaceholder: 'CVC',
    cardExpiryPlaceholder: 'MM / AA',
    submit: {
      [BillingMode.Monthly]: "Je m'assure",
      [BillingMode.Yearly]: "Je m'assure",
    },
    monthly: 'mensuel',
    yearly: 'annuel -5%',
    clickConfidentialite: 'politique de confidentialité',
    clickStripe: 'Stripe',
    cgu: 'CGU',
    termsTitle1:
      'J’accepte que Flitter SAS et Stripe traitent mes données bancaires à des fins de gestion de votre contrat, tel que défini dans notre <1>{{link_text_1}}<1>',
    termsTitle2:
      ' et vous acceptez les <1>{{link_text_3}}</1> de Flitter et <2>{{link_text_2}}</2>.',
    annualPaymentText: 'Économiser 9% en payant à l’année',
    annualFirstPayment: 'Total à régler',
    twoMonthPayment: 'Total à régler (2 mois)',
    promoCodeLink: 'Ajouter un code promo ou parrainage',
    promoCode: 'Code promo',
    promoCodeAddBtn: 'Ajouter mon code promo',
    promoCodeCloseBtn: 'Fermer et modifier mes informations',
    securePayment: 'Paiement sécurisé protocole SSL',
    afterPromoText: 'Total à régler après réduction',
    errorModal: {
      title: 'Paiement refusé',
      codes: {
        unknown: 'Une erreur est survenue.',
        authentication_required: "Votre carte a été refusée par motif d'authentification.",
        approve_with_id:
          "Le paiement ne peut être autorisé. Réessayez, et si l'erreur persiste, contactez votre banque pour plus d'informations.",
        call_issuer:
          "Votre carte a été refusée pour un motif inconnu. Contactez votre banque pour plus d'informations.",
        card_declined: 'Votre carte a été refusée.',
        card_not_supported: "Votre carte n'est pas supporté pour ce type d'achat.",
        card_velocity_exceeded: 'Il semble que votre plafond ait été atteint.',
        currency_not_supported: "Votre carte n'accepte pas les paiements en euros.",
        do_not_honor: "Contactez votre banque pour plus d'informations.",
        do_not_try_again: "Contactez votre banque pour plus d'informations.",
        duplicate_transaction:
          'Il semble que le paiement est un doublon. Vérifiez si un paiement récent existe déjà pour cet achat.',
        expired_card: 'Votre carte est expirée. Essayez avec une autre carte.',
        fraudulent: "Contactez votre banque pour plus d'informations.",
        generic_decline: "Contactez votre banque pour plus d'informations.",
        incorrect_number: 'Le numéro de carte semble incorrect.',
        incorrect_cvc: 'Le numéro de vérification semble incorrect.',
        insufficient_funds: 'Les fonds sont insuffisants.',
        invalid_account:
          'La carte ou le compte bancaire sont invalides. Vérifiez leur validité auprès de votre banque.',
        invalid_amount: "Le montant n'est pas accepté.",
        invalid_cvc: 'Le numéro de vérification semble incorrect.',
        invalid_expiry_month: "Le mois d'expiration semble incorrect.",
        invalid_expiry_year: "L'année d'expiration semble incorrecte.",
        invalid_number: 'Le numéro de carte semble incorrect.',
        incomplete_expiry: "La date d'expiration de votre carte est incomplète.",
        incomplete_number: 'Votre numéro de carte est incomplet.',
        incomplete_cvc: 'Le code de sécurité de votre carte est incomplet.',
        issuer_not_available:
          'Votre banque ne répond pas. Réessayez plus tard ou contactez votre banque.',
        lost_card: "Contactez votre banque pour plus d'informations.",
        merchant_blacklist: "Contactez votre banque pour plus d'informations.",
        new_account_information_available: "Contactez votre banque pour plus d'informations.",
        no_action_taken: "Contactez votre banque pour plus d'informations.",
        not_permitted: "Contactez votre banque pour plus d'informations.",
        pickup_card: "Contactez votre banque pour plus d'informations.",
        processing_error: 'Une erreur est survenue lors du paiement. Réessayez plus tard.',
        reenter_transaction:
          "Une erreur est survenue lors du paiement. Réessayez plus tard. Si l'erreur persiste, contactez votre banque.",
        restricted_card: "Contactez votre banque pour plus d'informations.",
        revocation_of_all_authorizations: "Contactez votre banque pour plus d'informations.",
        revocation_of_authorization: "Contactez votre banque pour plus d'informations.",
        security_violation: "Contactez votre banque pour plus d'informations.",
        service_not_allowed: "Contactez votre banque pour plus d'informations.",
        stolen_card: "Contactez votre banque pour plus d'informations.",
        stop_payment_order: "Contactez votre banque pour plus d'informations.",
        testmode_decline: 'Vous avez utilisé une carte de test. Utilisez une vraie carte bancaire.',
        transaction_not_allowed: "Contactez votre banque pour plus d'informations.",
        try_again_later:
          "Une erreur est survenue lors du paiement. Réessayez plus tard. Si l'erreur persiste, contactez votre banque.",
        withdrawal_count_limit_exceeded: 'Essayez avec un autre moyen de paiement.',
        already_exist: 'Erreur de paiement. Un utilisateur existe déjà avec le même prénom et nom.',
        null: '',
      },
    },
  },
  picture: {
    takePic: 'Prenez une photo de votre compteur',
    takePicSuccessHeader: 'Tout est bon ?',
    takePicSuccessText:
      'Vérifiez que la photo est bien nette et que votre compteur est bien visible.',
    confirm: 'Confirmer',
    continue: 'Continuer vers mon espace',
    retakePic: 'Reprendre une photo',
    submitPicSuccessHeader: 'Votre photo a bien été envoyée',
    submitPicSuccessText:
      'Votre relevé kilométrique sera traité par nos équipes dans les plus brefs délais. Vous recevrez un e-mail dès que votre relevé kilométrique est validé !',
    error: {
      general: 'Une erreur est survenue, veuillez réessayer plus tard.',
    },
    pictureCheckText: 'Vérifiez que la photo est bien nette.',
  },
  paymentSummary: {
    title: 'Votre contrat Flitter',
    insured: 'L’assuré',
    fullName: 'Nom complet',
    vehicule: 'Véhicule assuré',
    coverage: 'Couverture',
    startContract: 'Début de contrat',
    formula: 'Formule',
    formulaKm: 'Forfait kilométrique annuel',
    franchise: 'Franchises',
    franchiseBrokenGlace: 'Franchise bris de glace',
    franchiseAccident: 'Franchise tous accidents',
    franchiseVolIncendie: 'Franchise vol incendie',
    options: 'Options',
    assistance0Km: 'Assistance 0 km',
    secondaryDriver: 'Conducteur secondaire',
    vehiculeReplacement: 'Véhicule de remplacement',
    protection: 'Protection juridique',
    edit: 'Modifier',
    included: 'Inclus',
    notIncluded: 'Non inclus',
  },
  payments: {
    title: 'Moyens de paiement',
    subtitle: 'Pour ajouter une nouvelle méthode de paiement c’est par ici que ca se passe.',
  },
  paymentMethods: {
    title: 'Vos moyens de paiements',
    isDefault: 'Par défaut',
    isExpired: 'Expirée',
    setDefault: 'Utiliser par défaut',
    delete: 'Supprimer',
  },
  forfait: {
    title: 'Mon forfait',
    subtitle:
      'Retrouvez l’historique de vos relevés  et rechargez si besoin votre forfait kilométrique.',
    odometerReadings: 'Vos relevés',
    lastReading: 'Votre dernier relevé kilométrique, le {{date, datetime}}',
    lastReadingDistance: '{{distance, number}} km',
    addReading: 'Ajouter un relevé',
    limit: 'limite: {{limit, number}} km',
    odometerLimitTitle: 'Votre limite kilométrique',
    emptyReadings: "Vous n'avez aucun relevé kilométrique",
    limitLabel: 'Vous pouvez rouler sereinement jusqu’à',
    extraCta: 'Recharger mon forfait',
    underLimitTitle: 'Vous avez < de {{value, number}} km au compteur ?',
    underLimitDescription:
      'Les kilomètres restant peuvent être utilisés sur l’année suivante et vous pouvez réduire votre forfait l’année suivante.',
    aboveLimitTitle: 'Vous avez + de {{value, number}} km au compteur ?',
    aboveLimitDescription: 'Rechargez votre forfait pour continuer d’être protégé à 100%.',
    forfaitDetail: 'Détail de votre limite kilométrique',
    startReading: 'Kilométrage de départ',
    forfaitAdd: "Ajout d'un forfait {{value, number}}km",
    forfaitAutomaticUp: 'Recharge automatique',
    forfaitUpTo: "Vous pouvez rouler jusqu'à",
  },
  odometerUpload: {
    title: 'Ajoutez votre relevé annuel',
    description:
      'Il vous reste {{nbDays}} jours pour nous partager votre relevé kilométrique annuel et continuer de profiter de votre assurance auto !',
    cta: 'Ajouter le relevé annuel',
  },
  factures: {
    title: 'Mes factures',
    subtitle: 'Modifiez vos informations de facturation sur ',
    subtitleLink1: 'Mon Profil.',
    subtitle2: 'Modifiez vos informations de paiement sur ',
    subtitleLink2: 'Moyens de paiement.',
    categ: 'Toutes les factures de cette année',
    invoice: {
      date: '{{month}} {{year}}',
      amount: '{{monthlyPremiumTTC}} €',
    },
  },
  documents: {
    title: 'Mes documents',
    subtitle: 'Retrouvez l’ensemble des documents utiles de votre assurance.',
    important: {
      title: 'Mes attestations',
      insurance: 'Attestation d’assurance temporaire',
      insuranceDescription: "Votre carte verte temporaire d'une durée de 30 jours",
      attestation: 'Attestation d’assurance',
      attestationDescription: 'Le fameux macaron vert à apposer sur votre pare-brise',
      info: 'Relevé d’informations',
      infoDescription: 'Coefficient Bonus/malus et historique d’assurance',
    },
    info: {
      title: 'Mes infos',
      cg: 'Carte grise',
      cgDescription: 'Fini les demi-journées perdues à la préfécture',
      licenseRecto: 'Permis de conduire - Recto',
      licenseRectoDescription: 'Ça prend trop de place dans un porte-feuille',
      licenseVerso: 'Permis de conduire - Verso',
      licenseVersoDescription: 'Ça prend trop de place dans un porte-feuille',
    },
    documentsUtiles: {
      title: 'Mes documents utiles',
    },
    contracts: {
      title: 'Mes contrats',
      paymentNotice: "Dernier avis d'échéance",
      paymentNoticeDescription: 'Résume les échéances de paiement pour l’année',
      garantieNoticeAssurance: "Document d'information Assurance",
      garantieNoticeDescriptionAssurance:
        "Résumé des garanties et exclusions de votre contrat d'assurance",
      garantieNoticeAssistance: "Document d'information Assistance",
      garantieNoticeDescriptionAssistance:
        "Résumé des garanties et exclusions de votre contrat d'assistance",
      generalConditions: 'Conditions générales',
      generalConditionsDescription: "Document un peu long qui détaille l'ensemble de vos garanties",
      specialConditions: "Contrat d'assurance",
      specialConditionsDescription: 'Votre contrat',
    },
  },
  profile: {
    title: 'Mon profil',
    subtitle: 'Vos informations personnelles et de contact sont rassemblées ici',
    infoHeader: 'Mes infos perso',
    profileForm: {
      firstname: 'Prénom',
      lastname: 'Nom',
      email: 'Email',
      emailPlaceholder: 'lewis.hamilton@flitter.fr',
      phone: 'Téléphone',
      phonePlaceholder: '0612345678',
      profileSuccess: 'Votre profil a bien été mis à jour',
      streetName: 'Numéro et nom de rue',
      postalCode: 'Code postal',
      extraAddress: 'Complément d’adresse',
      city: 'Ville',
      cancel: 'Annuler',
    },
    addressHeader: 'Adresse de contact',
    addressLabel: 'Numéro et nom de rue',
    addressSuccess: 'Nouvelle adresse enregistrée',
    passwordHeader: 'Changer mon mot de passe',
    passwordCTA: 'Changer mon mot de passe',
    passwordForm: {
      currentPwdLabel: 'Mot de passe actuel',
      newPwdLabel: 'Nouveau mot de passe',
      confirmPwdLabel: 'Confirmez le nouveau mot de passe',
      passwordSuccess: 'Votre mot de passe a bien été changé',
      confirmBtn: 'Mettre à jour',
      error: {
        mismatch: "Aie, vous n'avez pas entré le même nouveau mot de passe!",
        AUTH01: "Votre mot de passe actuel n'est pas correct.",
      },
    },
  },
  contact: {
    sinistre: {
      phoneNumber: '01 76 39 01 17',
      call: 'Appeler',
      email: 'sinistres@flitter.fr',
      copy: "Copier l'email",
    },
    title: 'Nous contacter',
    subtitle:
      "Pour nous aider à toujours mieux répondre à vos attentes, n'hésitez pas à nous contacter",
    assistance: {
      title: 'Assistance',
      text: 'Une urgence ? Nos équipes sont disponibles 7j/7 24h/24 pour vous assister.',
      cta: "Appeler l'assistance",
      phoneNumberLabel: "Le numéro de l'assistance :",
      // warning: this is used directly in a tel: link on the contact page, so it must stay a valid phone number
      phoneNumber: '+33 1 76 36 12 60',
      copy: 'Copier',
      call: 'Appeler',
    },
    chat: {
      title: 'Contacter le chat',
      text: 'Posez-nous toutes vos questions, nous sommes là pour vous répondre !',
      cta: 'Contacter le chat',
    },
    rdv: {
      title: 'Prendre rendez-vous',
      text: 'Nous sommes disponibles pour vous à vos horaires. Prenez rdv pour échanger avec notre équipe.',
      cta: 'Prendre rendez-vous',
      link: 'https://calendly.com/hajer-gorgi/rdv-avec-mon-conseiller-flitter',
    },
    email: {
      title: 'Nous écrire',
      text: 'Nos conseillers sont également disponibles par e-mail si vous préférez nous écrire !',
      cta: 'Nous écrire',
      emailLabel: 'Notre adresse email :',
      email: 'hello@flitter.fr',
      copy: 'Copier',
      send: 'Envoyer un e-mail',
    },
    faq: {
      title: 'F.A.Q',
      text: 'Retrouvez des réponses aux questions fréquentes dans notre foire aux questions.',
      cta: 'Accéder à la FAQ',
      link: 'https://intercom.help/flitter-36a9a9d0b29e/fr',
    },
  },
  garanties: {
    title: 'Mes garanties',
    subtitle: 'Tous vos contrats Flitter sont rassemblés dans cette section.',
    ctaOtherVehicule: 'Assurer un autre véhicule',
    insuredDriver: {
      title: 'Vos conducteurs assurés',
      principalDriver: 'Conducteur principal',
      secondDriver: 'Conducteur secondaire',
      edit: 'Modifier',
      delete: 'Supprimer',
    },
    summary: {
      title: 'Votre assurance auto {{carMake}}',
      immatriculation: 'Véhicule assuré :',
      contract: 'Numéro de contrat :',
      franchiseBdg: 'Franchise bris de glace :',
      franchiseAccident: 'Franchise autres dommages :',
      franchiseValue: '{{value, number}} €',
      show: 'Voir mes garanties',
      hide: 'Masquer mes garanties',
    },
    options: {
      title: 'Vos options',
      replacementTitle: 'Véhicule de remplacement',
      replacementDesc:
        'Nous vous prêtons un véhicule de remplacement pendant 7 jours en cas de panne, accident, vol, incendie ou vandalisme de votre voiture.',
      protectionTitle: 'Protection juridique',
      protectionDesc:
        'Nous vous offrons une assistance juridique si vous rencontrez un litige relatif à votre voiture avec un tiers après un accident, des réparations ou encore votre contrôle technique.',
    },
    details: {
      covered: 'Ce que nous couvrons',
      notCovered: 'Ce que nous ne couvrons pas',
      franchiseTitle: 'Ce qu’il reste à votre charge en cas de sinistre',
      franchiseBdg: 'Bris de glace',
      franchiseAccident: 'Tout accidents',
      franchiseValue: '{{value, number}} €',
      title: {
        [QuoteGaranties.Assistance]: 'Assistance 0 km',
        [QuoteGaranties.Responsabilite]: 'Responsabilité civile',
        [QuoteGaranties.Defense]: 'Défense et recours',
        [QuoteGaranties.Garantie]: 'Garantie du conducteur',
        [QuoteGaranties.BrisGlace]: 'Bris de glace',
        [QuoteGaranties.Vol]: 'Vol, incendie, explosion',
        [QuoteGaranties.Tempetes]: 'Tempêtes et intempéries',
        [QuoteGaranties.CatNaturelles]: 'Catastrophes naturelles',
        [QuoteGaranties.Accidents]: 'Dommages tous accidents',
      },
      text: {
        [QuoteGaranties.Assistance]:
          "L'assistance 0 km est incluse dans tous nos contrats. Elle vous permet d'être dépanné 24h/24 et 7j/7, que vous soyiez tombés en panne en bas de chez vous, ou dans l'un des 42 pays de la carte verte.",
        [QuoteGaranties.Responsabilite]:
          "La responsabilité civile est incluse dans tous vos contrats d'assurance auto. C'est la seule garantie obligatoire en France. Elle vous protège pour tous les dégâts que vous pourriez causer aux autres (automobilistes, cyclistes, piétons..). Par exemple, si vous avez un accident responsable, nous prenons en charge les réparations de la voiture que vous avez percutée, mais aussi les dommages causés à l'automobiliste s'il est blessé.",
        [QuoteGaranties.Defense]:
          "La garantie défense pénale et recours suite à un accident est incluse dans toutes nos assurances auto car c’est une garantie essentielle en cas de litige. Ainsi nous couvrons vos frais de procédure judiciaire dans les cas où vous êtes victime d'un accident de voiture pour faire valoir vos droits, mais aussi dans le cas où vous êtes mis en cause par un tiers suite à un accident.",

        [QuoteGaranties.Garantie]:
          "Nous vous couvrons à hauteur de 500 000 € si vous êtes blessé lors d’un accident responsable. Nous prenons en charge vos frais d'hopitaux, mais aussi vos pertes de revenus dans le cas où vous seriez en incapacité (temporaire ou définitive) de travailler.",
        [QuoteGaranties.BrisGlace]:
          'La garantie bris de glace couvre vos frais de réparation lorsque les surfaces vitrées de votre véhicule sont cassées ou endommagées : vitres, pare-brise, toit, lunette arrière ou optiques de phare. Nous prenons en charge les frais de réparation de vos bris de glace sans franchise, et leur remplacement après application de votre franchise bris de glace.',
        [QuoteGaranties.Vol]:
          "La garantie Vol vous protège en cas de vol et prend en charge la réparation de votre voiture si elle a été dégradée lors d'une tentative de vol (serrure forcée, modification des branchements électriques, ...). La garantie Incendie prend en charge les dégâts causés par un incendie, qu’il vienne de l’intérieur ou de l’extérieur de votre voiture (explosion, incendie, problème électrique, foudre, ...).",
        // TODO !!
        [QuoteGaranties.Tempetes]:
          'Cette garantie intervient lorsque votre voiture est endommagée par des événements climatiques (tempête, grêle, glissement de terrain ou encore innondations). Nous prenons en charge vos réparations même si ces évènements ne sont pas reconnus comme catastrophes naturelles. ',
        // TODO
        [QuoteGaranties.CatNaturelles]:
          'Vous êtes protégé pour tous les dommages causés à votre véhicule lors d\'une catastrophe naturelle telle qu’une inondation, une tempête ou un tremblement de terre, lorsque ces évènements sont déclarés "catastrophe naturelle" par un arrêté interministériel.',
        // TODO
        [QuoteGaranties.Accidents]:
          "Nous vous protégeons en toutes circonstances, que vous soyiez responsable ou non, et même lorsque le responsable n'est pas identifié. La réparation de votre voiture est couverte dans les cas suivants : accident responsable ou non, vandalisme, dégats causés par un passant ou une autre voiture, collision avec un arbre, ou même un trottoir. En un mot, vous pouvez rouler l'esprit tranquille !",
      },
    },
  },
};

export default common;
