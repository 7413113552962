import { PasswordField, TextField } from '@flitter-insurance/nessui';
import { ChangeEventHandler, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useDashboardState } from '@/context/DashBoard';
import useUpdatePassword from '@/hooks/api/dashboard/useUpdatePassword';
import useSignInApi from '@/hooks/auth/useSignIn';
import { getCurrentUser } from '@/services/firebase';

import PasswordFields from '../../login/_login/PasswordFields';

interface PasswordChangeFormProps {
  onSuccess: () => void;
}

const usePasswordChangeForm = ({ onSuccess }: PasswordChangeFormProps) => {
  const [currentPwd, setCurrentPwd] = useState('');

  const [pwd, setPwd] = useState('');
  const [confirmPwd, setConfirmPwd] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');

  const { mutate: updatePassword } = useUpdatePassword();
  const onSignIn = useSignInApi();
  const [{ policyData }] = useDashboardState();

  const { t } = useTranslation();

  const onChangePwd: ChangeEventHandler<HTMLInputElement> = (e) => {
    setCurrentPwd(e.currentTarget.value);
  };

  const onSubmit = () => {
    setIsLoading(true);
    setError('');
    onSignIn(policyData?.driver_out?.qt_email as string, currentPwd)
      .then(() => {
        updatePassword(pwd);
        setIsLoading(false);
      })
      .then(() => {
        onSuccess();
        setIsLoading(false);
      })
      .catch((e) => {
        if (e.response?.data?.detail)
          setError(t('profile.passwordForm.error.' + e.response.data.detail.payload));
        else setError(t('resetPwd.error.general'));
        setIsLoading(false);
      });
  };

  return {
    currentPwd,
    pwd,
    setPwd,
    confirmPwd,
    setConfirmPwd,
    isLoading,
    onChangePwd,
    onSubmit,
    error,
  };
};

const PasswordChangeForm = (props: PasswordChangeFormProps) => {
  const {
    currentPwd,
    pwd,
    setPwd,
    confirmPwd,
    setConfirmPwd,
    isLoading,
    onChangePwd,
    onSubmit,
    error,
  } = usePasswordChangeForm(props);

  const { t } = useTranslation();

  const currentUserEmail = getCurrentUser()?.email || '';

  return (
    <form className='space-y-6'>
      <div className='hidden'>
        <TextField hidden value={currentUserEmail} type='email' name='email' />
      </div>
      <PasswordField
        id='current-password'
        autoComplete='current-password'
        value={currentPwd}
        onChange={onChangePwd}
        label={t('profile.passwordForm.currentPwdLabel')}
        error={error}
      />
      <PasswordFields
        pwd={pwd}
        setPwd={setPwd}
        confirmPwd={confirmPwd}
        setConfirmPwd={setConfirmPwd}
        onSubmit={onSubmit}
        isEditProfileLayout={true}
        isLoading={isLoading}
      />
    </form>
  );
};

export default PasswordChangeForm;
