import 'react-html5-camera-photo/build/css/index.css';

import { LottieAnimation } from '@flitter-insurance/nessui';
import { useMemo, useState } from 'react';
import Camera, { FACING_MODES } from 'react-html5-camera-photo';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';
import { Link } from 'react-router-dom';

import routes from '@/routes';

import SignInLayout from '../../../layouts/SignInLayout';
import HeadTitle from '../../../utils/HeadTitle';

const baseURL = import.meta.env.VITE_API_PUBLIC_DOMAIN as string;

const Picture = () => {
  const [imageUri, setImageUri] = useState('');
  const [successFirebase, setSuccessFirebase] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const { search } = useLocation();
  const params = useMemo(() => new URLSearchParams(search), [search]);

  const { t } = useTranslation();

  function uploadPhoto({
    file_name,
    base64_str,
    jwt_secret,
  }: {
    file_name: FileList | string;
    base64_str: string;
    jwt_secret: string;
  }) {
    setIsLoading(true);
    base64_str = base64_str.split(',')[1];
    fetch(`${baseURL}/api/v1/dashboard/uploadb64`, {
      method: 'POST',
      body: JSON.stringify({
        file_name: file_name,
        base64_str: base64_str,
        jwt_secret: jwt_secret,
      }),
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(function (response) {
        setIsLoading(false);
        return response.json();
      })
      .then(function () {
        setIsLoading(false);
        setSuccessFirebase(true);
        setErrorMsg('');
      })
      .catch(() => {
        setIsLoading(false);
        setErrorMsg(t('picture.error.general'));
      });
  }

  return (
    <SignInLayout>
      <HeadTitle titleKey='signIn.header' />
      <>
        {imageUri === '' ? (
          <>
            <p className='mb-2 font-bold text-center text-secondary-900'>{t('picture.takePic')}</p>
            <Camera
              onTakePhoto={(dataUri: string) => {
                setImageUri(dataUri);
              }}
              idealFacingMode={FACING_MODES.ENVIRONMENT}
              isImageMirror={false}
              isFullscreen={false}
              isMaxResolution={true}
            />
          </>
        ) : (
          <>
            {successFirebase && (
              <>
                <div className='mx-auto mb-7 max-w-xs'>
                  <div className='relative h-auto'>
                    <div className='w-auto'>
                      <img src='/img/protection.svg' className='-mb-5 ml-10' />
                    </div>
                  </div>
                  <img src='/img/manCarLarge.svg' alt='' />
                </div>
                <p className='mb-1 text-2xl font-bold text-center text-secondary-900'>
                  {t('picture.submitPicSuccessHeader')}
                </p>
                <p className='text-sm text-center text-gray-600'>
                  {t('picture.submitPicSuccessText')}
                </p>
                <Link to={routes.HOME}>
                  <button type='button' className='mt-9 btn btn-primary btn-xs lg:btn-sm btn-fluid'>
                    {t('picture.continue')}
                  </button>
                </Link>
              </>
            )}
            {!successFirebase &&
              (errorMsg.length > 0 ? (
                <>
                  <p className='mb-1 text-center text-primary-700'>{errorMsg}</p>
                  <button
                    type='button'
                    className='mt-4 bg-gray-50 btn btn-white btn-fluid'
                    onClick={() => setImageUri('')}
                  >
                    {t('picture.retakePic')}
                  </button>
                </>
              ) : (
                <>
                  <p className='mb-1 text-2xl font-bold text-center text-secondary-900'>
                    {t('picture.takePicSuccessHeader')}
                  </p>
                  <p className='text-sm text-center text-gray-600'>
                    {t('picture.takePicSuccessText')}
                  </p>
                  <img id='1' src={imageUri} className='block my-4 mx-auto' alt='' />

                  <button
                    type='button'
                    className='mb-2 btn btn-primary btn-xs lg:btn-sm btn-fluid'
                    onClick={() => {
                      uploadPhoto({
                        file_name: 'compteur-kilometrique',
                        base64_str: imageUri,
                        jwt_secret: params.get('secret') as string,
                      });
                    }}
                  >
                    {isLoading ? <LottieAnimation type='white-dots' /> : t('picture.confirm')}
                  </button>
                  <button
                    type='button'
                    className='bg-gray-50 btn btn-white btn-fluid'
                    onClick={() => setImageUri('')}
                  >
                    {t('picture.retakePic')}
                  </button>
                </>
              ))}
          </>
        )}
      </>
    </SignInLayout>
  );
};

export default Picture;
