import '../../../assets/css/checkoutForm.css';

import {
  CalendarIcon,
  Checkbox,
  CreditCard,
  LockFill,
  LottieAnimation,
  Mastercard,
  ModalSlider,
  TextFieldStripe,
  Visa,
} from '@flitter-insurance/nessui';
import { CardCvcElement, CardExpiryElement, CardNumberElement } from '@stripe/react-stripe-js';
import {
  StripeCardExpiryElementChangeEvent,
  StripeCardNumberElementChangeEvent,
} from '@stripe/stripe-js';
import { FormEventHandler, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { useSubscriptionState } from '@/context/Subscription';
import useCheckout from '@/hooks/api/useCheckout';
import { inputOptions } from '@/services/stripe';
import { toFixed } from '@/services/utils';

const CheckoutForm = ({
  price,
  price_2_times,
  billing,
  pricing_yearly_id,
  pricing_monthly_id,
  priceAfterDiscount,
  set_pricing_monthly_after_promo,
  set_pricing_yearly_after_promo,
  set_valid_promo_code,
  set_coupon_discount_monthly,
  set_coupon_discount_yearly,
}: {
  price: number;
  price_2_times: number;
  billing: 'mensuel' | 'annuel' | 'bi-annuel' | undefined;
  pricing_yearly_id: number | undefined;
  pricing_monthly_id: number | undefined;
  priceAfterDiscount: number | undefined;
  set_pricing_monthly_after_promo: any;
  set_pricing_yearly_after_promo: any;
  set_valid_promo_code: any;
  set_coupon_discount_monthly: any;
  set_coupon_discount_yearly: any;
}) => {
  const { t } = useTranslation();
  const [subscriptionState] = useSubscriptionState();
  const { onSubmit, isLoading } = useCheckout();
  const [errorCode, setErrorCode] = useState<string | null>(null);
  const [terms, setTerms] = useState<boolean | undefined>(false);
  const [coupon, setCoupon] = useState<string | null>(null);
  const [couponError, setCouponError] = useState<string | undefined>();
  const [enableCoupon, setEnableCoupon] = useState<boolean>(true);

  const [displayPromo, setDisplayPromo] = useState(false);
  const [promoCodeBtnDisabled, setPromoCodeBtnDisabled] = useState(true);

  const [isFocusCardNumber, setIsFocusCardNumber] = useState(false);
  const [isFocusCardExpire, setIsFocusCardExpire] = useState(false);
  const [isFocusCardSecurity, setIsFocusCardSecurity] = useState(false);

  const couponValidationURL = ((import.meta.env.VITE_API_PUBLIC_DOMAIN as string) +
    import.meta.env.VITE_API_COUPON_VALIDATION) as string;

  function handlePromoCodeUpdate(event: any) {
    if (event.target.value === '') {
      setPromoCodeBtnDisabled(true);
      setCoupon(null);
    } else {
      setCoupon(event.target.value.toUpperCase());
      setPromoCodeBtnDisabled(false);
    }
  }

  const onSubmitForm: FormEventHandler = async (e) => {
    e.preventDefault();
    await onSubmit(coupon).catch((error: any) => {
      if (typeof error.decline_code === 'string') setErrorCode(error.decline_code);
      else if (typeof error.code === 'string') setErrorCode(error.code);
      else if (error.detail && error.detail.message.includes('policy already exists'))
        setErrorCode('already_exist');
      else setErrorCode('unknown');
    });
  };

  useEffect(() => {
    if (typeof window !== 'undefined') {
      if (window.location.href.indexOf('partner_quote_id') > -1) {
        setCoupon('FLITTER50');
        handleSubmitCodePromo('FLITTER50');
        setEnableCoupon(false);
      }
    }
  }, []);

  function handleSubmitCodePromo(initiateCoupon = '') {
    fetch(
      couponValidationURL +
        '?coupon_name=' +
        (initiateCoupon.length === 0 ? coupon : initiateCoupon) +
        '&pricing_monthly_id=' +
        pricing_monthly_id +
        '&pricing_yearly_id=' +
        pricing_yearly_id,
      {
        method: 'GET',
        headers: {
          'Content-type': 'application/json',
          Accept: 'application/json',
        },
      }
    )
      .then((response) => {
        return response.json();
      })
      .then((result) => {
        window.analytics.track('coupon_validation', {
          ...subscriptionState,
          coupon: coupon,
          coupon_result: result,
          ts: new Date().toISOString(),
        });
        if (!result.detail) {
          set_pricing_monthly_after_promo(
            toFixed(result.filter((x: any) => x.qt_price_id == pricing_monthly_id)[0].amount, 2)
          );
          set_pricing_yearly_after_promo(
            toFixed(result.filter((x: any) => x.qt_price_id == pricing_yearly_id)[0].amount, 2)
          );
          set_coupon_discount_yearly(
            toFixed(
              result.filter((x: any) => x.qt_price_id == pricing_yearly_id)[0].coupon_discount,
              2
            )
          );
          set_coupon_discount_monthly(
            toFixed(
              result.filter((x: any) => x.qt_price_id == pricing_monthly_id)[0].coupon_discount,
              2
            )
          );
          setCouponError(undefined);
          setDisplayPromo(false);
          set_valid_promo_code(coupon);
        } else {
          setCoupon('');
          set_valid_promo_code();
          setCouponError('Ce code n’est pas valide.');
          set_pricing_monthly_after_promo(undefined);
          set_pricing_yearly_after_promo(undefined);
          set_coupon_discount_monthly(undefined);
          set_coupon_discount_yearly(undefined);
        }
      })
      .catch((error) => {
        console.log('error caught', error);
        window.analytics.track('coupon_validation', {
          ...subscriptionState,
          coupon: coupon,
          coupon_result: error,
          ts: new Date().toISOString(),
        });
      });
  }

  const onChangeCardNumber = (e: StripeCardNumberElementChangeEvent) => {
    if (e.complete)
      document.getElementById('card-expire')?.getElementsByTagName('input')[0].focus();
  };

  const onChangeCardExpire = (e: StripeCardExpiryElementChangeEvent) => {
    if (e.complete)
      document.getElementById('card-security')?.getElementsByTagName('input')[0].focus();
  };

  return (
    <>
      <form onSubmit={onSubmitForm} className='mb-8 text-gray-400'>
        {billing === 'mensuel' && (
          <div className='py-6 px-5 text-gray-700 bg-ivory rounded-2.5xl'>
            <p className='text-sm text-gray-700'>
              {enableCoupon
                ? t('subscribe.infoBulle.text', { price_2_times, price })
                : t('subscribe.infoBulleAutohero.text', { priceAfterDiscount })}
            </p>
          </div>
        )}
        <div className='mt-6 mb-4'>
          <div
            className={
              'flex items-center rounded-lg border-2 transition ' +
              (isFocusCardNumber ? 'border-secondary-800 shadow-lg' : 'border-gray-100')
            }
          >
            <CreditCard size={24} className={'ml-2 ' + (isFocusCardNumber && 'border-black')} />
            <CardNumberElement
              className='flex-grow p-3 text-gray-400 '
              options={{ ...inputOptions, placeholder: t('subscribe.cardNumberPlaceholder') }}
              onFocus={() => setIsFocusCardNumber(true)}
              onBlur={() => setIsFocusCardNumber(false)}
              onChange={(e) => onChangeCardNumber(e)}
            />
          </div>
        </div>
        <div className='grid grid-cols-2 gap-4 mb-4'>
          <div
            className={
              'flex items-center rounded-lg border-2 transition ' +
              (isFocusCardExpire ? 'border-secondary-800 shadow-lg' : 'border-gray-100')
            }
          >
            <CalendarIcon size={24} className={'ml-2 ' + (isFocusCardExpire && 'border-black')} />
            <CardExpiryElement
              className='flex-grow p-3 text-gray-400 '
              options={{ ...inputOptions, placeholder: t('subscribe.cardExpiryPlaceholder') }}
              onFocus={() => setIsFocusCardExpire(true)}
              onBlur={() => setIsFocusCardExpire(false)}
              onChange={(e) => onChangeCardExpire(e)}
              id='card-expire'
            />
          </div>
          <div
            className={
              'flex items-center rounded-lg border-2 transition ' +
              (isFocusCardSecurity ? 'border-secondary-800 shadow-lg' : 'border-gray-100')
            }
          >
            <LockFill size={24} className={'ml-2 ' + (isFocusCardSecurity && 'border-black')} />
            <CardCvcElement
              className='flex-grow p-3 text-gray-400 '
              options={{ ...inputOptions, placeholder: t('subscribe.cardCvcPlaceholder') }}
              onFocus={() => setIsFocusCardSecurity(true)}
              onBlur={() => setIsFocusCardSecurity(false)}
              id='card-security'
            />
          </div>
        </div>
        <div className='mb-5'>
          {enableCoupon && (
            <p
              className='my-4 text-sm text-secondary-500 cursor-pointer'
              onClick={() => setDisplayPromo(true)}
            >
              {t('subscribe.promoCodeLink')}
            </p>
          )}
          {displayPromo && (
            <ModalSlider
              title={
                <>
                  <img src='/img/promo-code-icon.png' className='inline mr-5' alt='' />
                  {t('subscribe.promoCode')}
                </>
              }
              open={true}
              onClose={() => setDisplayPromo(false)}
              onCloseOutside={false}
            >
              <p className='mt-10 text-sm text-gray-500'>{t('subscribe.promoCode')}</p>
              <p className='mt-2 text-gray-400'>
                <TextFieldStripe
                  placeholder={'FLITTER CODE'}
                  value={coupon as string}
                  onChange={(e) => handlePromoCodeUpdate(e)}
                  type={'text'}
                />
              </p>
              {couponError && <p className='mt-5 text-red-500'>{couponError}</p>}
              <button
                className='mt-8 btn btn-primary btn-fluid'
                disabled={promoCodeBtnDisabled}
                onClick={() => handleSubmitCodePromo()}
              >
                {t('subscribe.promoCodeAddBtn')}
              </button>
            </ModalSlider>
          )}
        </div>

        <div className='mb-6'>
          <div className='flex space-x-2.5'>
            <div>
              <Checkbox
                id='termsAgreement'
                checked={terms}
                onChange={(e) => setTerms(e.currentTarget.checked)}
              />
            </div>
            <div>
              <p className='text-sm text-gray-700'>
                <Trans
                  i18nKey='subscribe.termsTitle1'
                  values={{ link_text_1: t('subscribe.clickConfidentialite') }}
                >
                  text
                  <a
                    className='underline'
                    href='https://www.notion.so/flitter/Politique-de-confidentialit-Flitter-1bd88fc56ab54a96b8222cf39edcbe5b'
                    target='_blank'
                    rel='noreferrer'
                  >
                    politique de confidentialité
                  </a>
                </Trans>
                <Trans
                  i18nKey='subscribe.termsTitle2'
                  values={{
                    link_text_2: t('subscribe.clickStripe'),
                    link_text_3: t('subscribe.cgu'),
                  }}
                >
                  text
                  <a
                    className='underline'
                    href='https://flitter.notion.site/Conditions-G-n-rales-d-Utilisation-Flitter-8c1a1f88a5d0400f9a4794b0ace07c18'
                    target='_blank'
                    rel='noreferrer'
                  >
                    CGU
                  </a>
                  <a
                    className='underline'
                    href='https://stripe.com/fr/privacy'
                    target='_blank'
                    rel='noreferrer'
                  >
                    Stripe
                  </a>
                </Trans>
              </p>
            </div>
          </div>
        </div>
        <button className='font-bold btn btn-primary btn-fluid' disabled={!terms || isLoading}>
          {isLoading ? (
            <LottieAnimation type='white-dots' />
          ) : (
            t(`subscribe.submit.${billing}`, { price: price })
          )}
        </button>

        <p className='mt-8 font-bodyMedium text-xs text-center text-gray-900'>
          <span className='inline-block pr-1 pl-1 mr-2 text-center text-gray-900 bg-gray-100 rounded svg-inline'>
            <Visa width={24} height={24} />
          </span>
          <span className='inline-block pr-1 pl-1 mr-2 text-center text-gray-900 bg-gray-100 rounded svg-inline'>
            <Mastercard width={24} height={24} />
          </span>
          <span className='inline-block mr-2 text-center text-gray-900 svg-inline'>
            <LockFill width={24} height={24} />
          </span>
          {t('subscribe.securePayment')}
        </p>
      </form>
      <ModalSlider
        title={
          <>
            <img src='/img/payment-error-icon.png' className='inline mr-5' alt='' />
            {t('subscribe.errorModal.title')}
          </>
        }
        open={errorCode !== null}
        onClose={() => {
          setErrorCode(null);
        }}
      >
        <p className='py-4 mt-5 text-lg text-gray-700'>
          {t(`subscribe.errorModal.codes.${errorCode}`, t('subscribe.errorModal.codes.unknown'))}
        </p>

        <button
          className='mt-5 btn btn-primary btn-fluid'
          onClick={() => {
            setErrorCode(null);
          }}
        >
          {t('subscribe.promoCodeCloseBtn')}
        </button>
      </ModalSlider>
    </>
  );
};

export default CheckoutForm;
