import { useTranslation } from 'react-i18next';

import { Formule } from '@/components/utils/constants';

import WhiteCard from '../_shared/WhiteCard';

interface FranchiseSummaryProps {
  formule?: Formule;
  bdgPrice: number | null;
  accidentPrice: number | null;
}

const FranchiseSummary = ({ accidentPrice, bdgPrice, formule }: FranchiseSummaryProps) => {
  const { t } = useTranslation();
  if (!formule || formule === Formule.TIERS) {
    return null;
  }
  return (
    <div>
      <h2 className='mb-4'> {t('garanties.details.franchiseTitle')}</h2>
      <WhiteCard>
        <div className='grid grid-cols-1 py-2 divide-y divide-gray-50'>
          {typeof bdgPrice === 'number' && (
            <div className='flex py-4 sm:py-5 px-4 sm:px-6 pb-2 space-x-6'>
              <div className='flex-none'>
                <span
                  className={`p-2 w-11 h-11 sm:w-12 sm:h-12 bg-gray-50 rounded-full inline-block`}
                >
                  <img
                    src='/img/brokenGlass.svg'
                    alt='bris de glace'
                    className='w-10 sm:w-11 max-h-10 sm:max-h-11'
                  />
                </span>
              </div>
              <div className='grow'>
                <p className={'flex-grow mt-4 sm:mt-0 mb-1 sm:text-base font-bold text-gray-900'}>
                  {t('garanties.details.franchiseBdg')}
                </p>
                <p className='hidden sm:block text-lg sm:text-xl font-bold text-gray-700'>
                  {t('garanties.details.franchiseValue', { value: bdgPrice })}
                </p>
              </div>
              <div className='sm:hidden flex-1 text-right'>
                <p className='mt-2 text-lg sm:text-xl font-bold text-gray-700'>
                  {t('garanties.details.franchiseValue', { value: bdgPrice })}
                </p>
              </div>
            </div>
          )}
          {(formule === Formule.TOUS_RISQUES || formule === Formule.VOL_ICENDIE) && (
            <div className='flex py-4 sm:py-5 px-4 sm:px-6 pb-2 space-x-6'>
              <div className='flex-none'>
                <span
                  className={`p-2 w-11 h-11 sm:w-12 sm:h-12 bg-gray-50 rounded-full inline-block`}
                >
                  <img
                    src='/img/accident.svg'
                    alt='accident'
                    className='w-10 sm:w-11 max-h-10 sm:max-h-11'
                  />
                </span>
              </div>
              <div className='grow'>
                <p className={'flex-grow mt-4 sm:mt-0 mb-1 sm:text-base font-bold text-gray-900'}>
                  {t('garanties.details.franchiseAccident')}
                </p>
                <p className='hidden sm:block text-lg sm:text-xl font-bold text-gray-700'>
                  {t('garanties.details.franchiseValue', { value: accidentPrice })}
                </p>
              </div>
              <div className='sm:hidden flex-1 text-right'>
                <p className='mt-2 text-lg sm:text-xl font-bold text-gray-700'>
                  {t('garanties.details.franchiseValue', { value: accidentPrice })}
                </p>
              </div>
            </div>
          )}
        </div>
      </WhiteCard>
    </div>
  );
};

export default FranchiseSummary;
