import { ArrowDown } from '@flitter-insurance/nessui';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import UserDropdown from '../pages/dashboard/_shared/UserDropdown';

const PageContainer: FC = ({ children }) => {
  const { t } = useTranslation();

  return (
    <>
      <div className='grid lg:grid-cols-5 grid-flow-col gap-2 pb-24 m-6 sm:m-3 md:mx-6 xl:ml-32'>
        <div className='lg:col-span-4'>{children}</div>
        <div className='hidden lg:block lg:col-span-1 max-w-max'>
          <UserDropdown>
            <div className='flex items-center space-x-2 text-sm text-gray-800'>
              <span className='font-bold'>{t('nav.myAccount')}</span>
              <ArrowDown />
            </div>
          </UserDropdown>
        </div>
      </div>
    </>
  );
};

export default PageContainer;
