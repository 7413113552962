import { FC } from 'react';

import IconLogo from '@/assets/svg/IconLogo';

const SignInLayout: FC = ({ children }) => {
  return (
    <div className='flex flex-col justify-center px-4 pb-14 min-h-screen bg-gray-50'>
      <div className='m-auto sm:w-96'>
        <div className='flex justify-center mb-8'>
          <IconLogo width={137} height={40} />
        </div>
        <div>{children}</div>
      </div>
    </div>
  );
};

export default SignInLayout;
