import '../../../assets/css/helloSign.css';

import { LottieAnimation } from '@flitter-insurance/nessui';
import * as Sentry from '@sentry/react';
import dayjs from 'dayjs';
import HelloSignPopup from 'hellosign-embedded';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router';

import { useSubscriptionState } from '@/context/Subscription';
import useDriver from '@/hooks/api/useDriver';
import usePolicyEditique from '@/hooks/api/usePolicyEditique';
import useLocalStorage from '@/hooks/useLocalStorage';
import routes, { trackingUrlFinal, urlParamConcat } from '@/routes';

import HeadTitle from '../../utils/HeadTitle';
import TopMenu from '../topnav/TopNav';

const HelloSign = () => {
  const { search } = useLocation();
  const params = useMemo(() => new URLSearchParams(search), [search]);
  const { mutate: generateEditique } = usePolicyEditique({
    policy_uuid: params.get('policy_id') as string,
  });
  const [subscriptionState] = useSubscriptionState();
  const { push } = useHistory();
  const history = useHistory();

  const clientId = import.meta.env.VITE_HELLO_SIGN_CLIENT_ID as string | undefined;
  const baseURL = import.meta.env.VITE_API_PUBLIC_DOMAIN as string;
  const helloSignPath = '/api/v1/underwriting/editique/embedded-url';
  const helloSignPathRefresh = '/api/v1/underwriting/editique/embedded-url/refresh';

  const insuranceStartDate =
    localStorage.getItem('subscriptionState') !== null
      ? JSON.parse(localStorage.getItem('subscriptionState') as string)
      : null;

  const { data: driverData } = useDriver({ policy_uuid: params.get('policy_id') as string });

  const [signatureId, setSignatureId] = useLocalStorage('signatureId', null);
  const [displayPopup, setDisplayPopup] = useState(signatureId !== null);
  const [isLoading, setIsLoading] = useState(false);
  const [isFullLoading, setIsFullLoading] = useState(false);

  const { t } = useTranslation();
  const client = new HelloSignPopup();

  function generateIFrame(embeddedLink: string) {
    setIsLoading(false);
    setIsFullLoading(false);
    setDisplayPopup(false);

    client.open(embeddedLink, {
      clientId: clientId,
      skipDomainVerification: true,
      debug: true,
      allowCancel: false,
      container: document.getElementById('sign-here')!,
    });

    client.once('open', () => {
      window.analytics.track('hellosign_open', {
        ...subscriptionState,
        qt_quote_uuid: localStorage.getItem('paymentQuoteId'),
        uw_policy_uuid: String(params.get('policy_id')),
        ts: new Date().toISOString(),
      });
    });

    // The client has signed
    client.once('sign', () => {
      window.analytics.track('hellosign_signed', {
        ...subscriptionState,
        qt_quote_uuid: localStorage.getItem('paymentQuoteId'),
        uw_policy_uuid: String(params.get('policy_id')),
        ts: new Date().toISOString(),
      });
      setTimeout(() => {
        localStorage.removeItem('signatureId');
        localStorage.removeItem('paymentPolicyId');
        localStorage.removeItem('selectedPricingId');
        redirectToSignUp();
      }, 2000);
    });
  }

  function redirectToSignUp() {
    push({
      pathname: routes.SIGNUP,
      search: trackingUrlFinal,
    });
  }

  function generateHelloSignPopup() {
    setIsLoading(true);
    setIsFullLoading(true);
    fetch(`${baseURL}${helloSignPath}?policy_uuid=${params.get('policy_id')}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(function (response) {
        return response.json();
      })
      .then(function (response) {
        window.analytics.track('hellosign_generate_link', {
          ...subscriptionState,
          qt_quote_uuid: localStorage.getItem('paymentQuoteId'),
          uw_policy_uuid: String(params.get('policy_id')),
          ts: new Date().toISOString(),
        });
        setSignatureId(response.response.signature_id);
        generateIFrame(response.response.url);
      })
      .catch((e) => {
        Sentry.captureException(e);
        redirectToSignUp();
      });
  }

  function regenerateHelloSignPopup() {
    setIsLoading(true);
    setIsFullLoading(true);
    fetch(`${baseURL}${helloSignPathRefresh}?signature_id=${signatureId}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(function (response) {
        return response.json();
      })
      .then(function (response) {
        window.analytics.track('hellosign_regenerate_link', {
          ...subscriptionState,
          qt_quote_uuid: localStorage.getItem('paymentQuoteId'),
          uw_policy_uuid: String(params.get('policy_id')),
          ts: new Date().toISOString(),
        });
        generateIFrame(response.url);
      });
  }

  history.listen(() => {
    if (client.isOpen) client.close();
  });

  useEffect(() => {
    if (localStorage.getItem('paymentPolicyId') === null) {
      redirectToSignUp();
    } else if (signatureId === null && !subscriptionState.document_status) {
      setIsFullLoading(true);
      generateEditique({ policy_uuid: params.get('policy_id') as string });
    } else {
      if (signatureId === null) generateHelloSignPopup();
    }
  }, [subscriptionState.document_status]);

  return (
    <>
      <div className='px-5 xl:px-0 pt-8 pb-20 lg:pb-40 min-h-screen bg-ivory' id='helloSignPage'>
        <HeadTitle titleKey='helloSign.header' />
        <TopMenu
          stepSelected={4}
          backRoute={`${
            routes.SUBSCRIBE
          }${trackingUrlFinal}${urlParamConcat}quote_id=${localStorage.getItem('paymentQuoteId')}`}
        />
        <div className='container sm:mx-auto mt-8'>
          <h1>{t('helloSign.header')}</h1>

          {!displayPopup ? (
            <>
              {isFullLoading ? (
                <LottieAnimation type='full-page' text={t('helloSign.loadingText')} />
              ) : (
                <div id='sign-here' className='mx-auto mt-10 md:mt-20 max-w-5xl bg-white'></div>
              )}
            </>
          ) : (
            <div className='py-8 px-8 mx-auto mt-10 lg:mt-20 w-full xl:w-2/4 text-gray-500 bg-white shadow-lg'>
              <h2>{t('helloSign.popupTitle')}</h2>
              <p className='mt-5 mb-3'>
                {t('helloSign.popupP1', {
                  firstName: driverData?.qt_first_name,
                  date: dayjs(insuranceStartDate?.policy?.qt_start_date).format('DD-MM-YYYY'),
                })}
              </p>
              <p className='mt-3'>{t('helloSign.popupP2')}</p>
              <p className='mt-3'>{t('helloSign.popupP3')}</p>

              <div className='flex gap-4 mt-8'>
                <button
                  className='btn btn-primary btn-xs lg:btn-sm'
                  onClick={regenerateHelloSignPopup}
                >
                  {isLoading ? <LottieAnimation type='white-dots' /> : t('helloSign.popupBtnSign')}
                </button>

                <button className='btn btn-white btn-sm' onClick={redirectToSignUp}>
                  {t('helloSign.popupBtnCancel')}
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default HelloSign;
