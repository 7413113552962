import { useCallback } from 'react';

import { usePublicApiAxios } from '@/context/PublicApiAxios';

import { paths } from '../../../generated/publicApi';

const forgotPwdPath = '/api/v1/authentication/forgot-password';
type ForgotPwdRequest = paths['/api/v1/authentication/forgot-password']['post'];
type ForgotPwdQuery = ForgotPwdRequest['parameters']['query'];
type ForgotPwdResponse = ForgotPwdRequest['responses']['200']['content']['application/json'];

const useForgotPwdApi = () => {
  const axios = usePublicApiAxios();
  return useCallback(
    async (email: string) => {
      const params: ForgotPwdQuery = { email };
      await axios.post<ForgotPwdResponse>(forgotPwdPath, {}, { params });
    },
    [axios]
  );
};

export default useForgotPwdApi;
