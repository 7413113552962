import { Elements } from '@stripe/react-stripe-js';
import { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';

import { stripePromise } from '@/services/stripe';

import PaymentMethodForm from './PaymentMethodForm';

interface AddRibFormProps {
  onClose: () => void;
  setDisplayRib: Dispatch<SetStateAction<boolean>>;
}

const AddRibForm = ({ onClose, setDisplayRib }: AddRibFormProps) => {
  const { t } = useTranslation();
  //const { onSubmit, defaultChecked, onChangeDefaultChecked } = useAddRibForm(onClose);

  return (
    <Elements stripe={stripePromise}>
      <PaymentMethodForm onClose={onClose} setDisplayRib={setDisplayRib} />
      {/*
      <form onSubmit={onSubmit}>
        <IbanElement
          options={{ ...inputOptions, supportedCountries: StripeIbanSupportedContries }}
          className='p-3 mb-8 rounded-md border-2 border-gray-100'
        />
        
        <div className='flex items-center mb-8'>
          <Checkbox id={checkId} checked={defaultChecked} onChange={onChangeDefaultChecked} />
          <label className='pl-3 text-sm text-gray-600 cursor-pointer' htmlFor={checkId}>
            {t('ribPanel.useNewAsDefault')}
          </label>
        </div>
        
        <button type='submit' className='btn btn-primary btn-xs lg:btn-sm btn-fluid'>
          {t('ribPanel.submitRib')}
        </button>
      </form>
      */}
    </Elements>
  );
};

const checkId = 'use-rib-default';

export default AddRibForm;
