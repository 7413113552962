import { useTranslation } from 'react-i18next';

import { useAuth } from '@/context/Auth';
import { useDashboardState } from '@/context/DashBoard';
import useDocumentData from '@/hooks/api/dashboard/useDocument';

import PageContainer from '../../layouts/PageContainer';
import HeadTitle from '../../utils/HeadTitle';
import DocumentDownload from './_documents/DocumentDownload';

const Documents = () => {
  const { t } = useTranslation();
  const [{ policyData }] = useDashboardState();
  const [{ driver_id }] = useAuth();
  const { data: documentData, isLoading } = useDocumentData({ driver_id: driver_id as number });

  return (
    <PageContainer>
      <HeadTitle titleKey='documents.title' />
      <div className='space-y-8 sm:space-y-12'>
        <div>
          <h1>{t('documents.title')}</h1>
          <p className='text-base text-gray-600'>{t('documents.subtitle')}</p>
        </div>

        <div>
          <h2 className='mb-4 sm:ml-2 text-lg sm:text-xl'>{t('documents.important.title')}</h2>

          {!isLoading &&
            (policyData?.policy_out.uw_status === 'insured_pending' ||
              policyData?.policy_out.uw_status === 'insured_confirmed') && (
              <DocumentDownload
                title={t('documents.important.insurance')}
                description={t('documents.important.insuranceDescription')}
                fileType='carte-verte-temp'
              />
            )}
          {!isLoading && policyData?.policy_out.uw_status === 'insured_confirmed' && (
            <DocumentDownload
              title={t('documents.important.attestation')}
              description={t('documents.important.attestationDescription')}
              fileType='carte-verte'
            />
          )}
          {!isLoading && policyData?.policy_out.uw_status === 'insured_confirmed' && (
            <DocumentDownload
              title={t('documents.important.info')}
              description={t('documents.important.infoDescription')}
              fileType='releve-information'
            />
          )}
        </div>

        <div>
          <h2 className='mb-4 sm:ml-2 text-lg sm:text-xl'>
            {t('documents.documentsUtiles.title')}
          </h2>
          <DocumentDownload
            title={t('documents.contracts.paymentNotice')}
            description={t('documents.contracts.paymentNoticeDescription')}
            fileType={
              policyData?.policy_out?.qt_paymentfrequency_type === 'mensuel'
                ? 'echeancier-mensuel'
                : 'echeancier-annuel'
            }
          />
        </div>

        <div>
          <h2 className='mb-4 sm:ml-2 text-lg sm:text-xl'>{t('documents.contracts.title')}</h2>
          <DocumentDownload
            title={t('documents.contracts.specialConditions')}
            description={t('documents.contracts.specialConditionsDescription')}
            fileType='conditions-particulieres'
          />
          <DocumentDownload
            title={t('documents.contracts.generalConditions')}
            description={t('documents.contracts.generalConditionsDescription')}
            fileType='conditions-generales'
          />
          <DocumentDownload
            title={t('documents.contracts.garantieNoticeAssurance')}
            description={t('documents.contracts.garantieNoticeDescriptionAssurance')}
            fileType='ipid-assurance'
          />
          <DocumentDownload
            title={t('documents.contracts.garantieNoticeAssistance')}
            description={t('documents.contracts.garantieNoticeDescriptionAssistance')}
            fileType='ipid-assistance'
          />
        </div>
      </div>
    </PageContainer>
  );
};

export default Documents;
