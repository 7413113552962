import { useMutation } from 'react-query';
import { useHistory } from 'react-router';

import { useSubscriptionState } from '@/context/Subscription';

import { paths } from '../../../generated/publicApi';
import { usePublicApiAxios } from '../../context/PublicApiAxios';
import routes, { trackingUrlFinal, urlParamConcat } from '../../routes';

const cacheOptions = {
  staleTime: Infinity,
  refetchOnWindowFocus: false,
  cacheTime: Infinity,
  refetchOnMount: false,
};

const createPolicyPath = 'api/v1/underwriting/policy';
export type CreatePolicyQuery = paths['/api/v1/underwriting/policy']['post']['parameters']['query'];

export type CreatePolicyResult =
  paths['/api/v1/underwriting/policy']['post']['responses']['200']['content']['application/json'];

const useCreatePolicy = () => {
  const { push } = useHistory();
  const axios = usePublicApiAxios();
  const [, setSubscriptionState] = useSubscriptionState();

  const createPolicy = async ({ quote_uuid }: CreatePolicyQuery) => {
    const { data } = await axios.post<CreatePolicyResult>(
      createPolicyPath + `?quote_uuid=${quote_uuid}`,
      { quote_uuid }
    );
    localStorage.setItem('paymentQuoteId', String(quote_uuid));
    return data;
  };

  return useMutation(createPolicy, {
    onSuccess: (data) => {
      setSubscriptionState((prevState) => ({
        ...prevState,
        policy: data,
      }));
      localStorage.setItem('paymentPolicyId', String(data.uw_policy_uuid));
      push({
        pathname: routes.HELLOSIGN,
        search: trackingUrlFinal + urlParamConcat + 'policy_id=' + String(data.uw_policy_uuid),
      });
    },
    onError: (arg) => {
      console.log('error on price update', arg);
    },
  });
};

export default useCreatePolicy;
