import { CardNumberElement, IbanElement, useElements, useStripe } from '@stripe/react-stripe-js';
import toast from 'react-hot-toast';
import { useMutation } from 'react-query';

import { paths } from '@/../generated/publicApi';
import { useDashboardState } from '@/context/DashBoard';

import { usePublicApiAxios } from '../../../context/PublicApiAxios';
import usePaymentMethodData from './usePaymentMethods';

const setupIntentPath = '/api/v1/dashboard/stripe/setup-intent';
export type setupIntentQuery =
  paths['/api/v1/dashboard/stripe/setup-intent']['post']['parameters']['query'];

export type setupIntentResult =
  paths['/api/v1/dashboard/stripe/setup-intent']['post']['responses']['200']['content']['application/json'];

const useSetupIntent = () => {
  const axios = usePublicApiAxios();
  const stripe = useStripe();
  const elements = useElements();
  const [{ policyData }] = useDashboardState();

  const { refetch } = usePaymentMethodData({ policy_id: policyData?.policy_out.id as number });

  const createSetupIntent = async (params: setupIntentQuery) => {
    const { data } = await axios.post<setupIntentResult>(setupIntentPath, {}, { params });
    return data;
  };

  return useMutation(setupIntentPath, createSetupIntent, {
    onSuccess: async (data: any, { policy_id, payment_method_type }) => {
      if (payment_method_type === 'sepa_debit') {
        await stripe!
          .confirmSepaDebitSetup(data.response.client_secret, {
            payment_method: {
              sepa_debit: elements!.getElement(IbanElement)!,
              billing_details: {
                name: `${policyData?.driver_out.qt_first_name} ${policyData?.driver_out.qt_last_name}`,
                email: policyData?.driver_out.qt_email as string,
                address: {
                  city: policyData?.driver_out.qt_city,
                  country: 'FR',
                  line1: policyData?.driver_out.qt_line1,
                  postal_code: policyData?.driver_out.qt_postal_code,
                },
              },
            },
          })
          .then(async (result) => {
            if (result.error) {
              // Show error to your customer.
              toast.error(result.error.message as string);
              /*
                    setErrorIbanSetupMessage(result.error.message)
                    setModalState('ibanError')
                    window.analytics.track('IBAN_SETUP_FAILURE', {
                        paymentMethodId: result.error.payment_method.id,
                        setupIntent: result.error.setup_intent.id,
                        error: result.error.message
                    })
                    setLoading(false)
                    */
            } else {
              // Show a confirmation message to your customer.
              // The SetupIntent is in the 'succeeded' state.
              toast.success('Merci! Votre R.I.B a été ajouté comme paiement par défaut');
              await refetch();
              //window.location.reload();
              /*
                    window.analytics.track('IBAN_SETUP_SUCCESS', {
                        paymentMethodId: result.setupIntent.payment_method
                    })
                    setModalState('ibanSuccess')
                    setLoading(false)
                    */
            }
          });
      } else if (payment_method_type === 'card') {
        stripe!
          .confirmCardSetup(data.response.client_secret, {
            payment_method: {
              card: elements!.getElement(CardNumberElement)!,
              billing_details: {
                name: `${policyData?.driver_out.qt_first_name} ${policyData?.driver_out.qt_last_name}`,
                email: policyData?.driver_out.qt_email as string,
                address: {
                  city: policyData?.driver_out.qt_city,
                  country: 'FR',
                  line1: policyData?.driver_out.qt_line1,
                  postal_code: policyData?.driver_out.qt_postal_code,
                },
              },
            },
          })
          .then((result) => {
            if (result.error) {
              // Show error to your customer.
              toast.error(result.error.message as string);
              /*
                    window.analytics.track('CARD_SETUP_FAILURE', {
                        paymentMethodId: result.error.payment_method.id,
                        setupIntent: result.error.setup_intent.id,
                        error: result.error.message
                    })
                    setErrorCardSetupMessage(result.error.message)
                    setModalState('cardError')
                    setLoading(false)
                    */
            } else {
              // Show a confirmation message to your customer.
              // The SetupIntent is in the 'succeeded' state.
              toast.success('Merci! Votre carte bancaire a été ajoutée comme paiement par défaut');
              /*
                    window.analytics.track('CARD_SETUP_SUCCESS', {
                        paymentMethodId: result.setupIntent.payment_method
                    })
                    setModalState('cardSuccess')
                    setLoading(false)
                    */
            }
          });
      }
    },
    onError: (error) => {
      console.log(error);
      toast.error("Erreur lors d'ajout de votre R.I.B.");
    },
  });
};

export default useSetupIntent;
