import { useQuery } from 'react-query';

import { useSubscriptionState } from '@/context/Subscription';

import { paths } from '../../../generated/publicApi';
import { usePublicApiAxios } from '../../context/PublicApiAxios';

const cacheOptions = {
  staleTime: Infinity,
  refetchOnWindowFocus: false,
  cacheTime: Infinity,
  refetchOnMount: false,
};

const driverPath = '/api/v1/dashboard/driver';

export type DriverQuery = paths['/api/v1/dashboard/driver']['get']['parameters']['query'];
export type DriverResult =
  paths['/api/v1/dashboard/driver']['get']['responses']['200']['content']['application/json'];

const useDriver = ({ policy_uuid }: DriverQuery) => {
  const axios = usePublicApiAxios();
  const [, setSubscriptionState] = useSubscriptionState();

  const getDriver = async () => {
    const { data } = await axios.get<DriverResult>(driverPath, { params: { policy_uuid } });
    setSubscriptionState((prevState) => ({
      ...prevState,
      driver: data,
    }));
    return data;
  };

  return useQuery(driverPath, getDriver, cacheOptions);
};

export default useDriver;
