import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { useDashboardState } from '@/context/DashBoard';
import useGetInvoices from '@/hooks/api/dashboard/useGetInvoices';
import routes from '@/routes';

import PageContainer from '../../layouts/PageContainer';
import HeadTitle from '../../utils/HeadTitle';
import DocumentDownload from './_documents/DocumentDownload';

const Factures = () => {
  const { t } = useTranslation();
  const [{ policyData }] = useDashboardState();
  const { data: allInvoices, isLoading } = useGetInvoices({
    policy_id: Number(policyData?.policy_out.id),
  });

  if (isLoading) {
    return <></>;
  }

  return (
    <PageContainer>
      <HeadTitle titleKey='factures.title' />
      <div className='space-y-8 sm:space-y-12'>
        <div>
          <h1>{t('factures.title')}</h1>
          <p className='text-base text-gray-600'>
            {t('factures.subtitle')}{' '}
            <Link to={routes.PROFILE} className='text-base font-bold underline'>
              {t('factures.subtitleLink1')}
            </Link>
          </p>
          <p className='text-base text-gray-600'>
            {t('factures.subtitle2')}{' '}
            <Link to={routes.PAYMENTS} className='text-base font-bold underline'>
              {t('factures.subtitleLink2')}
            </Link>
          </p>
        </div>

        <div>
          <h2 className='mb-4 sm:ml-2 text-lg sm:text-xl'>{t('factures.categ')}</h2>

          {allInvoices!.map(function (
            item: {
              gs_path: string;
              period_end: string;
              period_start: string;
              amount: number;
            } | null
          ) {
            return (
              <DocumentDownload
                key={item!.gs_path}
                title={t('factures.invoice.date', {
                  month: item!.period_start.slice(3, 5),
                  year: item!.period_start.slice(6, 10),
                })} // mettre les dates du début ou bien de la fin ? A quoi sert ce texte ?
                description={t('factures.invoice.amount', { monthlyPremiumTTC: item?.amount })}
                fileType='facture'
                invoiceData={item!}
              />
            );
          })}
        </div>
      </div>
    </PageContainer>
  );
};

export default Factures;
