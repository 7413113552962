import { useQuery } from 'react-query';

import { useSubscriptionState } from '@/context/Subscription';

import { paths } from '../../../generated/publicApi';
import { usePublicApiAxios } from '../../context/PublicApiAxios';

const cacheOptions = {
  staleTime: Infinity,
  refetchOnWindowFocus: false,
  cacheTime: Infinity,
  refetchOnMount: false,
};

const quotePricingPath = '/api/v1/underwriting/quote_pricing';

export type QuotePricingQuery =
  paths['/api/v1/underwriting/quote_pricing']['get']['parameters']['query'];
export type QuotePricingResult =
  paths['/api/v1/underwriting/quote_pricing']['get']['responses']['200']['content']['application/json'];

const useQuotePricing = ({ quote_uuid }: QuotePricingQuery) => {
  const axios = usePublicApiAxios();
  const [, setSubscriptionState] = useSubscriptionState();

  const getPricingQuote = async () => {
    const { data } = await axios.get<QuotePricingResult>(quotePricingPath, {
      params: { quote_uuid },
    });
    setSubscriptionState((prevState) => ({
      ...prevState,
      quote_pricing: data,
    }));
    return data;
  };

  return useQuery(quotePricingPath, getPricingQuote, cacheOptions);
};

export default useQuotePricing;
