import { PaymentMethodType } from '@/components/utils/constants';
import { useDashboardState } from '@/context/DashBoard';

export default function IsRibAdded() {
  const [{ paymentMethodData }] = useDashboardState();
  let result = false;
  paymentMethodData?.map((pm) => {
    if (pm.payment_method.type === PaymentMethodType.Sepa) {
      result = true;
    }
  });

  return result;
}
