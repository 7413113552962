import { useQuery } from 'react-query';

import { usePublicApiAxios } from '../../context/PublicApiAxios';

const cacheOptions = {
  staleTime: Infinity,
  refetchOnWindowFocus: false,
  cacheTime: Infinity,
  refetchOnMount: false,
};

const insurerPath = '/api/v1/dashboard/insurers';

const useInsurer = () => {
  const axios = usePublicApiAxios();
  const getInsurers = async () => {
    const { data } = await axios.get(insurerPath, {});
    return data;
  };

  return useQuery(insurerPath, getInsurers, cacheOptions);
};

export default useInsurer;
