const IconCalendar = ({ width = 24, height = 24 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox={'0 0 24 24'}
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M7.5 3V6'
        stroke='#1E2044'
        strokeWidth='1.8'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M16.5 3V6'
        stroke='#1E2044'
        strokeWidth='1.8'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M18 4.5H6C4.34315 4.5 3 5.84315 3 7.5V18C3 19.6569 4.34315 21 6 21H18C19.6569 21 21 19.6569 21 18V7.5C21 5.84315 19.6569 4.5 18 4.5Z'
        stroke='#1E2044'
        strokeWidth='1.8'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M8.5 11H9.11448C9.37234 11 9.62367 11.0811 9.83285 11.2319C10.042 11.3827 10.1985 11.5955 10.28 11.8401V11.8401C10.3471 12.0414 10.3609 12.2566 10.3202 12.4648C10.2794 12.673 10.1854 12.8671 10.0473 13.0281L7.5 16H10.5'
        stroke='#F45B69'
        strokeWidth='1.8'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M7.5 12C7.5 11.7348 7.60536 11.4804 7.79289 11.2929C7.98043 11.1054 8.23478 11 8.5 11'
        stroke='#F45B69'
        strokeWidth='1.8'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M14.5 11H15.1145C15.3723 11 15.6237 11.0811 15.8329 11.2319C16.042 11.3827 16.1985 11.5955 16.28 11.8401V11.8401C16.3471 12.0414 16.3609 12.2566 16.3202 12.4648C16.2794 12.673 16.1854 12.8671 16.0473 13.0281L13.5 16H16.5'
        stroke='#F45B69'
        strokeWidth='1.8'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M13.5 12C13.5 11.7348 13.6054 11.4804 13.7929 11.2929C13.9804 11.1054 14.2348 11 14.5 11'
        stroke='#F45B69'
        strokeWidth='1.8'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export default IconCalendar;
