// Import the functions you need from the SDKs you need
// https://firebase.google.com/docs/web/setup#available-libraries
import { initializeApp } from 'firebase/app';
import {
  getAuth,
  NextOrObserver,
  onAuthStateChanged as fbOnAuthStateChanged,
  signInWithCustomToken as fbSignInWithCustomToken,
  signInWithEmailAndPassword as fbSignInWithEmailAndPassword,
  signOut as fbSignOut,
  User,
} from 'firebase/auth';

export type { User } from 'firebase/auth';

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: import.meta.env.VITE_FIREBASE_API_KEY as string,
  authDomain: import.meta.env.VITE_FIREBASE_AUTH_DOMAIN as string,
  databaseURL: import.meta.env.VITE_FIREBASE_DATABASE_URL as string,
  projectId: import.meta.env.VITE_FIREBASE_PROJECT_ID as string,
  storageBucket: import.meta.env.VITE_FIREBASE_STORAGE_BUCKET as string,
  messagingSenderId: import.meta.env.VITE_FIREBASE_MESSAGING_SENDER_ID as string,
  appId: import.meta.env.VITE_FIREBASE_APP_ID as string,
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

const auth = getAuth(app);

export const signInWithCustomToken = (customToken: string) =>
  fbSignInWithCustomToken(auth, customToken);
export const signInWithEmailAndPassword = (email: string, password: string) =>
  fbSignInWithEmailAndPassword(auth, email, password);
export const signOut = () => fbSignOut(auth);

export const onAuthStateChanged = (cb: NextOrObserver<User>) => fbOnAuthStateChanged(auth, cb);

export const getCurrentUser = () => {
  auth.currentUser?.getIdToken().then((token) => {
    //console.log('token', token);
  });
  return auth.currentUser;
};
