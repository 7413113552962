import { Check, Close } from '@flitter-insurance/nessui';
import { useTranslation } from 'react-i18next';

import {
  LowerCaseRegex,
  NumericRegex,
  SpecialCharRegex,
  UpperCaseRegex,
} from '@/components/utils/constants';

interface Props {
  password: string;
}
const PasswordCheck = ({ password }: Props) => {
  const { t } = useTranslation();

  const validationRules = {
    length: password.length >= 8,
    lowercase: LowerCaseRegex.test(password),
    uppercase: UpperCaseRegex.test(password),
    special: SpecialCharRegex.test(password),
    number: NumericRegex.test(password),
  };

  return (
    <div className='flex flex-wrap gap-x-5'>
      {Object.entries(validationRules).map(([testLabel, textValid], index) => {
        return (
          <div
            key={index}
            className={`${
              textValid ? 'text-gray-700' : 'text-gray-300'
            } w-auto flex justify-start items-center`}
          >
            {textValid ? (
              <Check className='text-positive-500' height={28} width={24} />
            ) : (
              <Close className='text-gray-300' height={28} width={24} />
            )}
            <span className='ml-2.5'>{t(`signUp.rules.${testLabel}`)}</span>
          </div>
        );
      })}
    </div>
  );
};

export default PasswordCheck;
