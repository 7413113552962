import * as Sentry from '@sentry/react';
import { useMutation } from 'react-query';
import { useHistory } from 'react-router-dom';

import { useSubscriptionState } from '@/context/Subscription';
import routes from '@/routes';

import { paths } from '../../../generated/publicApi';
import { usePublicApiAxios } from '../../context/PublicApiAxios';

const policyEditiquePath = '/api/v1/underwriting/policyeditique';

export type PolicyEditiqueQuery =
  paths['/api/v1/underwriting/policyeditique']['post']['parameters']['query'];
export type PolicyEditiqueResult =
  paths['/api/v1/underwriting/policyeditique']['post']['responses']['200']['content']['application/json'];

const usePolicyEditique = ({ policy_uuid }: PolicyEditiqueQuery) => {
  const axios = usePublicApiAxios();
  const [, setSubscriptionState] = useSubscriptionState();
  const { push } = useHistory();

  const createPolicyEditique = async (params: PolicyEditiqueQuery) => {
    const { data } = await axios.post<PolicyEditiqueResult>(policyEditiquePath, {}, { params });
    return data;
  };

  return useMutation(policyEditiquePath, createPolicyEditique, {
    onSuccess: async (data) => {
      console.log('editique created', data);

      setSubscriptionState((prevState) => ({
        ...prevState,
        document_status: true,
      }));
    },
    onError: (error: any) => {
      Sentry.captureException(error);
      setSubscriptionState((prevState) => ({
        ...prevState,
        document_status: false,
      }));
      push({
        pathname: routes.SIGNUP,
      });
    },
  });
};

export default usePolicyEditique;
