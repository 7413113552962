import '../../../assets/css/subscribe.css';

import { Switch } from '@flitter-insurance/nessui';
import { Elements } from '@stripe/react-stripe-js';
import { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';

import useQuotePricing from '@/hooks/api/useQuotePricing';
import { trackingUrlFinal, urlParamConcat } from '@/routes';
import { stripePromise } from '@/services/stripe';
import { toFixed } from '@/services/utils';

import HeadTitle from '../../utils/HeadTitle';
import PaymentSummary from '../dashboard/_payment/PaymentSummary';
import TopMenu from '../topnav/TopNav';
import CheckoutForm from './CheckoutForm';

const useSubscribe = () => {
  const { t } = useTranslation();
  const { search } = useLocation();
  const params = useMemo(() => new URLSearchParams(search), [search]);
  const { data: quote_pricing, isLoading } = useQuotePricing({
    quote_uuid: params.get('quote_id') as string,
  });

  return { quote_pricing, isLoading, t, quote_id: params.get('quote_id') };
};

const Subscribe = () => {
  const { quote_pricing, t, quote_id } = useSubscribe();
  const [switchChange, setSwitchChange] = useState(false);
  const monthlyPricing = quote_pricing?.pricing_out.monthly;
  const annualPricing = quote_pricing?.pricing_out.annually;
  const yearlyDiscount = toFixed(Number(quote_pricing?.discount), 0);

  const [validCodePromo, setValidCodePromo] = useState<string>();
  const [monthlyPricingAfterPromo, setMonthlyPricingAfterPromo] = useState<number | undefined>();
  const [annualPricingAfterPromo, setAnnualPricingAfterPromo] = useState<number | undefined>();
  const [couponDiscountMonthly, setCouponDiscountMonthly] = useState<number | undefined>();
  const [couponDiscountYearly, setCouponDiscountYearly] = useState<number | undefined>();

  const linkURL = import.meta.env.VITE_LINK_APP_DOMAIN as string;

  useEffect(() => {
    quote_pricing?.quote_out?.qt_paymentfrequency_type === 'mensuel'
      ? setSwitchChange(false)
      : setSwitchChange(true);
  }, [quote_pricing]);

  useEffect(() => {
    localStorage.removeItem('dashboardState');
    localStorage.removeItem('subscriptionState');
    localStorage.removeItem('authState');
  }, []);

  function handleChange() {
    setSwitchChange(!switchChange);
    if (switchChange)
      localStorage.setItem('selectedPricingId', (monthlyPricing?.id || '').toString());
    else localStorage.setItem('selectedPricingId', (annualPricing?.id || '').toString());
  }

  function getFranchiseBrokenGlace() {
    const excessDetails = quote_pricing?.quote_out?.qt_excessbdg_type;

    if (excessDetails) {
      switch (excessDetails) {
        case 'DEFAULT':
          return Object(quote_pricing?.pricing_out?.monthly).qt_excess_details.BDG.DEFAULT.value;
        case 'REDUCED':
          return Object(quote_pricing?.pricing_out?.monthly).qt_excess_details.BDG.REDUCED.value;
        default:
          return Object(quote_pricing?.pricing_out?.monthly).qt_excess_details.BDG.DEFAULT.value;
      }
    }
  }

  function getFranchiseAccident() {
    const excessDetails = quote_pricing?.quote_out?.qt_excessda_type;

    if (excessDetails) {
      switch (excessDetails) {
        case 'DEFAULT':
          return Object(quote_pricing?.pricing_out?.monthly).qt_excess_details.DA.DEFAULT.value;
        case 'REDUCED':
          return Object(quote_pricing?.pricing_out?.monthly).qt_excess_details.DA.REDUCED.value;
        case 'INCREASED':
          return Object(quote_pricing?.pricing_out?.monthly).qt_excess_details.DA.INCREASED.value;
        default:
          return Object(quote_pricing?.pricing_out?.monthly).qt_excess_details.DA.DEFAULT.value;
      }
    }
  }

  const priceMonth = Number(toFixed(Number(monthlyPricing?.qt_flitter_total_prime_ttc) / 12, 2));
  const priceAnnualBase = Number(toFixed(priceMonth * 12, 2));
  const priceAnnual = Number(toFixed(Number(annualPricing?.qt_flitter_total_prime_ttc), 2));
  const price = switchChange ? priceAnnual : priceMonth;
  const price_2_times = switchChange ? priceAnnual : 2 * priceMonth;

  return (
    <>
      {quote_pricing && (
        <div className='px-5 lg:px-0 pt-8 pb-40 bg-ivory'>
          <HeadTitle titleKey='subscribe.title' />
          <TopMenu
            stepSelected={3}
            backRoute={`${linkURL}/quote/summary${trackingUrlFinal}${urlParamConcat}quote_id=${quote_id}`}
          />

          <div className='container sm:mx-auto mt-8'>
            <h1>{t('subscribe.title')}</h1>
          </div>
          <div className='container grid lg:grid-cols-2 mx-auto'>
            <div className='mt-5 sm:mt-10'>
              <PaymentSummary
                quote_id={quote_id}
                franchiseBDG={getFranchiseBrokenGlace()}
                franchiseDA={getFranchiseAccident()}
              />
            </div>
            <div className='py-8 px-5 lg:px-8 bg-white rounded-lg shadow-lg'>
              <div className='mb-8 divide-y divide-gray-50'>
                <div className='grid relative grid-flow-col mb-10'>
                  <h2>{t('subscribe.cotisationTitle')}</h2>
                  <div
                    className='absolute top-0 right-0 pl-6 sm:pl-9 text-gray-700'
                    id='contribution-price-bg'
                  >
                    <span id='price' className='font-displayBold text-xl sm:text-3xl text-gray-800'>
                      {!switchChange ? priceMonth : priceAnnual}€
                    </span>{' '}
                    <span className='text-sm sm:text-lg font-bold text-gray-900'>
                      {' '}
                      {!switchChange ? '/mois' : '/an'}
                    </span>{' '}
                    <br />
                    {!switchChange && (
                      <span className='text-base text-gray-700' id='price-year'>
                        soit {priceAnnualBase}€/an{' '}
                      </span>
                    )}
                  </div>
                </div>

                <p className='mb-8 border-none'>
                  <Switch onChange={handleChange} checked={switchChange} className='-mb-2' />
                  <span
                    className={
                      'ml-1 lg:ml-3 text-sm lg:text-base ' +
                      (switchChange ? 'text-gray-700' : 'text-gray-200')
                    }
                  >
                    Économiser {yearlyDiscount}% en payant à l’année
                  </span>
                </p>

                {!switchChange ? (
                  <div className=''>
                    <QuoteRecapItem2 key={'premierPaiement'} price={price_2_times}>
                      {t('subscribe.twoMonthPayment')}
                    </QuoteRecapItem2>
                  </div>
                ) : (
                  <div className=''>
                    <QuoteRecapItem2 key={'premierPaiement'} price={price_2_times}>
                      {t('subscribe.annualFirstPayment')}
                    </QuoteRecapItem2>
                  </div>
                )}

                {monthlyPricingAfterPromo && (
                  <>
                    <div className='flex items-center text-gray-900'>
                      <div className='flex-grow py-2'>
                        {' '}
                        {t('subscribe.promoCode')} {validCodePromo}
                      </div>
                      <div className=''>
                        - {switchChange ? couponDiscountYearly : couponDiscountMonthly}€
                      </div>
                    </div>
                    <div className='flex items-center text-gray-900'>
                      <div className='flex-grow py-2'>{t('subscribe.afterPromoText')}</div>
                      <div className=''>
                        {switchChange ? annualPricingAfterPromo : monthlyPricingAfterPromo}€
                      </div>
                    </div>
                  </>
                )}
              </div>

              <Elements stripe={stripePromise}>
                <CheckoutForm
                  price={price}
                  price_2_times={price_2_times}
                  billing={!switchChange ? 'mensuel' : 'annuel'}
                  pricing_monthly_id={monthlyPricing?.id}
                  pricing_yearly_id={annualPricing?.id}
                  priceAfterDiscount={
                    switchChange ? annualPricingAfterPromo : monthlyPricingAfterPromo
                  }
                  set_pricing_monthly_after_promo={setMonthlyPricingAfterPromo}
                  set_pricing_yearly_after_promo={setAnnualPricingAfterPromo}
                  set_valid_promo_code={setValidCodePromo}
                  set_coupon_discount_monthly={setCouponDiscountMonthly}
                  set_coupon_discount_yearly={setCouponDiscountYearly}
                />
              </Elements>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

interface QuoteRecapItem2Props {
  price: number;
}

const QuoteRecapItem2: FC<QuoteRecapItem2Props> = ({ price, children }) => {
  const { t } = useTranslation();
  return (
    <div className='flex items-center text-flitblue-dark'>
      <div className='flex-grow py-2'>{children}</div>
      <div className='text-right' style={{ minWidth: '60px' }}>
        {t(`subscribe.first`, { price: price })}
      </div>
    </div>
  );
};

export default Subscribe;
