import { Helmet, HelmetProvider } from 'react-helmet-async';
import { Toaster } from 'react-hot-toast';
import { BrowserRouter } from 'react-router-dom';

import Router from './components/router/Router';
import { toastOptions } from './components/utils/constants';
import { AuthProvider } from './context/Auth';
import { DashboardStateProvider } from './context/DashBoard';
import { PublicApiAxiosProvider } from './context/PublicApiAxios';
import { InstanciatedQueryClientProvider } from './context/QueryClient';
import { SubscriptionStateProvider } from './context/Subscription';

function App() {
  return (
    <HelmetProvider>
      <Helmet>
        <link rel='icon' type='image/png' href='/img/favicon_blue.png' />
      </Helmet>
      <AuthProvider>
        <PublicApiAxiosProvider>
          <SubscriptionStateProvider>
            <DashboardStateProvider>
              <InstanciatedQueryClientProvider>
                <BrowserRouter>
                  <Router />
                  <Toaster position='top-center' toastOptions={toastOptions} />
                </BrowserRouter>
              </InstanciatedQueryClientProvider>
            </DashboardStateProvider>
          </SubscriptionStateProvider>
        </PublicApiAxiosProvider>
      </AuthProvider>
    </HelmetProvider>
  );
}

export default App;
