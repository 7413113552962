import { useCallback } from 'react';

import { usePublicApiAxios } from '@/context/PublicApiAxios';

import { paths } from '../../../generated/publicApi';

const getDriverByTokenPath = '/api/v1/authentication/get_driver_by_token';
type GetDriverByTokenRequest = paths['/api/v1/authentication/get_driver_by_token']['get'];
type GetDriverByTokenQuery = GetDriverByTokenRequest['parameters']['query'];
type GetDriverByTokenResponse =
  GetDriverByTokenRequest['responses']['200']['content']['application/json'];

const resetPwdPath = '/api/v1/authentication/update_password';
type ResetPwdRequest = paths['/api/v1/authentication/update_password']['put'];
type ResetPwdBody = ResetPwdRequest['requestBody']['content']['application/json'];
type ResetPwdResponse = ResetPwdRequest['responses']['200']['content']['application/json'];

export const useGetDriverByTokenApi = () => {
  const axios = usePublicApiAxios();
  return useCallback(
    async (token: string) => {
      const params: GetDriverByTokenQuery = { token };
      return await axios.get<GetDriverByTokenResponse>(getDriverByTokenPath, { params });
    },
    [axios]
  );
};

const useResetPwdApi = () => {
  const axios = usePublicApiAxios();
  return useCallback(
    async (password: string, driverId: number) => {
      const body: ResetPwdBody = {
        driver_id: driverId,
        password,
      };
      return await axios.put<ResetPwdResponse>(resetPwdPath, body);
    },
    [axios]
  );
};

export default useResetPwdApi;
