const IconDownload = ({ width = 16, height = 16 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox={'0 0 16 16'}
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M10.3702 7.40735L7.99957 9.77802L5.62891 7.40735'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M8 2.66669V9.77802'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M13.3346 10.9626C13.3346 11.5914 13.0849 12.1944 12.6403 12.639C12.1957 13.0835 11.5927 13.3333 10.964 13.3333H5.03864C4.4099 13.3333 3.80691 13.0835 3.36232 12.639C2.91773 12.1944 2.66797 11.5914 2.66797 10.9626V10.9626'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export default IconDownload;
