import { Close, ModalSlider } from '@flitter-insurance/nessui';
import { Transition } from '@headlessui/react';
import { FC, useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';

import IconBars from '@/assets/svg/IconBars';
import IconLogo from '@/assets/svg/IconLogo';
import IconUser from '@/assets/svg/IconUser';
import routes from '@/routes';

import UserDropdown from '../pages/dashboard/_shared/UserDropdown';
import Sidebar from '../pages/dashboard/sidebar/Sidebar';

const SideBarLayout: FC = ({ children }) => {
  const [showMobileMenu, setShowMobileMenu] = useState<boolean>(false);
  const { pathname } = useLocation();
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setShowMobileMenu(false);
  }, [pathname, setShowMobileMenu]);

  // useful when we are already on the page but the link is clicked anyway
  const onNavigate = () => {
    setShowMobileMenu(false);
  };
  const onSidebar = () => {
    setShowMobileMenu((prev) => !prev);
  };

  return (
    <div className='flex flex-col lg:flex-row min-h-screen'>
      {/* top bar mobile */}
      <div className='flex lg:hidden flex-nowrap justify-between items-center py-3 px-4 border-b-2 border-gray-50'>
        <button onClick={onSidebar} className='p-1 rounded-md border border-gray-100 '>
          {showMobileMenu ? <Close size={24} /> : <IconBars />}
        </button>
        <Link to={routes.ROOT} className='block' onClick={onNavigate}>
          <IconLogo />
        </Link>
        <UserDropdown>
          <div className='p-1 rounded-md border border-gray-100'>
            <IconUser />
          </div>
        </UserDropdown>
      </div>

      {/* menu mobile */}
      <div className='lg:hidden relative'>
        <Transition
          show={showMobileMenu}
          enter='transition-transform transform duration-200 ease-out'
          enterFrom='-translate-x-full'
          enterTo='translate-x-0'
          leave='transition-transform transform duration-200 ease-in'
          leaveFrom='translate-x-0'
          leaveTo='-translate-x-full'
        >
          <div className='absolute top-0 z-10 px-8 pt-6 pb-10 w-full bg-white'>
            <Sidebar onNavigate={onNavigate} openModal={setOpen} />
          </div>
        </Transition>
      </div>

      {/* menu desktop */}
      <div className='hidden lg:block flex-shrink-0 px-8 pt-10 bg-white'>
        <div className='lg:w-56'>
          <Link to={routes.ROOT} className='block py-3 mb-9'>
            <IconLogo />
          </Link>
          <Sidebar openModal={setOpen} />
        </div>
      </div>

      <SinistreModal open={open} onCloseModal={() => setOpen(false)} />

      {/* page content */}
      <div className='flex-grow pt-0 sm:pt-10 bg-gray-50'>{children}</div>
    </div>
  );
};

const SinistreModal = ({ onCloseModal, open }: { onCloseModal: () => void; open: boolean }) => {
  const { t } = useTranslation();
  const onCopyNumber = () => {
    navigator.clipboard.writeText(t('contact.sinistre.email'));
    toast.success("L'adresse email a été copiée !");
  };

  return (
    <ModalSlider onClose={onCloseModal} open={open} title={'Vous avez un sinistre ?'}>
      <div className='pt-4'>
        <p>{'Pour déclarer un sinistre, vous pouvez le faire :'}</p>
        <ul>
          <li className='pl-2 mt-2'>
            {'- Par téléphone au'}{' '}
            <span className='font-bold text-primary-500'>{t('contact.sinistre.phoneNumber')}</span>{' '}
            {'du lundi au vendredi de 9h à 18h'}
          </li>
          <li className='pl-2 mt-2 mb-4'>
            {'- Par mail à '}{' '}
            <span className='font-bold text-primary-500'>{t('contact.sinistre.email')}</span>
          </li>
        </ul>
        <div className='flex flex-wrap gap-4'>
          <button
            type='button'
            onClick={onCopyNumber}
            className='btn btn-white btn-xs lg:btn-sm btn-fluid sm:btn-solid'
          >
            {t('contact.sinistre.copy')}
          </button>
          <a
            className='block w-full sm:w-auto'
            href={`tel:${t('contact.sinistre.phoneNumber').replace(/\s/g, '')}`}
          >
            <button
              type='button'
              className='btn btn-primary btn-xs lg:btn-sm btn-fluid sm:btn-solid'
            >
              {t('contact.sinistre.call')}
            </button>
          </a>
        </div>
      </div>
    </ModalSlider>
  );
};

export default SideBarLayout;
