import { ModalSlider } from '@flitter-insurance/nessui';
import { FC, ReactNode, useState } from 'react';
import { useTranslation } from 'react-i18next';

import IconCalendar from '@/assets/svg/IconCalendar';
import IconChat from '@/assets/svg/IconChat';
import IconHelp from '@/assets/svg/IconHelp';
import IconMessage from '@/assets/svg/IconMessage';

import PageContainer from '../../layouts/PageContainer';
import HeadTitle from '../../utils/HeadTitle';

const Contact = () => {
  const { t } = useTranslation();
  return (
    <PageContainer>
      <HeadTitle titleKey='contact.title' />

      <div className='mb-6 sm:mb-12'>
        <h1>{t('contact.title')}</h1>
        <p className='text-base text-gray-600'>{t('contact.subtitle')}</p>
      </div>
      <div className='space-y-8 sm:space-y-8'>
        <AssistanceBanner />
        <div className='grid grid-cols-1 lg:grid-cols-2 gap-8'>
          <ContactCard
            title={t('contact.chat.title')}
            text={t('contact.chat.text')}
            icon={<IconChat />}
          >
            {/* todo : open intercom */}
            <button className='btn btn-white btn-xs lg:btn-sm btn-fluid' id='my-intercom-button'>
              {t('contact.chat.cta')}
            </button>
          </ContactCard>
          <ContactCard
            title={t('contact.rdv.title')}
            text={t('contact.rdv.text')}
            icon={<IconCalendar />}
          >
            <a target='_blank' rel='noreferrer noopener' href={t('contact.rdv.link')}>
              <button className='btn btn-white btn-xs lg:btn-sm btn-fluid'>
                {t('contact.rdv.cta')}
              </button>
            </a>
          </ContactCard>
          <ContactCard
            title={t('contact.email.title')}
            text={t('contact.email.text')}
            icon={<IconMessage />}
          >
            <EmailModal />
          </ContactCard>
          <ContactCard
            title={t('contact.faq.title')}
            text={t('contact.faq.text')}
            icon={<IconHelp />}
          >
            <a target='_blank' rel='noreferrer noopener' href={t('contact.faq.link')}>
              <button className='btn btn-white btn-xs lg:btn-sm btn-fluid'>
                {t('contact.faq.cta')}
              </button>
            </a>
          </ContactCard>
        </div>
      </div>
    </PageContainer>
  );
};

const AssistanceBanner = () => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const onOpenModal = () => {
    setOpen(true);
  };
  const onCloseModal = () => {
    setOpen(false);
  };

  return (
    <div className='flex flex-col sm:flex-row sm:items-center p-4 lg:p-6 bg-yellow-100 rounded-2xl'>
      <img src='/img/icons/iconTruck2.svg' className='flex-shrink-0 mb-3 lg:mb-0 w-9' />
      <div className='sm:flex-grow mb-4 sm:mb-0 sm:ml-8'>
        <p className='text-base font-bold text-gray-900'>{t('contact.assistance.title')}</p>
        <p className='text-gray-600'>{t('contact.assistance.text')}</p>
      </div>
      <button
        className='sm:ml-8 whitespace-nowrap btn btn-primary btn-xs lg:btn-sm'
        type='button'
        onClick={onOpenModal}
      >
        {t('contact.assistance.cta')}
      </button>
      <AssistanceModal open={open} onCloseModal={onCloseModal} />
    </div>
  );
};

interface EmailModalProps {
  onCloseModal: () => void;
  open: boolean;
}
const AssistanceModal = ({ onCloseModal, open }: EmailModalProps) => {
  const { t } = useTranslation();
  const onCopyNumber = () => {
    navigator.clipboard.writeText(t('contact.assistance.phoneNumber'));
  };

  return (
    <ModalSlider onClose={onCloseModal} open={open} title={t('contact.assistance.title')}>
      <div className='pt-4'>
        <p className='mb-4'>
          {t('contact.assistance.phoneNumberLabel')}{' '}
          <span className='text-base font-bold'>{t('contact.assistance.phoneNumber')}</span>
        </p>
        <div className='flex flex-wrap gap-4'>
          <button
            type='button'
            onClick={onCopyNumber}
            className='btn btn-white btn-xs lg:btn-sm btn-fluid sm:btn-solid'
          >
            {t('contact.assistance.copy')}
          </button>
          <a
            className='block w-full sm:w-auto'
            href={`tel:${t('contact.assistance.phoneNumber').replace(/\s/g, '')}`}
          >
            <button
              type='button'
              className='btn btn-primary btn-xs lg:btn-sm btn-fluid sm:btn-solid'
            >
              {t('contact.assistance.call')}
            </button>
          </a>
        </div>
      </div>
    </ModalSlider>
  );
};

const EmailModal = () => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const onCopyEmail = () => {
    navigator.clipboard.writeText(t('contact.email.email'));
  };
  const onCloseModal = () => {
    setOpen(false);
  };
  const onOpenModal = () => {
    setOpen(true);
  };

  return (
    <>
      <button
        type='button'
        onClick={onOpenModal}
        className='btn btn-white btn-xs lg:btn-sm btn-fluid'
      >
        {t('contact.email.cta')}
      </button>
      <ModalSlider onClose={onCloseModal} open={open} title={t('contact.email.title')}>
        <div className='pt-4'>
          <p className='mb-4'>
            {t('contact.email.emailLabel')}{' '}
            <span className='text-base font-bold text-primary-500'>{t('contact.email.email')}</span>
          </p>
          <div className='flex flex-wrap gap-4'>
            <button
              type='button'
              onClick={onCopyEmail}
              className='btn btn-white btn-xs lg:btn-sm btn-fluid sm:btn-solid'
            >
              {t('contact.email.copy')}
            </button>
            <a className='block w-full sm:w-auto' href={`mailto:${t('contact.email.email')}`}>
              <button
                type='button'
                className='btn btn-primary btn-xs lg:btn-sm btn-fluid sm:btn-solid'
              >
                {t('contact.email.send')}
              </button>
            </a>
          </div>
        </div>
      </ModalSlider>
    </>
  );
};

interface ContactCardProps {
  title: string;
  text: string;
  icon: ReactNode;
}
const ContactCard: FC<ContactCardProps> = ({ title, text, icon, children }) => {
  return (
    <div className='p-4 sm:p-8 sm:pb-6 bg-white rounded-xl shadow-sm'>
      <div className='sm:flex md:flex-row gap-4 mb-4 lg:mb-0'>
        <div className='mb-2 sm:mb-4'>
          <span className='inline-block p-3 bg-secondary-50 rounded-full'>{icon}</span>
        </div>
        <div>
          <p className='mb-1 sm:mb-2 text-base font-bold text-gray-900'>{title}</p>
          <p className='lg:mb-4 xl:mb-0 xl:h-20'>{text}</p>
        </div>
      </div>

      {children}
    </div>
  );
};

export default Contact;
