import { ModalSlider } from '@flitter-insurance/nessui';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import IconEdit from '@/assets/svg/IconEdit';
import WhiteCard from '@/components/pages/dashboard/_shared/WhiteCard';

import PageContainer from '../../layouts/PageContainer';
import HeadTitle from '../../utils/HeadTitle';
import PasswordChangeForm from './_profile/PasswordChangeForm';
import ProfileAddressForm from './_profile/ProfileAddressForm';
import ProfileForm from './_profile/ProfileForm';
const Profile = () => {
  const { t } = useTranslation();
  const [openPasswordModal, setOpenPasswordModal] = useState(false);
  const [isAddressEditModeActivated, setIsAddressEditModeActivated] = useState(false);
  const [isResetWanted, setIsResetWanted] = useState(false);

  const onOpenModal = () => {
    setOpenPasswordModal(true);
  };
  const onCloseModal = () => {
    setOpenPasswordModal(false);
  };

  function HandleAddressEdition() {
    setIsResetWanted(!isResetWanted);
    setIsAddressEditModeActivated(!isAddressEditModeActivated);
  }

  return (
    <PageContainer>
      <HeadTitle titleKey='profile.title' />
      <div className='space-y-8 sm:space-y-12'>
        <div>
          <h1>{t('profile.title')}</h1>
          <p className='text-base text-gray-600'>{t('profile.subtitle')}</p>
        </div>

        <WhiteCard>
          <div className='py-6 md:py-8 px-4 sm:px-8'>
            <h2 className='mb-8'>{t('profile.infoHeader')}</h2>
            <ProfileForm />
          </div>
        </WhiteCard>
        <WhiteCard>
          <div className='py-6 md:py-8 px-4 sm:px-8'>
            <div className='flex flex-row justify-between'>
              <h2 className='mb-8'>{t('profile.addressHeader')}</h2>

              {!isAddressEditModeActivated ? (
                <span
                  onClick={HandleAddressEdition}
                  className='inline-block p-2.5 pl-3 w-10 h-10 text-gray-800 rounded-md border border-gray-100 cursor-pointer'
                >
                  <IconEdit />
                </span>
              ) : (
                <button
                  className='btn btn-white btn-xs lg:btn-sm sm:btn-solid'
                  onClick={HandleAddressEdition}
                >
                  {t('profile.profileForm.cancel')}
                </button>
              )}
            </div>
            <ProfileAddressForm
              isAddressEditModeActivated={isAddressEditModeActivated}
              setIsAddressEditModeActivated={setIsAddressEditModeActivated}
            />
          </div>
        </WhiteCard>
        <WhiteCard>
          <div className='flex flex-col sm:flex-row sm:justify-between py-4 md:py-8 px-4 sm:px-8'>
            <h2 className='flex items-center mb-4 lg:mb-0'>{t('profile.passwordHeader')}</h2>
            <div className='flex-shrink-0 lg:ml-6'>
              <button
                onClick={onOpenModal}
                className='btn btn-white btn-xs lg:btn-sm btn-fluid sm:btn-solid'
              >
                {t('profile.passwordCTA')}
              </button>
            </div>
          </div>
          <ModalSlider
            open={openPasswordModal}
            onClose={onCloseModal}
            title={t('profile.passwordCTA')}
          >
            <div className='pt-2 sm:min-h-0 min-h-screen-70'>
              <PasswordChangeForm onSuccess={onCloseModal} />
            </div>
          </ModalSlider>
        </WhiteCard>
      </div>
    </PageContainer>
  );
};

export default Profile;
