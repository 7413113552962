import { useCallback } from 'react';

import { useAuth } from '@/context/Auth';
import { usePublicApiAxios } from '@/context/PublicApiAxios';
import { useSubscriptionState } from '@/context/Subscription';

import { paths } from '../../../generated/publicApi';

const signUpPath = '/api/v1/authentication/signup';
type SignUpRequest = paths['/api/v1/authentication/signup']['post'];
type SignUpBody = SignUpRequest['requestBody']['content']['application/json'];
type SignUpResponse = SignUpRequest['responses']['200']['content']['application/json'];

const useSignUpApi = () => {
  const axios = usePublicApiAxios();
  const [, setAuthState] = useAuth();
  const [{ policy, driver }] = useSubscriptionState();

  return useCallback(
    async (password: string) => {
      // get the customtoken from the public api
      const body: SignUpBody = {
        driver_id: policy ? Number(policy?.driver_id) : Number(driver?.id),
        password,
      };
      const {
        data: { access_token, driver_id },
      } = await axios.post<SignUpResponse>(signUpPath, body);
      //console.log({ access_token });
      if (!access_token) {
        throw new Error('did not get custom token');
      }

      localStorage.removeItem('paymentQuoteId');
      // usi the token to sign in to firebase
      setAuthState((auth) => ({
        ...auth,
        isLogged: true,
        isReady: true,
        token: access_token,
        driver_id,
      }));
    },
    [axios, policy?.driver_id, setAuthState]
  );
};

export default useSignUpApi;
