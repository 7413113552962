const IconHome = ({ width = 16, height = 16 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox={'0 0 16 16'}
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M10.2016 10.6121H5.71094'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M10.6667 14H5.33333C4.44928 14 3.60143 13.6488 2.97631 13.0237C2.35119 12.3986 2 11.5507 2 10.6667V7.46666C2 6.96676 2.11244 6.47327 2.32899 6.02271C2.54555 5.57215 2.86066 5.17607 3.25102 4.86378L5.91769 2.73045C6.50873 2.25761 7.24311 2 8.00002 2C8.75693 2 9.49131 2.25761 10.0824 2.73045L12.749 4.86378C13.1394 5.17607 13.4545 5.57216 13.671 6.02272C13.8876 6.47328 14 6.96677 14 7.46666V10.6667C14 11.5507 13.6488 12.3986 13.0237 13.0237C12.3986 13.6488 11.5507 14 10.6667 14Z'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export default IconHome;
