import { InfoCard } from '@flitter-insurance/nessui';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { useDashboardState } from '@/context/DashBoard';
import routes from '@/routes';

export default function GreenCard() {
  const { t } = useTranslation();
  const [{ policyData: policyDataState }] = useDashboardState();
  const [firstName] = useState(policyDataState?.driver_out.qt_first_name || '');

  const [{ policyData }] = useDashboardState();
  const insuredStatus = policyData?.policy_out.uw_status;

  const documentStatus = policyData?.policy_out.uw_document_status;

  const hereLink = () => {
    return (
      <Link to={routes.DOCUMENTS} target='_blank' className='text-primary-500 underline'>
        {t('greenCard.here')}
      </Link>
    );
  };

  return (
    <>
      {insuredStatus === 'insured_pending' && documentStatus === 'incomplete' && (
        <InfoCard title={t('greenCard.temporaryGreenCardTitle')}>
          {t('greenCard.temporaryGreenCardContent')}
          {hereLink()}
          {t('greenCard.point')}

          <br />
          {t('greenCard.temporaryGreenCardContentBis')}
          <br />
          {t('greenCard.temporaryGreenCardContentTer')}
          <span id='my-intercom-button' className='text-primary-500 underline cursor-pointer'>
            {t('greenCard.contactUs')}
          </span>
          {t('greenCard.temporaryGreenCardContentQua')}
        </InfoCard>
      )}

      {insuredStatus === 'insured_pending' && documentStatus === 'complete' && (
        <InfoCard title={t('greenCard.completedFileTitle')}>
          {t('greenCard.completedFileContent', { firstName: firstName })}
        </InfoCard>
      )}

      {insuredStatus === 'insured_confirmed' && (
        <InfoCard title={t('greenCard.greenCardIncomingTitle')}>
          {t('greenCard.greenCardIncomingContent', { firstName: firstName })}
          <br />
          {t('greenCard.greenCardIncomingContentBis')}
          {hereLink()}
          {t('greenCard.greenCardIncomingContentTer')}
        </InfoCard>
      )}
    </>
  );
}
