import { useMemo } from 'react';

import { Formule, garanties, QuoteGaranties } from '../components/utils/constants';

const useGarantiesFromFormule = (
  formule: Formule
): { enabled: QuoteGaranties[]; disabled: QuoteGaranties[] } => {
  return useMemo(() => {
    const enabled: QuoteGaranties[] = [];
    const disabled: QuoteGaranties[] = [];

    garanties.forEach(({ enabled: enabledFormules, key }) => {
      if (enabledFormules.includes(formule)) {
        enabled.push(key);
      } else {
        disabled.push(key);
      }
    });
    return { enabled, disabled };
  }, [formule]);
};

export default useGarantiesFromFormule;
