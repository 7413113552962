import { useQuery } from 'react-query';

import { useAuth } from '@/context/Auth';
import { useDashboardState } from '@/context/DashBoard';

import { paths } from '../../../../generated/publicApi';
import { usePublicApiAxios } from '../../../context/PublicApiAxios';

const policyDataPath = '/api/v1/dashboard/policy';

export type PolicyDataQuery = paths['/api/v1/dashboard/policy']['get']['parameters']['query'];
export type PolicyDataResult =
  paths['/api/v1/dashboard/policy']['get']['responses']['200']['content']['application/json'];

const usePolicyData = ({ driver_id }: PolicyDataQuery) => {
  const axios = usePublicApiAxios();
  const [, setDashboardState] = useDashboardState();
  const [{ token }] = useAuth();

  const getPolicyData = async () => {
    const { data } = await axios.get<PolicyDataResult>(policyDataPath, {
      params: { driver_id },
      headers: { Authorization: `Bearer ${token}` },
    });

    if (data && data !== null) {
      setDashboardState((prevState) => ({
        ...prevState,
        policyData: data,
      }));
      return data;
    }
  };

  return useQuery(policyDataPath, getPolicyData);
};

export default usePolicyData;
