import { useTranslation } from 'react-i18next';

import { Formule } from '@/components/utils/constants';
import { useAuth } from '@/context/Auth';
import { useDashboardState } from '@/context/DashBoard';
import usePolicyData from '@/hooks/api/dashboard/usePolicyData';
import { toFixed } from '@/services/utils';

import PageContainer from '../../layouts/PageContainer';
import HeadTitle from '../../utils/HeadTitle';
import ContractSummary from './_garanties/ContractSummary';

const Garanties = () => {
  const { t } = useTranslation();
  const [{ policyData }] = useDashboardState();
  const [{ driver_id }] = useAuth();
  //const { data } = usePolicyData({ driver_id: driver_id as number });
  // todo : real values

  const carMake = policyData?.car_out.qt_make;

  const premiumTotalTTC = Number(policyData?.policy_out.qt_flitter_total_prime_ttc);
  const premiumAnnualTTC =
    policyData?.policy_out.qt_paymentfrequency_type === 'mensuel'
      ? premiumTotalTTC / 12
      : premiumTotalTTC;
  const price = Number(toFixed(Number(premiumAnnualTTC), 2));
  const paymentfrequency =
    policyData?.policy_out.qt_paymentfrequency_type === 'mensuel' ? 'mois' : 'an';

  function getExcessBDG(excessType: string) {
    switch (excessType) {
      case 'REDUCED':
        return Object(policyData?.policy_out?.qt_excess_details).BDG.REDUCED.value;
      case 'DEFAULT':
        return Object(policyData?.policy_out?.qt_excess_details).BDG.DEFAULT.value;
    }
  }

  function getExcessDA(excessType: string) {
    switch (excessType) {
      case 'REDUCED':
        return Object(policyData?.policy_out?.qt_excess_details).DA.REDUCED.value;
      case 'DEFAULT':
        return Object(policyData?.policy_out?.qt_excess_details).DA.DEFAULT.value;
      case 'INCREASED':
        return Object(policyData?.policy_out.qt_excess_details).DA.INCREASED.value;
    }
  }

  function getFormule(formule: string): Formule {
    switch (formule) {
      case 'tiers':
        return Formule.TIERS;
      case 'vol_incendie':
        return Formule.VOL_ICENDIE;
      case 'tous_risques':
        return Formule.TOUS_RISQUES;
      default:
        return Formule.TOUS_RISQUES;
    }
  }

  const qtExcessBDGType = policyData?.policy_out?.qt_excessbdg_type;
  const franchiseBdgPrice = getExcessBDG(qtExcessBDGType!);
  const qtExcessDAType = policyData?.policy_out?.qt_excessda_type;
  const franchiseAccidentPrice = getExcessDA(qtExcessDAType!);

  const immatriculation = policyData?.car_out?.qt_license_plate;

  const formule = getFormule(policyData?.policy_out?.qt_formule as string);
  const distance = Number(policyData?.policy_out.qt_km);
  const contractNumber = policyData?.policy_out.uw_policy_id;
  const contractStart = policyData?.policy_out.qt_start_date;

  return (
    <PageContainer>
      <HeadTitle titleKey='garanties.title' />
      <div className='space-y-8 sm:space-y-12'>
        <div>
          <h1>{t('garanties.title')}</h1>
          <p className='text-base text-gray-600'>{t('garanties.subtitle')}</p>
        </div>

        <ContractSummary
          carMake={carMake!}
          price={price!}
          formule={formule}
          distance={distance}
          contractNumber={contractNumber!}
          franchiseAccidentPrice={franchiseAccidentPrice}
          franchiseBdgPrice={franchiseBdgPrice}
          immatriculation={immatriculation!}
          paymentfrequency={paymentfrequency}
          contractStart={contractStart}
        />
      </div>
    </PageContainer>
  );
};

export default Garanties;
