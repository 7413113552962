import { CardNumberElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router';
import { useHistory } from 'react-router';

import { paths } from '@/../generated/publicApi';
import { useSubscriptionState } from '@/context/Subscription';
import useCreatePolicy from '@/hooks/api/useCreatePolicy';
import routes, { trackingUrlFinal, urlParamConcat } from '@/routes';

export type SubscriptionQuery =
  paths['/api/v1/underwriting/payment-stripe/create-subscription']['post']['requestBody']['content']['application/json'];
type SubscriptionResult =
  paths['/api/v1/underwriting/payment-stripe/create-subscription']['post']['responses']['200']['content']['application/json'];

type PaymentData = {
  subscription: SubscriptionResult;
  paymentMethodId: string;
};

const baseURL = import.meta.env.VITE_API_PUBLIC_DOMAIN as string;

const useCheckout = () => {
  const stripe = useStripe();
  const elements = useElements();
  const [subscriptionState] = useSubscriptionState();
  const { mutate: usecreatePolicy } = useCreatePolicy();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { search } = useLocation();
  const params = useMemo(() => new URLSearchParams(search), [search]);
  const { push } = useHistory();

  useEffect(() => {
    localStorage.removeItem('selectedPricingId');
    const paymentPolicyId = localStorage.getItem('paymentPolicyId');
    const paymentQuoteId = localStorage.getItem('paymentQuoteId');
    if (paymentQuoteId && paymentQuoteId == params.get('quote_id'))
      push({
        pathname: routes.HELLOSIGN,
        search: trackingUrlFinal + urlParamConcat + 'policy_id=' + paymentPolicyId,
      });
  }, []);

  // const retryInvoiceWithNewPaymentMethod = ( paymentMethodId: string) => {
  //     return (
  //         fetch('https://stripe-api-rw6z7wg6ka-ew.a.run.app/retry-invoice', {
  //         method: 'POST',
  //         headers: {
  //             'Content-type': 'application/json',
  //         },
  //         body: JSON.stringify({
  //             payment_method_id: paymentMethodId,
  //         }),
  //         })
  //         .then((response) => {
  //             return response.json();
  //         })
  //         // If the card is declined, display an error to the user.
  //         .then((result) => {

  //             if (result.error) {
  //             // The card had an error when trying to attach it to a customer.

  //             throw { error: { message: result.error.message } };
  //             }
  //             return result;
  //         })
  //         // Normalize the result to contain the object returned by Stripe.
  //         // Add the additional details we need.
  //         .then((result) => {
  //             return {
  //             // Use the Stripe 'object' property on the
  //             // returned result to understand what object is returned.
  //             invoice: result,
  //             paymentMethodId: paymentMethodId,
  //             isRetry: true,
  //             };
  //         })
  //         // Some payment methods require a customer to be on session
  //         // to complete the payment process. Check the status of the
  //         // payment intent to handle these actions.
  //         .then(handlePaymentThatRequiresCustomerAction)
  //         // No more actions required. Provision your service for the user.
  //         .then(onSubscriptionComplete)
  //         .catch((error) => {
  //             // An error has happened. Display the failure to the user here.
  //             // We utilize the HTML element we created.
  //            console.log('error', error)
  //         })
  //     );
  //     }
  const onSubmit = async (coupon: string | null) => {
    setIsLoading(true);

    if (!stripe || !elements) {
      return;
    }

    await completePayment();

    function createSubscription(paymentMethodId: string): PaymentData | any {
      const selectedPricingId =
        localStorage.getItem('selectedPricingId') === ''
          ? 0
          : Number(localStorage.getItem('selectedPricingId'));

      return (
        fetch(`${baseURL}/api/v1/underwriting/payment-stripe/create-subscription`, {
          method: 'POST',
          headers: {
            'Content-type': 'application/json',
            Accept: 'application/json',
          },
          body: JSON.stringify({
            qt_quote_uuid: params.get('quote_id'),
            payment_method_id: paymentMethodId,
            pricing_id: selectedPricingId,
            ...(coupon && { coupon_name: coupon }),
          }),
        })
          .then((response) => {
            return response.json();
          })
          // If the card is declined, display an error to the user.
          .then((result) => {
            if (result.error) {
              window.analytics.track('quote_create_subscription', {
                ...subscriptionState,
                qt_quote_uuid: params.get('quote_id'),
                payment_method_id: paymentMethodId,
                pricing_id: selectedPricingId,
                ...(coupon && { coupon_name: coupon }),
                subscription: result.error,
                ts: new Date().toISOString(),
              });
              throw result.error;
            }
            window.analytics.track('quote_create_subscription', {
              ...subscriptionState,
              qt_quote_uuid: params.get('quote_id'),
              payment_method_id: paymentMethodId,
              pricing_id: selectedPricingId,
              ...(coupon && { coupon_name: coupon }),
              subscription: result,
              ts: new Date().toISOString(),
            });
            return result;
          })
          // Normalize the result to contain the object returned by Stripe.
          // Add the additional details we need.
          .then((result) => {
            return {
              paymentMethodId: paymentMethodId,
              subscription: result,
            };
          })
          // Some payment methods require a customer to be on session
          // to complete the payment process. Check the status of the
          // payment intent to handle these actions.
          .then(handlePaymentThatRequiresCustomerAction)
          // If attaching this card to a Customer object succeeds,
          // but attempts to charge the customer fail, you
          // get a requires_payment_method error.
          .then(handleRequiresPaymentMethod)
          // No more actions required. Provision your service for the user.
          .then(onSubscriptionComplete)
          .catch((error) => {
            // An error has happened. Display the failure to the user here.
            // We utilize the HTML element we created.
            // showCardError(error);

            // TODO : detect errors that match coupon invalide
            // if (error === coupon invalide) {
            //   toast.error('Coupon invalide');
            //   return
            // }
            setIsLoading(false);
            throw error;
          })
      );
    }

    function handleRequiresPaymentMethod({ subscription, paymentMethodId }: PaymentData) {
      if (subscription.status === 'active' || subscription.status === 'trialing') {
        // subscription is active, no customer actions required.
        return { subscription, paymentMethodId };
      } else if (
        subscription.stripe_latest_invoice_id &&
        subscription.stripe_latest_invoice_id.payment_intent &&
        subscription.stripe_latest_invoice_id?.payment_intent?.status === 'requires_payment_method'
      ) {
        // Using localStorage to manage the state of the retry here,
        // feel free to replace with what you prefer.
        // Store the latest invoice ID and status.
        const invoice_id = subscription?.stripe_latest_invoice_id?.payment_intent?.id as string;
        const latestInvoicePaymentIntentStatus = subscription?.stripe_latest_invoice_id
          ?.payment_intent?.status as string;
        localStorage.setItem('latestInvoiceId', invoice_id);
        localStorage.setItem('latestInvoicePaymentIntentStatus', latestInvoicePaymentIntentStatus);
        throw { error: { message: 'Votre carte nécessite une deuxième authentification' } };
      } else {
        return {
          subscription: subscription,
          paymentMethodId: paymentMethodId,
        };
      }
    }

    function onSubscriptionComplete(result: PaymentData) {
      // Payment was successful.
      if (
        result.subscription &&
        (result.subscription.status === 'active' || result.subscription.status === 'trialing')
      ) {
        localStorage.removeItem('latestInvoiceId');
        localStorage.removeItem('latestInvoicePaymentIntentStatus');

        console.log('subscription complete');
        usecreatePolicy({ quote_uuid: params.get('quote_id') as string });
        //createPolicy();

        //setIsLoading(false);
        //push(routes.SIGNUP);
        // Change your UI to show a success message to your customer.
        // Call your backend to grant access to your service based on
        // `result.subscription.items.data[0].price.product` the customer subscribed to.
      }
    }

    function handlePaymentThatRequiresCustomerAction({
      subscription,
      paymentMethodId,
    }: //isRetry
    {
      subscription: SubscriptionResult;
      paymentMethodId: string;
      //isRetry: boolean;
    }): PaymentData | any {
      if (!subscription.status) throw subscription;

      if (
        subscription &&
        (subscription.status === 'active' || subscription.status === 'trialing')
      ) {
        // Subscription is active, no customer actions required.
        return { subscription, paymentMethodId };
      }

      // If it's a first payment attempt, the payment intent is on the subscription latest invoice.
      // If it's a retry, the payment intent will be on the invoice itself.
      const paymentIntent = subscription.stripe_latest_invoice_id?.payment_intent as {
        status: string;
        client_secret: string;
      };
      const subscription_id = subscription.id;
      if (
        paymentIntent.status === 'requires_action' ||
        paymentIntent.status === 'requires_confirmation' ||
        paymentIntent.status === 'requires_payment_method'
        //(isRetry === true && paymentIntent.status === 'requires_payment_method')
      ) {
        window.analytics.track('confirm_card_payment', {
          qt_quote_uuid: params.get('quote_id'),
          subscription_id: subscription.id,
          ts: new Date().toISOString(),
        });
        return stripe!
          .confirmCardPayment(paymentIntent.client_secret, {
            payment_method: paymentMethodId,
            setup_future_usage: 'off_session',
          })
          .then((result) => {
            if (result.error) {
              // Start code flow to handle updating the payment details.
              // Display error message in your UI.
              // The card was declined (i.e. insufficient funds, card has expired, etc).
              window.analytics.track('card_error', {
                qt_quote_uuid: params.get('quote_id'),
                subscription_id: subscription.id,
                error: result.error,
                ts: new Date().toISOString(),
              });
              throw result.error;
            } else {
              if (result.paymentIntent.status === 'succeeded') {
                // Show a success message to your customer.
                window.analytics.track('payment_success', {
                  qt_quote_uuid: params.get('quote_id'),
                  subscription_id: subscription.id,
                  payment_intent: result.paymentIntent,
                  ts: new Date().toISOString(),
                });
                return fetch(
                  `${baseURL}/api/v1/underwriting/payment-stripe/retrieve-subscription?subscription_id=${subscription_id}`,
                  {
                    method: 'GET',
                    headers: {
                      'Content-Type': 'application/json',
                    },
                  }
                )
                  .then((response) => {
                    return response.json();
                  })
                  .then((result) => {
                    if (result.error) {
                      // The card had an error when trying to attach it to a customer.
                      throw result.error;
                    }
                    return {
                      subscription: result,
                      paymentMethodId: paymentMethodId,
                    };
                  })
                  .catch((error) => {
                    throw error;
                  });
              }
            }
          })
          .catch((err) => {
            throw err;
          });
      } else {
        // No customer action needed.
        return { subscription, paymentMethodId };
      }
    }

    /*
function retryInvoiceWithNewPaymentMethod({
  customerId,
  paymentMethodId,
  invoiceId,
  price_id_one_shot,
  price_id_recurring
  }) {
  return (
      fetch('https://stripe-api-rw6z7wg6ka-ew.a.run.app/retry-invoice', {
      method: 'POST',
      headers: {
          'Content-type': 'application/json',
      },
      body: JSON.stringify({
          customer_id: customerId,
          payment_method_id: paymentMethodId,
          invoice_id: invoiceId,
      }),
      })
      .then((response) => {
          return response.json();
      })
      // If the card is declined, display an error to the user.
      .then((result) => {
          if (result.error) {
          // The card had an error when trying to attach it to a customer.
          throw { error: { message: result.error.message } };
          }
          return result;
      })
      // Normalize the result to contain the object returned by Stripe.
      // Add the additional details we need.
      .then((result) => {
          return {
          // Use the Stripe 'object' property on the
          // returned result to understand what object is returned.
          invoice: result,
          paymentMethodId: paymentMethodId,
          price_id_one_shot,
          price_id_recurring,
          isRetry: true,
          };
      })
      // Some payment methods require a customer to be on session
      // to complete the payment process. Check the status of the
      // payment intent to handle these actions.
      .then(handlePaymentThatRequiresCustomerAction)
      // No more actions required. Provision your service for the user.
      .then(onSubscriptionComplete)
      .catch((error) => {
          // An error has happened. Display the failure to the user here.
          // We utilize the HTML element we created.
          setErrorModal({
              show: true,
              message: error.error.message
          })
          setLoading(false)
          addDataUser({lead_status: 'payment_failed'}, customerId.split('_')[1], 'payment data added', 'users')
      })
  );
  }
*/

    function completePayment() {
      window.analytics.track('start_payment', {
        qt_quote_uuid: params.get('quote_id'),
        ts: new Date().toISOString(),
      });
      return stripe!
        .createPaymentMethod({
          type: 'card',
          card: elements!.getElement(CardNumberElement)!,
        })
        .then((result) => {
          if (result.error) {
            throw result.error;
          }

          if (localStorage.getItem('latestInvoiceId')) {
            /*
                  retryInvoiceWithNewPaymentMethod({
                      customerId: Object.values(user)[0].customerId,
                      paymentMethodId: result.paymentMethod.id,
                      invoiceId: localStorage.getItem('latestInvoiceId')
                  })
                  */
            window.analytics.track('latestInvoice', {
              qt_quote_uuid: params.get('quote_id'),
              ts: new Date().toISOString(),
            });
          } else {
            console.log(result.paymentMethod.id);
            return createSubscription(result.paymentMethod.id);
          }
        })
        .catch((error) => {
          setIsLoading(false);
          throw error;
          //addDataUser({lead_status: 'payment_failed'}, Object.values(user)[0].customerId.split('_')[1], 'payment data added', 'users')
        });
    }
  };

  return { onSubmit, isLoading };
};

export default useCheckout;
