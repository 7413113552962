import usePaymentMethodData from '@hooks/api/dashboard/usePaymentMethods';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useDashboardState } from '@/context/DashBoard';
import IsRibAdded from '@/services/isRibAdded';

import PageContainer from '../../layouts/PageContainer';
import HeadTitle from '../../utils/HeadTitle';
import PaymentMethods from './_payment/PaymentMethods';
import RibPanel from './_payment/RibPanel';

const Payments = () => {
  const [displayRibPanel] = useState(!IsRibAdded());
  const { t } = useTranslation();
  const [{ policyData }] = useDashboardState();
  if (!policyData) window.location.href = import.meta.env.VITE_HARMONIE_APP_DOMAIN as string;

  const { data: allPaymentMethods, isLoading } = usePaymentMethodData({
    policy_id: Number(policyData?.policy_out.id),
  });

  return (
    <>
      {!isLoading && (
        <PageContainer>
          <HeadTitle titleKey='payments.title' />
          <div className='space-y-8 sm:space-y-12'>
            <div>
              <h1>{t('payments.title')}</h1>
              <div className='text-base text-gray-600'>{t('payments.subtitle')}</div>
            </div>
            {displayRibPanel && <RibPanel />}
            <PaymentMethods />
          </div>
        </PageContainer>
      )}
    </>
  );
};

export default Payments;
