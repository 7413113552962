import '../../../../assets/css/paymentSummary.css';

import { ArrowDown } from '@flitter-insurance/nessui';
import dayjs from 'dayjs';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useSubscriptionState } from '@/context/Subscription';

interface PaymentSummaryProps {
  franchiseBDG: number;
  franchiseDA: number;
  quote_id: string | null;
}

const PaymentSummary = ({ franchiseBDG, franchiseDA, quote_id }: PaymentSummaryProps) => {
  const { t } = useTranslation();
  const [{ quote_pricing }] = useSubscriptionState();
  const [displaySummary, setDisplaySummary] = useState(false);
  const linkURL = import.meta.env.VITE_LINK_APP_DOMAIN as string;

  function getFormule(formule: string): string {
    switch (formule) {
      case 'tiers':
        return 'Tiers';
      case 'vol_incendie':
        return 'Confort';
      case 'tous_risques':
        return 'Tous risques';
      default:
        return 'Tous risques';
    }
  }

  const formule = getFormule(quote_pricing?.pricing_out?.monthly.qt_formule as string);

  function handleDisplaySummary() {
    setDisplaySummary(!displaySummary);
  }

  return (
    <div className='p-5 lg:py-8 lg:px-8 mr-2 lg:mr-0 ml-2 lg:ml-0 rounded-lg bg-amber-300'>
      <div className='grid grid-cols-8 lg:grid-cols-1'>
        <div className='col-span-7 rotate-45'>
          <h2>{t('paymentSummary.title')}</h2>
        </div>
        <div className='lg:hidden text-right cursor-pointer svg-inline'>
          <ArrowDown
            className={displaySummary ? 'rotate-180 transform-gpu' : ''}
            width={24}
            height={24}
            onClick={handleDisplaySummary}
          />
        </div>
      </div>
      <div className={displaySummary ? 'block' : 'hidden lg:block'}>
        <div className='grid grid-cols-2 mt-4'>
          <p className='text-lg font-medium text-gray-900'>{t('paymentSummary.insured')}</p>
          <a href={`${linkURL}/quote?s=carMake`} className='text-right text-gray-500 underline'>
            {t('paymentSummary.edit')}
          </a>
        </div>
        <hr className='mt-3' />
        <div className='grid grid-cols-2 mt-5'>
          <p className='text-sm text-gray-500'>{t('paymentSummary.fullName')}</p>
          <p className='text-sm text-right lg:text-left text-flitblue-dark'>
            {quote_pricing?.quote_out.qt_first_name} {quote_pricing?.quote_out.qt_last_name}
          </p>
        </div>
        <div className='grid grid-cols-2 mt-1'>
          <p className='text-sm text-gray-500'>{t('paymentSummary.vehicule')}</p>
          <p className='text-sm text-right lg:text-left text-flitblue-dark'>
            {quote_pricing?.quote_out.qt_license_plate}
          </p>
        </div>
        <div className='grid grid-cols-2 mt-5'>
          <p className='text-lg font-medium text-gray-900'>{t('paymentSummary.coverage')}</p>
          <a
            href={`${linkURL}/quote/version?quote_id=${quote_id}`}
            className='text-right text-gray-400 underline'
          >
            {t('paymentSummary.edit')}
          </a>
        </div>
        <hr className='mt-3' />
        <div className='grid grid-cols-3 lg:grid-cols-2 mt-5'>
          <p className='col-span-2 lg:col-span-1 text-sm text-gray-500'>
            {t('paymentSummary.startContract')}
          </p>
          <p className='text-sm text-right lg:text-left text-flitblue-dark'>
            {dayjs(quote_pricing?.quote_out.qt_start_date).format('DD-MM-YYYY')}
          </p>
        </div>
        <div className='grid grid-cols-2 mt-1'>
          <p className='text-sm text-gray-500'>{t('paymentSummary.formula')}</p>
          <p className='text-sm text-right lg:text-left text-flitblue-dark'>{formule}</p>
        </div>
        <div className='grid grid-cols-3 lg:grid-cols-2 mt-1'>
          <p className='col-span-2 lg:col-span-1 text-sm text-gray-500'>
            {t('paymentSummary.formulaKm')}
          </p>
          <p className='text-sm text-right lg:text-left text-flitblue-dark'>
            {quote_pricing?.quote_out.qt_km} km
          </p>
        </div>

        {(formule.toLocaleLowerCase() === 'confort' ||
          formule.toLocaleLowerCase() === 'tous risques') && (
          <>
            <div className='grid grid-cols-2 mt-5'>
              <p className='text-lg font-medium text-gray-900'>{t('paymentSummary.franchise')}</p>
              <a
                href={`${linkURL}/quote/franchise?quote_id=${quote_id}`}
                className='text-right text-gray-400 underline'
              >
                {t('paymentSummary.edit')}
              </a>
            </div>
            <hr className='mt-3' />
            <div className='grid grid-cols-3 lg:grid-cols-2 mt-5'>
              <p className='col-span-2 lg:col-span-1 text-sm text-gray-500'>
                {t('paymentSummary.franchiseBrokenGlace')}
              </p>
              <p className='text-sm text-right lg:text-left text-flitblue-dark'>{franchiseBDG}€</p>
            </div>
            {formule.toLocaleLowerCase() === 'tous risques' && (
              <div className='grid grid-cols-3 lg:grid-cols-2 mt-1'>
                <p className='col-span-2 lg:col-span-1 text-sm text-gray-500'>
                  {t('paymentSummary.franchiseAccident')}
                </p>
                <p className='text-sm text-right lg:text-left text-flitblue-dark'>{franchiseDA}€</p>
              </div>
            )}
            {formule.toLocaleLowerCase() === 'confort' && (
              <div className='grid grid-cols-3 lg:grid-cols-2 mt-1'>
                <p className='col-span-2 lg:col-span-1 text-sm text-gray-500'>
                  {t('paymentSummary.franchiseVolIncendie')}
                </p>
                <p className='text-sm text-right lg:text-left text-flitblue-dark'>{franchiseDA}€</p>
              </div>
            )}
          </>
        )}
        <div className='grid grid-cols-2 mt-5'>
          <p className='text-lg font-medium text-gray-900'>{t('paymentSummary.options')}</p>
          <a
            href={`${linkURL}/quote/options?quote_id=${quote_id}`}
            className='text-right text-gray-400 underline'
          >
            {t('paymentSummary.edit')}
          </a>
        </div>
        <hr className='mt-3' />
        <div className='grid grid-cols-2 mt-5'>
          <p className='text-sm text-gray-500'>{t('paymentSummary.assistance0Km')}</p>
          <p className='text-sm text-right lg:text-left text-flitblue-dark'>
            {t('paymentSummary.included')}
          </p>
        </div>

        <div className='grid sm:grid-cols-2 mt-1'>
          <p className='text-sm text-gray-500'>{t('paymentSummary.secondaryDriver')}</p>
          <p className='text-sm text-right lg:text-left text-flitblue-dark'>
            {quote_pricing?.quote_out.qt_is_seconddriver
              ? quote_pricing?.quote_out.qt_firstname_seconddriver +
                ' ' +
                quote_pricing?.quote_out.qt_lastname_seconddriver
              : t('paymentSummary.notIncluded')}
          </p>
        </div>

        <div className='grid grid-cols-3 lg:grid-cols-2 mt-1'>
          <p className='col-span-2 lg:col-span-1 text-sm text-gray-500'>
            {t('paymentSummary.vehiculeReplacement')}
          </p>
          <p className='text-sm text-right lg:text-left text-flitblue-dark'>
            {quote_pricing?.quote_out.qt_is_vehiclerep
              ? t('paymentSummary.included')
              : t('paymentSummary.notIncluded')}
          </p>
        </div>
        {/* 
        <div className='grid sm:grid-cols-2 mt-1'>
          <p className='text-gray-500'>{t('paymentSummary.protection')}</p>
          <p className='text-flitblue-dark'>
            {quote_pricing?.quote_out.qt_is_pj
              ? t('paymentSummary.included')
              : t('paymentSummary.notIncluded')}
          </p>
        </div>
        */}
      </div>
    </div>
  );
};

export default PaymentSummary;
