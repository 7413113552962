import { Route, Switch } from 'react-router';

import routes from '../../routes';
import Picture from '../pages/dashboard/_documents/Picture';
import HelloSign from '../pages/helloSign/HelloSign';
import ResetPassword from '../pages/login/ResetPassword';
import ResetPasswordInvite from '../pages/login/ResetPasswordInvite';
import SignIn from '../pages/login/SignIn';
import SignUp from '../pages/login/SignUp';
import Subscribe from '../pages/subscribe/Subscribe';
import PrivatePages from './PrivatePages';

const Router = () => {
  return (
    <>
      <Switch>
        <Route path={routes.SIGNIN} component={SignIn} />
        <Route path={routes.RESETPASSWORDINVITE} component={ResetPasswordInvite} />
        <Route path={routes.RESETPASSWORD} component={ResetPassword} />
        <Route path={routes.SIGNUP} component={SignUp} />
        <Route path={routes.SUBSCRIBE} component={Subscribe} />
        <Route path={routes.PICTURE} component={Picture} />
        <Route path={routes.HELLOSIGN} component={HelloSign} />

        <PrivatePages />
        <Route path='*' component={SignIn} />
      </Switch>
    </>
  );
};

export default Router;
