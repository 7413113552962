import { Lock, LottieAnimation, PasswordField } from '@flitter-insurance/nessui';
import { ChangeEventHandler, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  LowerCaseRegex,
  NumericRegex,
  SpecialCharRegex,
  UpperCaseRegex,
} from '@/components/utils/constants';

import PasswordCheck from './PasswordCheck';

interface UsePwdValidationProps {
  pwd: string;
  setPwd: (arg0: string) => void;
  confirmPwd: string;
  setConfirmPwd: (arg0: string) => void;
  onSubmit: () => void;
}

const usePwdValidation = ({
  pwd,
  setPwd,
  confirmPwd,
  setConfirmPwd,
  onSubmit,
}: UsePwdValidationProps) => {
  const [newPwdError, setNewPwdError] = useState('');
  const [confirmPwdError, setConfirmPwdError] = useState('');

  const { t } = useTranslation();

  const onChangePwd: ChangeEventHandler<HTMLInputElement> = (e) => {
    setPwd(e.currentTarget.value);
  };
  const onChangeConfirmPwd: ChangeEventHandler<HTMLInputElement> = (e) => {
    setConfirmPwd(e.currentTarget.value);
  };

  const validatePasswordForm = () => {
    /** validate new pwd */
    const _newPwdErrorPrefix = 'Le mot de passe doit contenir au moins';
    let _newPwdError = '';

    if (pwd.length < 8) _newPwdError += ' 8 caractères,';
    if (!LowerCaseRegex.test(pwd)) _newPwdError += ' 1 caractère minuscule,';
    if (!UpperCaseRegex.test(pwd)) _newPwdError += ' 1 caractère majuscule,';
    if (!SpecialCharRegex.test(pwd)) _newPwdError += ' 1 caractère spécial,';
    if (!NumericRegex.test(pwd)) _newPwdError += ' 1 chiffre.';

    _newPwdError.length > 0
      ? setNewPwdError((_newPwdErrorPrefix + _newPwdError).replace(/.$/, '.'))
      : setNewPwdError(_newPwdError);

    /** validate confirm pwd */
    let _newConfirmPwdError = '';
    if (_newPwdError.length === 0) {
      if (confirmPwd != pwd) _newConfirmPwdError = t('profile.passwordForm.error.mismatch');
      setConfirmPwdError(_newConfirmPwdError);
    }
    if (_newPwdError.length === 0 && _newConfirmPwdError.length === 0) onSubmit();
  };

  return {
    newPwdError,
    onChangePwd,
    confirmPwdError,
    onChangeConfirmPwd,
    validatePasswordForm,
  };
};

interface PasswordFieldsProps {
  displayHelper?: boolean;
  pwd: string;
  setPwd: (arg0: string) => void;
  isLoading: boolean;
  confirmPwd: string;
  setConfirmPwd: (arg0: string) => void;
  isEditProfileLayout?: boolean;
  onSubmit: () => void;
}

const PasswordFields = ({
  displayHelper,
  pwd,
  setPwd,
  confirmPwd,
  setConfirmPwd,
  isLoading,
  isEditProfileLayout = false,
  onSubmit,
}: PasswordFieldsProps) => {
  const { t } = useTranslation();
  const { newPwdError, onChangePwd, confirmPwdError, onChangeConfirmPwd, validatePasswordForm } =
    usePwdValidation({ pwd, setPwd, confirmPwd, setConfirmPwd, onSubmit });

  return (
    <>
      <PasswordField
        value={pwd}
        onChange={onChangePwd}
        error={newPwdError}
        leftIcon={!isEditProfileLayout && <Lock size={24} />}
        placeholder={
          isEditProfileLayout ? t('profile.passwordForm.newPwdLabel') : t('signUp.passwordLabel')
        }
        label={
          isEditProfileLayout ? t('profile.passwordForm.newPwdLabel') : t('signUp.passwordLabel')
        }
      />
      <div className='mt-4'>
        <PasswordField
          value={confirmPwd}
          onChange={onChangeConfirmPwd}
          error={confirmPwdError}
          leftIcon={!isEditProfileLayout && <Lock size={24} />}
          placeholder={
            isEditProfileLayout
              ? t('profile.passwordForm.confirmPwdLabel')
              : t('signUp.confirmLabel')
          }
          label={
            isEditProfileLayout
              ? t('profile.passwordForm.confirmPwdLabel')
              : t('signUp.confirmLabel')
          }
        />
      </div>
      <div className='mt-4'>
        {displayHelper && (
          <span className='block mb-3 font-normal text-gray-600 text-md'>
            {t('resetPwd.pwdHelper')}
          </span>
        )}
        <PasswordCheck password={pwd} />
      </div>
      <div
        className={'flex  ' + (isEditProfileLayout ? 'justify-end mt-10' : 'justify-start mt-8')}
      >
        <button
          className='w-full md:w-max btn btn-xs lg:btn-sm btn-primary'
          type='button'
          onClick={validatePasswordForm}
        >
          {isLoading ? (
            <LottieAnimation type='white-dots' />
          ) : isEditProfileLayout ? (
            t('profile.passwordForm.confirmBtn')
          ) : (
            t('confirm')
          )}
        </button>
      </div>
    </>
  );
};

export default PasswordFields;
