const IconPaperClip = ({ width = 16, height = 16 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox={'0 0 16 16'}
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M6.84098 8.56853L10.2836 5.12596C10.4773 4.93221 10.7073 4.77852 10.9605 4.67366C11.2136 4.5688 11.4849 4.51483 11.7589 4.51483C12.0329 4.51483 12.3043 4.5688 12.5574 4.67366C12.8106 4.77852 13.0406 4.93221 13.2343 5.12596C13.4281 5.31971 13.5818 5.54973 13.6866 5.80287C13.7915 6.05602 13.8455 6.32734 13.8455 6.60135C13.8455 6.87535 13.7915 7.14668 13.6866 7.39982C13.5818 7.65297 13.4281 7.88299 13.2343 8.07674L9.79176 11.5193C9.00916 12.3019 7.94774 12.7416 6.84099 12.7416C5.73423 12.7416 4.67281 12.3019 3.89022 11.5193V11.5193C3.10762 10.7367 2.66797 9.67529 2.66797 8.56853C2.66797 7.46178 3.10762 6.40035 3.89022 5.61776L6.84136 2.66669'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export default IconPaperClip;
