import { useMutation } from 'react-query';

import { paths } from '@/../generated/publicApi';
import { useDashboardState } from '@/context/DashBoard';

import { usePublicApiAxios } from '../../../context/PublicApiAxios';

const sendSmsPath = '/api/v1/dashboard/send_sms';
export type SendSmsQuery = paths['/api/v1/dashboard/send_sms']['post']['parameters']['query'];

export type SendSmsResult =
  paths['/api/v1/dashboard/send_sms']['post']['responses']['200']['content']['application/json'];

const useSendSms = () => {
  const axios = usePublicApiAxios();
  const [{ policyData }] = useDashboardState();

  const createSendSms = async (params: SendSmsQuery) => {
    const { data } = await axios.post<SendSmsResult>(sendSmsPath, {}, { params });
    return data;
  };

  return useMutation(sendSmsPath, createSendSms, {
    onSuccess: (data) => {
      console.log('Succes sms', data);
    },
    onError: (error) => {
      console.log(error);
    },
  });
};

export default useSendSms;
