import { ModalSlider } from '@flitter-insurance/nessui';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import IconRIB from '@/assets/svg/IconRIB';

import AddRibForm from './RibForm';

const RibPanel = () => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const onCloseModal = () => setOpen(false);
  const onOpenModal = () => setOpen(true);
  const [displayRib, setDisplayRib] = useState(true);

  return displayRib ? (
    <>
      <div className='flex flex-col sm:flex-row lg:items-center p-4 lg:py-6 sm:px-8 mb-10 bg-yellow-100 rounded-2xl'>
        <div className='sm:flex-grow mb-4 lg:mb-0'>
          <p className='mb-2 text-sm font-bold text-gray-900 svg-inline'>
            <IconRIB /> {t('ribPanel.header')}
          </p>
          <p className='ml-7 text-sm text-gray-600'>{t('ribPanel.description')}</p>
        </div>
        <button
          onClick={onOpenModal}
          className='sm:ml-8 whitespace-nowrap btn btn-primary btn-xs lg:btn-sm'
        >
          {t('ribPanel.cta')}
        </button>
      </div>
      <ModalSlider open={open} onClose={onCloseModal} title={t('ribPanel.modalTitle')}>
        <div className='pt-4 sm:min-h-0 min-h-screen-70'>
          <AddRibForm onClose={onCloseModal} setDisplayRib={setDisplayRib} />
        </div>
      </ModalSlider>
    </>
  ) : (
    <></>
  );
};

export default RibPanel;
