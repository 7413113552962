import { useTranslation } from 'react-i18next';

interface LinearProgressProps {
  /**
   * value between 0 and 100
   */
  progress: number;
  active: boolean;
}

export const LinearProgress = ({ progress, active }: LinearProgressProps) => {
  return (
    <div className='relative h-1'>
      <div className='absolute inset-0 bg-gray-800 rounded-full opacity-20' />
      {active && (
        <div
          className='absolute inset-y-0 bg-gray-800 rounded-full'
          style={{ width: `${progress}%`, minWidth: '4px' }}
        />
      )}
    </div>
  );
};

interface ProgressProps {
  from: number;
  to: number;
}
export const Progress = ({ from, to }: ProgressProps) => {
  const { t } = useTranslation();
  return (
    <div className='flex sm:justify-end items-center'>
      <div className='w-16'>
        <LinearProgress active progress={(from * 100) / to} />
      </div>
      <div className='ml-4 w-16'>
        <div className='text-sm font-semibold text-gray-800'>{t('progress', { from, to })}</div>
      </div>
    </div>
  );
};

export default Progress;
