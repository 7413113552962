import './components/utils/i18n';
import './assets/css/globals.css';
import './assets/css/nessui.override.css';

/**
 * required for react-collapse to work
 */

declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    global: any;
  }
}
window.global = window;

import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';

import App from './App';

Sentry.init({
  dsn: 'https://7cf66a30efcd42e3a807538f818000f4@o1116513.ingest.sentry.io/6204644',
  integrations: [new BrowserTracing()],
  environment: import.meta.env.VITE_ENV as string,

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

ReactDOM.render(
  <React.StrictMode>
    <Suspense fallback=''>
      <App />
    </Suspense>
  </React.StrictMode>,
  document.getElementById('root')
);
