const IconRibFlatExpired = ({ width = 24, height = 24 }) => {
  return (
    <svg width='50' height='50' viewBox='0 0 50 50' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <rect width='50' height='50' rx='25' fill='#F6F6F6' />
      <path
        d='M18.8125 34V24.5537'
        stroke='#A9A9AF'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M25 34V24.5537'
        stroke='#A9A9AF'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M31.1875 34V24.5537'
        stroke='#A9A9AF'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M16.75 34H33.25'
        stroke='#A9A9AF'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M16.75 24.5538H33.25V20.8763L25 17.5L16.75 20.8763V24.5538Z'
        stroke='#A9A9AF'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export default IconRibFlatExpired;
