import { useQuery } from 'react-query';

import { paths } from '@/../generated/publicApi';
import { useDashboardState } from '@/context/DashBoard';

import { usePublicApiAxios } from '../../../context/PublicApiAxios';

const courrierStatusPath = '/api/v1/backoffice/courrier/status';

export type CourrierStatusDataQuery =
  paths['/api/v1/backoffice/courrier/status/{policy_id}']['get']['parameters'];
export type CourrierStatusDataResult =
  paths['/api/v1/backoffice/courrier/status/{policy_id}']['get']['responses']['200']['content']['application/json'];

const useCourrierStatus = ({ policy_id }: any) => {
  const axios = usePublicApiAxios();
  const [, setDashboardState] = useDashboardState();

  const getCourrierStatus = async () => {
    if (policy_id) {
      const { data } = await axios.get<CourrierStatusDataResult>(
        courrierStatusPath + `/${policy_id}`
      );
      setDashboardState((prevState) => ({
        ...prevState,
        courrierData: data,
      }));
      return data;
    }
  };

  return useQuery(courrierStatusPath, getCourrierStatus);
};

export default useCourrierStatus;
