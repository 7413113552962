import {
  ActionListItem,
  Camera as CameraIcon,
  Check,
  LottieAnimation,
  ModalSlider,
  PaperClip,
} from '@flitter-insurance/nessui';
import { useState } from 'react';
import Camera, { FACING_MODES } from 'react-html5-camera-photo';
import { useTranslation } from 'react-i18next';

import IconEdit from '@/assets/svg/IconEdit';
import IconPaperClip from '@/assets/svg/IconPaperClip';
import { AntecedentType } from '@/components/utils/constants';
import { useAuth } from '@/context/Auth';
import { useDashboardState } from '@/context/DashBoard';
import useDocumentData from '@/hooks/api/dashboard/useDocument';
import useSendSms from '@/hooks/api/dashboard/useSendSms';
import useUploadFile, { UploadFileQuery } from '@/hooks/api/dashboard/useUploadFile';
import useCaptureSupported from '@/hooks/useCaptureSupported';

import HeaderWithProgress from '../_home/HeaderWithProgress';

type ModalMode = UploadFileQuery['file_name'] | null;

enum DocumentStatus {
  NOT_RECEIVED = 'not_received',
  RECEIVED = 'received',
  INVALID = 'invalid',
  VALID = 'valid',
}

interface DocumentsUploadProps {
  permisRectoFileName: string | null;
  permisVersoFileName: string | null;
  CGFileName: string | null;
  hasInfoFileName: string | null;
  permisSecondDriverRectoFileName: string | null;
  permisSecondDriverVersoFileName: string | null;
  odometerPhotoFileName: string | null;
  onDeletePermisRecto: () => void;
  onDeletePermisVerso: () => void;
  onDeleteCG: () => void;
  onDeleteOdometer: () => void;
  onDeleteInfo: () => void;
  onDeletePermisSecondDriverRecto: () => void;
  onDeletePermisSecondDriverVerso: () => void;
}

const useDocumentsUpload = ({
  CGFileName,
  permisRectoFileName,
  permisVersoFileName,
  hasInfoFileName,
  odometerPhotoFileName,
  permisSecondDriverRectoFileName,
  permisSecondDriverVersoFileName,
}: DocumentsUploadProps) => {
  const [modalMode, setModalMode] = useState<ModalMode>(null);
  const cameraSupport = useCaptureSupported();
  let nbItemsDone = 0;
  if (permisRectoFileName !== null) nbItemsDone++;

  if (permisVersoFileName !== null) nbItemsDone++;

  if (CGFileName !== null) nbItemsDone++;

  if (hasInfoFileName) nbItemsDone++;

  if (odometerPhotoFileName !== null) nbItemsDone++;

  if (permisSecondDriverRectoFileName !== null) nbItemsDone++;

  if (permisSecondDriverVersoFileName !== null) nbItemsDone++;

  return {
    nbItemsDone,
    modalMode,
    setModalMode,
    showCamera: cameraSupport,
  };
};

const DocumentsUpload = (props: DocumentsUploadProps) => {
  const { t } = useTranslation();
  const { modalMode, setModalMode, showCamera } = useDocumentsUpload(props);
  const { mutate: uploadFile, isLoading } = useUploadFile();
  const [, setDocumentUploaded] = useState<ModalMode>(null);
  const [open, setOpen] = useState(false);
  const [isEditCompletedFile, setIsEditCompletedFile] = useState(false);
  const onOpenModal = () => {
    setOpen(true);
  };
  const onCloseModal = () => {
    setOpen(false);
  };

  const {
    permisVersoFileName,
    permisRectoFileName,
    CGFileName,
    hasInfoFileName,
    odometerPhotoFileName,
    onDeletePermisRecto,
    onDeletePermisVerso,
    onDeleteCG,
    onDeleteOdometer,
    onDeleteInfo,
    permisSecondDriverRectoFileName,
    permisSecondDriverVersoFileName,
    onDeletePermisSecondDriverRecto,
    onDeletePermisSecondDriverVerso,
  } = props;
  const [
    {
      permisRecto,
      permisVerso,
      cartegrise,
      releveInformation,
      permisSecondDriverRecto,
      permisSecondDriverVerso,
      policyData,
      documentData,
    },
  ] = useDashboardState();
  const isInsured = policyData?.driver_out.qt_antecedent_type === AntecedentType.WITH_ANTECEDENT;
  const isSecondDriver = policyData?.policy_out.qt_is_seconddriver;

  const permisRectoBackendStatus: DocumentStatus | any =
    documentData?.driving_license_recto_out?.status;
  const permisVersoBackendStatus: DocumentStatus | any =
    documentData?.driving_license_verso_out?.status;
  const carteGriseBackendStatus: DocumentStatus | any = documentData?.carte_grise_out?.status;
  const releveInformationBackendStatus: DocumentStatus | any =
    documentData?.releve_information_out?.status;
  //TO DO: ADD ODOMETER STATUS
  const permisSecondDriverRectoBackendStatus: DocumentStatus | any =
    documentData?.driving_license_recto_second_out?.status;
  const permisSecondDriverVersoBackendStatus: DocumentStatus | any =
    documentData?.driving_license_verso_second_out?.status;

  const status: Array<string> = [
    permisRectoBackendStatus,
    permisVersoBackendStatus,
    carteGriseBackendStatus,
  ];

  if (isSecondDriver) {
    status.push(permisSecondDriverRectoBackendStatus);
    status.push(permisSecondDriverVersoBackendStatus);
  }

  const localStatus: Array<any> = [
    permisRecto?.uploaded &&
      (permisRectoBackendStatus === 'received' || permisRectoBackendStatus === 'valid'),
    permisVerso?.uploaded &&
      (permisVersoBackendStatus === 'received' || permisVersoBackendStatus === 'valid'),
    cartegrise?.uploaded &&
      (carteGriseBackendStatus === 'received' || carteGriseBackendStatus === 'valid'),
    releveInformation?.uploaded &&
      (releveInformationBackendStatus === 'received' || releveInformationBackendStatus === 'valid'),
    permisSecondDriverRecto?.uploaded &&
      (permisSecondDriverRectoBackendStatus === 'received' ||
        permisSecondDriverRectoBackendStatus === 'valid'),
    permisSecondDriverVerso?.uploaded &&
      (permisSecondDriverVersoBackendStatus === 'received' ||
        permisSecondDriverVersoBackendStatus === 'valid'),
  ];

  if (isInsured) status.push(releveInformationBackendStatus);

  // function getValidPoints(statusArray: Array<string>): number {
  //   const validStatus = statusArray.filter(function (s: string) {
  //     return s === 'received' || s === 'valid';
  //   });
  //   return validStatus.length;
  // }

  function getValidPoints(statusArray: Array<any>): number {
    const validStatus = statusArray.filter(function (s: any) {
      return s === true;
    });
    return validStatus.length;
  }

  function IsFileUploaded(fileBackendStatus: any, file: any) {
    return (
      (fileBackendStatus === 'received' || fileBackendStatus === 'valid') &&
      file !== undefined &&
      file.uploaded === true
    );
  }

  function handleEditCompletedFile() {
    if (isEditCompletedFile === false) {
      setIsEditCompletedFile(true);
    }
  }

  function shortenFileName(filename: string | null | undefined): string | null {
    let result = filename;
    if (filename === undefined) return null;
    if (filename === null) return null;
    else {
      if (filename.includes('<')) result = filename.slice(0, filename!.indexOf('<') - 1);

      if (filename.length >= 200) return result!.substring(0, 200);

      return result!;
    }
  }

  return (
    <div>
      <div className='mb-4'>
        <HeaderWithProgress from={getValidPoints(localStatus)} to={status.length}>
          {t('home.documentsTitle')}
        </HeaderWithProgress>
      </div>
      {status.length === getValidPoints(localStatus) && isEditCompletedFile === false ? (
        <div className='mb-2'>
          <div className='py-6 md:py-6 px-4 sm:px-8 bg-white rounded-xl shadow-sm'>
            <div className='flex items-center'>
              <div className='flex flex-shrink-0 justify-center items-center w-8 h-8 text-white bg-positive-500 rounded-full flex-items-center'>
                <Check width={16} height={16} />
              </div>
              <div className='flex-grow mt-1 ml-3'>
                <div className='flex justify-between items-center ml-2'>
                  <p>{t('home.documentUpload.fileComplete')}</p>
                  <button
                    onClick={handleEditCompletedFile}
                    className='inline-block p-2.5 px-3 ml-4 w-10 h-10 text-gray-800 rounded-md border border-gray-100 btn btn-white'
                  >
                    <IconEdit></IconEdit>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className='py-6 px-4 sm:px-8 bg-white rounded-xl shadow-sm'>
          <ActionListItem label='1' done={IsFileUploaded(permisRectoBackendStatus, permisRecto)}>
            <div className='sm:flex flex-1 justify-between ml-2'>
              <FileUploadHeader
                label={t('home.documentUpload.permisRecto')}
                fileName={shortenFileName(permisRectoFileName)}
              />
              {!IsFileUploaded(permisRectoBackendStatus, permisRecto) ? (
                <button
                  className='mt-2 sm:mt-0 sm:ml-2 btn btn-primary btn-xs lg:btn-sm btn-fluid sm:btn-solid'
                  disabled={isLoading}
                  onClick={() => {
                    setModalMode('permis-recto');
                    setDocumentUploaded('permis-recto');
                  }}
                >
                  {t('add')}
                </button>
              ) : (
                <span
                  className='block sm:inline-block p-2 sm:p-2.5 mt-2 sm:mt-0 w-full sm:w-10 h-9 sm:h-10 text-center text-gray-800 rounded-md border border-gray-100 cursor-pointer'
                  onClick={onDeletePermisRecto}
                >
                  <img src='/img/icons/iconDelete.svg' alt='delete' className='inline' />
                </span>
              )}
            </div>
          </ActionListItem>
          <ActionListItem label='2' done={IsFileUploaded(permisVersoBackendStatus, permisVerso)}>
            <div className='sm:flex flex-1 justify-between ml-2'>
              <FileUploadHeader
                label={t('home.documentUpload.permisVerso')}
                fileName={shortenFileName(permisVersoFileName)}
              />
              {!IsFileUploaded(permisVersoBackendStatus, permisVerso) ? (
                <button
                  className='mt-2 sm:mt-0 sm:ml-2 btn btn-primary btn-xs lg:btn-sm btn-fluid sm:btn-solid'
                  disabled={isLoading}
                  onClick={() => {
                    setModalMode('permis-verso');
                    setDocumentUploaded('permis-verso');
                  }}
                >
                  {t('add')}
                </button>
              ) : (
                <span
                  className='block sm:inline-block p-2 sm:p-2.5 mt-2 sm:mt-0 w-full sm:w-10 h-9 sm:h-10 text-center text-gray-800 rounded-md border border-gray-100 cursor-pointer'
                  onClick={onDeletePermisVerso}
                >
                  <img src='/img/icons/iconDelete.svg' alt='delete' className='inline' />
                </span>
              )}
            </div>
          </ActionListItem>
          <ActionListItem
            label='3'
            done={IsFileUploaded(carteGriseBackendStatus, cartegrise)}
            isLast={!isInsured}
          >
            <div className='sm:flex flex-1 justify-between ml-2'>
              <FileUploadHeader
                label={t('home.documentUpload.cg')}
                fileName={shortenFileName(CGFileName)}
              />
              {!IsFileUploaded(carteGriseBackendStatus, cartegrise) ? (
                <button
                  className='mt-2 sm:mt-0 sm:ml-2 btn btn-primary btn-xs lg:btn-sm btn-fluid sm:btn-solid'
                  disabled={isLoading}
                  onClick={() => {
                    setModalMode('carte-grise');
                    setDocumentUploaded('carte-grise');
                  }}
                >
                  {t('add')}
                </button>
              ) : (
                <span
                  className='block sm:inline-block p-2 sm:p-2.5 mt-2 sm:mt-0 w-full sm:w-10 h-9 sm:h-10 text-center text-gray-800 rounded-md border border-gray-100 cursor-pointer'
                  onClick={onDeleteCG}
                >
                  <img src='/img/icons/iconDelete.svg' alt='delete' className='inline' />
                </span>
              )}
            </div>
          </ActionListItem>

          {isInsured && (
            <ActionListItem
              label='4'
              done={IsFileUploaded(releveInformationBackendStatus, releveInformation)}
              isLast={!isSecondDriver}
            >
              <div className='sm:flex flex-1 justify-between ml-2'>
                <FileUploadHeader
                  label={t('home.documentUpload.info')}
                  fileName={shortenFileName(hasInfoFileName)}
                />
                {!IsFileUploaded(releveInformationBackendStatus, releveInformation) ? (
                  <button
                    className='mt-2 sm:mt-0 sm:ml-2 btn btn-primary btn-xs lg:btn-sm btn-fluid sm:btn-solid'
                    disabled={isLoading}
                    onClick={() => {
                      setModalMode('releve-information');
                      setDocumentUploaded('releve-information');
                    }}
                  >
                    {t('add')}
                  </button>
                ) : (
                  <span
                    className='block sm:inline-block p-2 sm:p-2.5 mt-2 sm:mt-0 w-full sm:w-10 h-9 sm:h-10 text-center text-gray-800 rounded-md border border-gray-100 cursor-pointer'
                    onClick={onDeleteInfo}
                  >
                    <img src='/img/icons/iconDelete.svg' alt='delete' className='inline' />
                  </span>
                )}
              </div>
            </ActionListItem>
          )}
          {isSecondDriver && (
            <>
              <ActionListItem
                label={isInsured ? '5' : '4'}
                done={IsFileUploaded(permisSecondDriverRectoBackendStatus, permisSecondDriverRecto)}
              >
                <div className='sm:flex flex-1 justify-between ml-2'>
                  <FileUploadHeader
                    label={t('home.documentUpload.permisSecondDriverRecto')}
                    fileName={shortenFileName(permisSecondDriverRectoFileName)}
                  />
                  {!IsFileUploaded(
                    permisSecondDriverRectoBackendStatus,
                    permisSecondDriverRecto
                  ) ? (
                    <button
                      className='mt-2 sm:mt-0 sm:ml-2 btn btn-primary btn-xs lg:btn-sm btn-fluid sm:btn-solid'
                      disabled={isLoading}
                      onClick={() => {
                        setModalMode('permis-recto-second-conducteur');
                        setDocumentUploaded('permis-recto-second-conducteur');
                      }}
                    >
                      {t('add')}
                    </button>
                  ) : (
                    <span
                      className='block sm:inline-block p-2 sm:p-2.5 mt-2 sm:mt-0 w-full sm:w-10 h-9 sm:h-10 text-center text-gray-800 rounded-md border border-gray-100 cursor-pointer'
                      onClick={onDeletePermisSecondDriverRecto}
                    >
                      <img src='/img/icons/iconDelete.svg' alt='delete' className='inline' />
                    </span>
                  )}
                </div>
              </ActionListItem>
              <ActionListItem
                label={isInsured ? '6' : '5'}
                done={IsFileUploaded(permisSecondDriverVersoBackendStatus, permisSecondDriverVerso)}
                isLast={isSecondDriver}
              >
                <div className='sm:flex flex-1 justify-between ml-2'>
                  <FileUploadHeader
                    label={t('home.documentUpload.permisSecondDriverVerso')}
                    fileName={shortenFileName(permisSecondDriverVersoFileName)}
                  />
                  {!IsFileUploaded(
                    permisSecondDriverVersoBackendStatus,
                    permisSecondDriverVerso
                  ) ? (
                    <button
                      className='mt-2 sm:mt-0 sm:ml-2 btn btn-primary btn-xs lg:btn-sm btn-fluid sm:btn-solid'
                      disabled={isLoading}
                      onClick={() => {
                        setModalMode('permis-verso-second-conducteur');
                        setDocumentUploaded('permis-verso-second-conducteur');
                      }}
                    >
                      {t('add')}
                    </button>
                  ) : (
                    <span
                      className='block sm:inline-block p-2 sm:p-2.5 mt-2 sm:mt-0 w-full sm:w-10 h-9 sm:h-10 text-center text-gray-800 rounded-md border border-gray-100 cursor-pointer'
                      onClick={onDeletePermisSecondDriverVerso}
                    >
                      <img src='/img/icons/iconDelete.svg' alt='delete' className='inline' />
                    </span>
                  )}
                </div>
              </ActionListItem>
            </>
          )}

          {/*
          <ActionListItem label='4' isLast done={odometerPhotoFileName !== null}>
            <div className='sm:flex flex-1 justify-between ml-2'>
              <FileUploadHeader
                label={t('home.documentUpload.odometer')}
                fileName={shortenFileName(odometerPhotoFileName)}
                onDelete={onDeleteOdometer}
              />
              {odometerPhotoFileName === null && (
                <button
                  className='sm:-mt-1 ml-2 btn btn-primary btn-xs lg:btn-sm'
                  disabled={isLoading}
                  onClick={onOpenModal}
                >
                  {isLoading && documentUploaded === 'compteur-kilometrique'
                    ? 'en cours'
                    : t('add')}
                </button>
              ) : (
                    <span
                      className='mt-2 sm:mt-0 block sm:inline-block p-2 sm:p-2.5 w-full sm:w-10 h-9 sm:h-10 text-center text-gray-800 rounded-md border border-gray-100 cursor-pointer'
                      onClick={onDeletePermisSecondDriverVerso}
                    >
                      <img src='/img/icons/iconDelete.svg' alt='delete' className='inline' />
                    </span>
                  )}
            </div>
          </ActionListItem>
          */}
        </div>
      )}
      <OdometerModal open={open} onCloseModal={onCloseModal} />
      <ModalSlider
        title={
          modalMode !== null
            ? t(`home.documentUpload.${modalMode.split('-').join('')}ModalTitle`, {
                defaultValue: 'Téléchargez votre fichier',
              })
            : ''
        }
        onClose={() => setModalMode(null)}
        open={modalMode !== null}
      >
        <UploadForm
          showCamera={showCamera}
          uploadFile={uploadFile}
          setModalMode={setModalMode}
          modalMode={modalMode}
        />
      </ModalSlider>
    </div>
  );
};

interface FileUploadHeaderProps {
  label: string;
  fileName: string | null;
}

const FileUploadHeader = ({ label, fileName }: FileUploadHeaderProps) => (
  <div>
    <p className='mt-1 font-bold text-gray-900'>{label}</p>
    {fileName !== null && (
      <div className='text-gray-500 break-all svg-inline'>
        <span>
          <IconPaperClip width={12} height={12} /> {fileName}
        </span>
      </div>
    )}
  </div>
);

const UploadForm = ({
  showCamera,
  uploadFile,
  setModalMode,
  modalMode,
}: {
  modalMode: ModalMode;
  setModalMode: (p: ModalMode) => void;
  showCamera: boolean;
  uploadFile: (q: { params: UploadFileQuery; file: FileList | null }) => void;
}) => {
  const { t } = useTranslation();
  const [{ policyData }] = useDashboardState();
  const selectFile = (event: React.ChangeEvent<HTMLInputElement>) => {
    setModalMode(null);
    uploadFile({
      params: { file_name: modalMode!, policy_id: Number(policyData?.policy_out?.id) },
      file: event.target.files,
    });
  };

  /*
  const baseURL = import.meta.env.VITE_API_PUBLIC_DOMAIN as string;
  const [takePhoto, setTakePhoto] = useState(false);
  function pleaseTakePhoto(event: any) {
    event.preventDefault();
    setTakePhoto(true);
  }
  function manageTakePhoto(dataUri: string) {
    setImageUri(dataUri);
  }
  const [isLoading, setIsLoading] = useState(false);
  const [successFirebase, setSuccessFirebase] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const [imageUri, setImageUri] = useState('');
  const [{ driver_id }] = useAuth();
  const { refetch } = useDocumentData({ driver_id: driver_id as number });
  async function uploadPhoto({ base64_str }: { base64_str: string }) {
    setIsLoading(true);
    fetch(`${baseURL}/api/v1/dashboard/uploadb64`, {
      method: 'POST',
      body: JSON.stringify({
        file_name: modalMode,
        policy_id: Number(policyData!.policy_out.id),
        base64_str: base64_str,
      }),
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(function (response) {
        setIsLoading(false);
        return response.json();
      })
      .then(async function () {
        setIsLoading(false);
        setSuccessFirebase(true);
        setErrorMsg('');
        await refetch();
      })
      .catch(() => {
        setIsLoading(false);
        setErrorMsg(t('picture.error.general'));
      });
  }
  */
  return (
    <form className='py-6 space-y-3'>
      {showCamera && (
        <>
          <div className='p-3 rounded-lg border border-gray-100 btn-white'>
            <label
              className='flex justify-between items-center text-gray-600 cursor-pointer'
              htmlFor='camera-upload'
            >
              <span>{t('fileUpload.cameraLabel')}</span>
              <CameraIcon size={24} />
            </label>
          </div>

          {/* <input
            hidden
            type='file'
            capture='environment'
            accept='image/*'
            id='camera-upload'
            name='cameraInput'
          /> */}
        </>
      )}

      {/* <label
        className='flex justify-between items-center h-20 font-bold text-gray-900 cursor-pointer'
        htmlFor='photo-upload'
      >
        <span>{t('fileUpload.photoLabel')}</span>
        <Picture size={24} />
      </label>
      <input hidden type='file' id='photo-upload' accept='image/*' onChange={selectFile} /> */}

      <div className='p-3 rounded-lg border border-gray-100 btn-white'>
        <label
          className='flex justify-between items-center text-gray-600 cursor-pointer'
          htmlFor='file-upload'
        >
          <span>{t('fileUpload.fileLabel')}</span>
          <IconPaperClip />
        </label>
        <input hidden type='file' id='file-upload' onChange={selectFile} />
      </div>
    </form>
  );
};

interface OdometerModalProps {
  onCloseModal: () => void;
  open: boolean;
}

const OdometerModal = ({ onCloseModal, open }: OdometerModalProps) => {
  const { t } = useTranslation();
  const [{ policyData }] = useDashboardState();
  const { mutate: sendSms, isLoading } = useSendSms();

  const onCopyNumber = () => {
    sendSms({ policy_id: Number(policyData?.policy_out.id) });
  };

  return (
    <ModalSlider onClose={onCloseModal} open={open} title={t('home.odometer.title')}>
      <div className='pt-4'>
        <p className='mb-4'>
          {t('home.odometer.description')}{' '}
          <span className='text-base font-bold text-primary-500'>
            {policyData?.driver_out.qt_phone}
          </span>
        </p>
        <div className='flex flex-wrap gap-4'>
          {/*
          <button
            type='button'
            onClick={onCopyNumber}
            className='btn btn-primary btn-xs lg:btn-sm btn-fluid sm:btn-solid'
          >
            {t('contact.assistance.copy')}
          </button>
          */}
          <a
            className='block w-full sm:w-auto'
            //href={`tel:${t('home.odometer.phoneNumber').replace(/\s/g, '')}`}
          >
            <button
              type='button'
              className='btn btn-primary btn-xs lg:btn-sm btn-fluid sm:btn-solid'
              onClick={onCopyNumber}
            >
              {t('home.odometer.send')}
            </button>
          </a>
        </div>
      </div>
    </ModalSlider>
  );
};

export default DocumentsUpload;
