import { LottieAnimation } from '@flitter-insurance/nessui';
import { useState } from 'react';

import IconDownload from '@/assets/svg/IconDownload';
import IconFile from '@/assets/svg/IconFile';
import { useDashboardState } from '@/context/DashBoard';
import useDownloadFile, { DownloadFileQuery } from '@/hooks/api/dashboard/useDownloadFile';
import useDownloadInvoice from '@/hooks/api/dashboard/useDownloadInvoice';

import WhiteCard from '../_shared/WhiteCard';

interface DocumentDownloadProps {
  title: string;
  description: string;
  fileType: DownloadFileType;
  invoiceData?: invoiceDataType;
}

type invoiceDataType = {
  gs_path: string;
  period_end: string;
  period_start: string;
} | null;

type DownloadFileType = DownloadFileQuery['file_name'] | null;

const DocumentDownload = ({
  title,
  description,
  fileType,
  invoiceData = null,
}: DocumentDownloadProps) => {
  const { mutate: downloadFile } = useDownloadFile();
  const { mutate: downloadInvoice } = useDownloadInvoice();
  const [{ policyData, documentData }] = useDashboardState();
  const [downloadFileType] = useState<DownloadFileType>(fileType);
  const [isLoading, setIsLoading] = useState(false);

  function download() {
    setIsLoading(true);
    setTimeout(() => {
      if (invoiceData?.gs_path) {
        setIsLoading(false);
        downloadInvoice({
          params: {
            policy_id: Number(policyData?.policy_out?.id),
            file_path: invoiceData?.gs_path as string,
          },
        });
      } else {
        setIsLoading(false);
        downloadFile({
          params: {
            policy_id: Number(policyData?.policy_out?.id),
            file_name: downloadFileType!,
          },
        });
      }
    }, 500);
  }

  return (
    <div className='mb-2'>
      <WhiteCard>
        <div className='flex py-4 px-6 md:px-6 sm:pb-3 sm:space-x-6'>
          <div className='hidden md:block flex-none'>
            <span
              className={`p-3 sm:p-3.5 w-11 h-11 sm:w-12.5 sm:h-12.5 bg-gray-50 rounded-full inline-block`}
            >
              <IconFile />
            </span>
          </div>
          <div className='flex-1'>
            {invoiceData?.gs_path ? (
              <p className={'flex-grow sm:mb-1 sm:text-base font-bold text-gray-900'}>
                Période du {invoiceData.period_start} au {invoiceData.period_end}
              </p>
            ) : (
              <p className={'flex-grow sm:mb-1 sm:text-base font-bold text-gray-900'}>{title}</p>
            )}
            <p className='text-sm'>{description}</p>
          </div>
          <button onClick={download} className='text-right'>
            {!isLoading ? (
              <span className='inline-block p-2.5 ml-2 sm:ml-0 w-10 h-10 text-gray-800 rounded-md border border-gray-100'>
                <IconDownload />
              </span>
            ) : (
              <span className='inline-block'>
                <LottieAnimation type='black-dots' />
              </span>
            )}
          </button>
        </div>
      </WhiteCard>
    </div>
  );
};

export default DocumentDownload;
