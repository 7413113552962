import { Details } from '@flitter-insurance/nessui';
import { useTranslation } from 'react-i18next';

import WhiteCard from '@/components/pages/dashboard/_shared/WhiteCard';
import { Formule, imgSrcFromGarantie } from '@/components/utils/constants';
import useGarantiesFromFormule from '@/hooks/useGarantiesFromFormule';
import useMediaQuery from '@/hooks/useMediaQuery';

export const GarantiesCovered = ({ formule }: { formule: Formule }) => {
  const { t } = useTranslation();
  const { enabled } = useGarantiesFromFormule(formule);
  const [isMobileScreen] = useMediaQuery(640);

  return (
    <div>
      <h2 className='mb-4 sm:ml-2 text-lg sm:text-xl'> {t('garanties.details.covered')}</h2>
      <WhiteCard>
        <div className='grid grid-cols-1 py-2 divide-y divide-gray-50'>
          {enabled.map((garantie) => (
            <div key={garantie} className='px-6'>
              <Details
                title={t(`garanties.details.title.${garantie}`)}
                icon={imgSrcFromGarantie[garantie]}
                openByDefault={!isMobileScreen}
              >
                <div className='mt-2 text-gray-600'>{t(`garanties.details.text.${garantie}`)}</div>
              </Details>
            </div>
          ))}
        </div>
      </WhiteCard>
    </div>
  );
};
