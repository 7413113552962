import { Building, CreditCard, OptionsIcon, Trash } from '@flitter-insurance/nessui';
import { Menu, Transition } from '@headlessui/react';
import { ComponentPropsWithoutRef, Fragment } from 'react';
import { useTranslation } from 'react-i18next';

import IconPaymentCardFlat from '@/assets/svg/IconPaymentCardFlat';
import IconPaymentCardFlatExpired from '@/assets/svg/IconPaymentCardFlatExpired';
import IconRibFlat from '@/assets/svg/IconRibFlat';
import IconRibFlatExpired from '@/assets/svg/IconRibFlatExpired';
import { PaymentMethodType } from '@/components/utils/constants';
import { useDashboardState } from '@/context/DashBoard';

interface PaymentMethodData {
  id: number;
  type: PaymentMethodType;
  lastDigits: number | any;
  isDefault?: boolean;
  isExpired?: boolean | null;
  expMonth?: number | any;
  expYear?: number | any;
}

const usePaymentMethods = (): PaymentMethodData[] => {
  const [{ paymentMethodData }] = useDashboardState();

  const paymentMethods: PaymentMethodData[] = paymentMethodData!.map(function (item, key) {
    return {
      id: Number(key),
      type: item.payment_method.type === 'card' ? PaymentMethodType.Card : PaymentMethodType.Sepa,
      isDefault: item.is_default,
      lastDigits:
        item.payment_method.type === 'card'
          ? item.payment_method.card?.last4
          : item.payment_method.sepa_debit?.last4,
      isExpired: null,
      expMonth: item.payment_method.type === 'card' ? item.payment_method.card?.exp_month : null,
      expYear: item.payment_method.type === 'card' ? item.payment_method.card?.exp_year : null,
    };
  });

  return paymentMethods;
};

const PaymentMethods = () => {
  const paymentMethods = usePaymentMethods();
  const { t } = useTranslation();
  return (
    <div>
      <h2 className='mb-4 sm:ml-2 text-lg sm:text-xl'>{t('paymentMethods.title')}</h2>
      {paymentMethods.map((paymentMethod: any) => {
        const { id } = paymentMethod;
        return <PaymentMethodItem key={id} paymentMethod={paymentMethod} />;
      })}
    </div>
  );
};

interface PaymentMethodItemProps {
  paymentMethod: PaymentMethodData;
}
const PaymentMethodItem = ({ paymentMethod }: PaymentMethodItemProps) => {
  const { type, lastDigits, isDefault, isExpired, expMonth, expYear } = paymentMethod;
  return (
    <div
      className={`flex mb-2 p-3 px-1 sm:p-3 py-4 pl-3 sm:pl-6 bg-white rounded-xl shadow-sm  ${
        isExpired ? 'text-gray-300' : 'text-gray-900'
      }`}
    >
      <div className='flex flex-wrap flex-grow items-center '>
        <div className='flex flex-grow items-center no-wrap'>
          <PaymentLogo type={type} isExpired={isExpired ? true : false} />
          <div className='flex-col sm:ml-1'>
            <div className='flex ml-2 sm:ml-4'>
              <span className='text-sm font-bold text-gray-900'>****</span>
              <div className='ml-1 font-bold'>{lastDigits}</div>
            </div>
            <div className='sm:block ml-2 sm:ml-4'>
              {type === 'sepa_debit' && <span>RIB</span>}
              {type === 'card' && (
                <>
                  <span>Carte bancaire</span> <span className='hidden sm:inline'>-</span>{' '}
                  <span className='block sm:inline'>
                    Expire : {expMonth}/{expYear}
                  </span>
                </>
              )}
            </div>
          </div>
        </div>
        {isDefault && <DefaultChip />}
      </div>

      <div className='flex items-center mr-1 sm:mr-6 ml-2'>
        {isExpired ? (
          <ExpiredChip
          /*onClick={() => {
              alert('todo');
            }}*/
          />
        ) : (
          <></>
          // <PaymentMethodOptions isDefault={isDefault} />
        )}
      </div>
    </div>
  );
};

const PaymentMethodOptions = ({ isDefault }: { isDefault?: boolean }) => {
  const { t } = useTranslation();
  return (
    <Menu as='div' className='relative'>
      <div>
        <Menu.Button className='inline-block p-2 sm:ml-4 w-10 h-10 text-gray-800 rounded-md border border-gray-100'>
          <OptionsIcon size={24} />
        </Menu.Button>
      </div>
      <Transition
        as={Fragment}
        enter='transition ease-out duration-100'
        enterFrom='transform opacity-0 scale-95'
        enterTo='transform opacity-100 scale-100'
        leave='transition ease-in duration-75'
        leaveFrom='transform opacity-100 scale-100'
        leaveTo='transform opacity-0 scale-95'
      >
        <Menu.Items className='absolute right-0 z-10 p-4 mt-2 text-gray-900 whitespace-nowrap bg-white rounded-xl divide-y divide-gray-50 shadow-lg origin-top-right focus:outline-none'>
          <Menu.Item disabled={isDefault}>
            <button
              className={`flex items-center px-4 space-x-3 w-full h-11 text-xs hover:bg-gray-50 ${
                isDefault ? ' text-gray-900 bg-gray-50 ' : ''
              }`}
              disabled={isDefault}
              // onClick={() => {
              //   alert('todo');
              // }}
            >
              {t('paymentMethods.setDefault')}
            </button>
          </Menu.Item>
          <Menu.Item>
            <button
              className='flex items-center px-4 space-x-3 w-full h-11 text-xs hover:bg-gray-50'
              // onClick={() => {
              //   alert('todo');
              // }}
            >
              {t('paymentMethods.delete')}
            </button>
          </Menu.Item>
        </Menu.Items>
      </Transition>
    </Menu>
  );
};

const DefaultChip = () => {
  const { t } = useTranslation();
  return (
    <div className='flex flex-nowrap items-center py-3 px-2 sm:px-3 h-6 text-xs whitespace-nowrap bg-gray-50 rounded-lg'>
      <span className='text-gray-900'>{t('paymentMethods.isDefault')}</span>
      {/* <div className='ml-2 w-2 h-2 bg-secondary-500 rounded-full' /> */}
    </div>
  );
};

const ExpiredChip = (props: ComponentPropsWithoutRef<'button'>) => {
  const { t } = useTranslation();
  return (
    <button
      {...props}
      className='flex flex-nowrap items-center h-6 text-xs text-primary-700 bg-critical-300 hover:bg-critical-500 hover:border-critical-500 btn btn-xs'
    >
      <span>{t('paymentMethods.isExpired')}</span>
    </button>
  );
};

// const Dots = () => (
//   <div className='flex flex-nowrap'>
//     <div className='w-1 h-1 bg-current rounded-full' />
//     <div className='ml-0.5 w-1 h-1 bg-current rounded-full' />
//     <div className='ml-0.5 w-1 h-1 bg-current rounded-full' />
//     <div className='ml-0.5 w-1 h-1 bg-current rounded-full' />
//   </div>
// );

const PaymentLogo = ({
  type,
  size,
  isExpired,
}: {
  type: PaymentMethodType;
  size?: number;
  isExpired?: boolean;
}) => {
  if (type === PaymentMethodType.Card) {
    if (isExpired) {
      return <IconPaymentCardFlatExpired />;
    } else {
      console.log('isExpired', isExpired);
      return <IconPaymentCardFlat />;
    }
  }
  if (type === PaymentMethodType.Sepa) {
    if (isExpired) {
      return <IconRibFlatExpired />;
    } else {
      return <IconRibFlat />;
    }
  }
  return null;
};

export default PaymentMethods;
