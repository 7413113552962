import React, { createContext, useContext } from 'react';

import { CourrierStatusDataResult } from '@/hooks/api/dashboard/useCourrierStatus';
import { DocumentDataResult } from '@/hooks/api/dashboard/useDocument';
import { PaymentMethodDataResult } from '@/hooks/api/dashboard/usePaymentMethods';
import { PolicyDataResult } from '@/hooks/api/dashboard/usePolicyData';
import { UploadFileQuery } from '@/hooks/api/dashboard/useUploadFile';

import useLocalStorage from '../hooks/useLocalStorage';

export type Document = {
  type?: UploadFileQuery['file_name'];
  uploaded?: boolean;
  name?: string;
  validated?: boolean;
};

export type DashboardInput = {
  permisRecto?: Document;
  permisVerso?: Document;
  cartegrise?: Document;
  releveInformation?: Document;
  compteurKilometrique?: Document;
  permisSecondDriverRecto?: Document;
  permisSecondDriverVerso?: Document;
  policyData: PolicyDataResult | null;
  documentData?: DocumentDataResult;
  paymentMethodData?: PaymentMethodDataResult;
  courrierData?: CourrierStatusDataResult;
};

const storageKey = 'dashboardState';

export const initialDashboardState: DashboardInput = {
  policyData: null,
};

type DashboardStateContextType = readonly [
  DashboardInput,
  (value: DashboardInput | ((val: DashboardInput) => DashboardInput)) => void
];
export const DashboardStateContext = createContext<DashboardStateContextType>([
  initialDashboardState,
  () => {},
]);

export const DashboardStateProvider: React.FC = ({ children }) => {
  const [dashboardState, setDashboardState] = useLocalStorage<DashboardInput>(
    storageKey,
    initialDashboardState
  );
  const setDashboardStateWithSegment = (
    dashboard: DashboardInput | ((q: DashboardInput) => DashboardInput)
  ) => {
    let dashboardValue;
    if (typeof dashboard === 'function') dashboardValue = dashboard(dashboardState);
    else dashboardValue = dashboard;
    if (dashboardValue.policyData && dashboardValue.policyData !== null)
      setDashboardState(dashboardValue);
  };
  return (
    <DashboardStateContext.Provider value={[dashboardState, setDashboardStateWithSegment]}>
      {children}
    </DashboardStateContext.Provider>
  );
};

export const useDashboardState = () => useContext(DashboardStateContext);
