const IconDocumentCheck = ({ width = 16, height = 16 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox={'0 0 16 16'}
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M9.03884 7.33337L7.74284 8.62937L6.96484 7.85137'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M6 10.6666H10'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M13.3641 14V3.14456C13.3641 3.08557 13.3484 3.02764 13.3187 2.97668C13.289 2.92572 13.2463 2.88356 13.195 2.85449C13.1437 2.82543 13.0855 2.81051 13.0266 2.81126C12.9676 2.812 12.9098 2.82838 12.8593 2.85873L11.9185 3.42316C11.8149 3.48533 11.6964 3.51817 11.5755 3.51817C11.4547 3.51817 11.3361 3.48533 11.2325 3.42316L10.13 2.76163C10.0264 2.69947 9.90782 2.66663 9.78699 2.66663C9.66616 2.66663 9.5476 2.69947 9.44399 2.76163L8.34145 3.42316C8.23784 3.48533 8.11928 3.51817 7.99845 3.51817C7.87762 3.51817 7.75906 3.48533 7.65545 3.42316L6.55291 2.76163C6.44929 2.69947 6.33074 2.66663 6.20991 2.66663C6.08908 2.66663 5.97052 2.69947 5.86691 2.76163L4.76436 3.42316C4.66075 3.48533 4.54219 3.51817 4.42136 3.51817C4.30053 3.51817 4.18197 3.48533 4.07836 3.42316L3.13765 2.85873C3.08706 2.82838 3.02934 2.812 2.97035 2.81126C2.91137 2.81051 2.85325 2.82543 2.80192 2.85449C2.75058 2.88356 2.70788 2.92572 2.67818 2.97668C2.64847 3.02764 2.63281 3.08557 2.63281 3.14456V14'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M14 14H2'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export default IconDocumentCheck;
