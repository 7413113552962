import { OptionsIcon } from '@flitter-insurance/nessui';
import { Menu, Transition } from '@headlessui/react';
import { Fragment } from 'react';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';

import { useDashboardState } from '@/context/DashBoard';

import WhiteCard from '../_shared/WhiteCard';

const InsuredDrivers = () => {
  const { t } = useTranslation();

  const [{ policyData }] = useDashboardState();

  const principalDriver =
    policyData?.driver_out.qt_first_name + ' ' + policyData?.driver_out.qt_last_name;
  const isSecondDriver = policyData?.policy_out.qt_is_seconddriver;
  const secondDriver =
    policyData?.policy_out.qt_firstname_seconddriver +
    ' ' +
    policyData?.policy_out.qt_lastname_seconddriver;

  return (
    <div>
      <h2 className='mb-4 sm:ml-2 text-lg sm:text-xl'>{t('garanties.insuredDriver.title')}</h2>
      <WhiteCard>
        <div className='grid grid-cols-1 py-2 divide-y divide-gray-50'>
          <div className='flex py-5 px-6 space-x-6'>
            <div className='grow'>
              <p className={'flex-grow mb-1 text-base font-bold text-gray-900'}>
                {principalDriver}
              </p>
              <p className='mt-2 text-gray-600'>{t('garanties.insuredDriver.principalDriver')}</p>
            </div>
          </div>
          {isSecondDriver && (
            <div className='flex py-5 px-6 space-x-6'>
              <div className='grow'>
                <p className={'flex-grow mb-1 text-base font-bold text-gray-900'}>{secondDriver}</p>
                <p className='mt-2 text-gray-600'>{t('garanties.insuredDriver.secondDriver')}</p>
              </div>
              {/* 
              <button className='flex-grow text-right'>
                <SecondDriverActions />
              </button>
              */}
            </div>
          )}
        </div>
      </WhiteCard>
    </div>
  );
};

const SecondDriverActions = () => {
  const { t } = useTranslation();

  const [{ policyData }, setDashBoardState] = useDashboardState();

  const onDeleteSecondDriver = () => {
    if (policyData?.policy_out) {
      const _policyData = policyData;
      _policyData.policy_out.qt_birthdate_seconddriver = '1900-01-01';
      _policyData.policy_out.qt_licensedate_seconddriver = '1900-01-01';
      _policyData.policy_out.qt_firstname_seconddriver = '';
      _policyData.policy_out.qt_lastname_seconddriver = '';
      _policyData.policy_out.qt_is_extradriverexpert = false;
      _policyData.policy_out.qt_is_extradrivernovice = false;
      _policyData.policy_out.qt_is_seconddriver = false;
      _policyData.policy_out.qt_is_seconddriverspouse = false;

      setDashBoardState((prevState) => ({
        ...prevState,
        policyData: _policyData,
      }));

      toast.success('Le conducteur secondaire a été supprimé !');
    }
  };

  return (
    <Menu as='div' className='relative'>
      <div>
        <Menu.Button className='inline-block p-2 sm:ml-4 w-10 h-10 text-gray-800 rounded-md border border-gray-100'>
          <OptionsIcon size={24} />
        </Menu.Button>
      </div>
      <Transition
        as={Fragment}
        enter='transition ease-out duration-100'
        enterFrom='transform opacity-0 scale-95'
        enterTo='transform opacity-100 scale-100'
        leave='transition ease-in duration-75'
        leaveFrom='transform opacity-100 scale-100'
        leaveTo='transform opacity-0 scale-95'
      >
        <Menu.Items className='absolute right-0 z-10 p-4 mt-2 text-gray-900 whitespace-nowrap bg-white rounded-xl divide-y divide-gray-50 shadow-lg origin-top-right focus:outline-none'>
          <Menu.Item>
            <p
              className='flex items-center px-4 space-x-3 w-full h-11 text-sm hover:bg-gray-50'
              // onClick={() => {
              //   alert('todo');
              // }}
            >
              {t('garanties.insuredDriver.edit')}
            </p>
          </Menu.Item>
          <Menu.Item>
            <p
              className='flex items-center px-4 space-x-3 w-full h-11 text-sm hover:bg-gray-50'
              onClick={onDeleteSecondDriver}
            >
              {t('garanties.insuredDriver.delete')}
            </p>
          </Menu.Item>
        </Menu.Items>
      </Transition>
    </Menu>
  );
};

export default InsuredDrivers;
