import { TextField } from '@flitter-insurance/nessui';
import { isValidPhoneNumber, parsePhoneNumber } from 'libphonenumber-js';
import { ChangeEventHandler, FormEventHandler, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useAuth } from '@/context/Auth';
import { useDashboardState } from '@/context/DashBoard';
import usePolicyData from '@/hooks/api/dashboard/usePolicyData';
import useUpdateDriver from '@/hooks/api/dashboard/useUpdateDriver';

const useProfileForm = () => {
  // todo : get from server
  const [{ driver_id }] = useAuth();
  const [{ policyData: policyDataState }] = useDashboardState();

  const { mutate: updateDriver } = useUpdateDriver();
  const { data: policyData, isLoading } = usePolicyData({ driver_id: driver_id as number });
  const [email, setEmail] = useState(policyDataState?.driver_out.qt_email || '');
  const [phone, setPhone] = useState(policyDataState?.driver_out.qt_phone || '');
  const [phoneError, setPhoneError] = useState('');
  const [firstname, setFirstname] = useState(policyDataState?.driver_out.qt_first_name || '');
  const [lastname, setLastname] = useState(policyDataState?.driver_out.qt_last_name || '');
  const [canSubmit, setCanSubmit] = useState(false);

  useEffect(() => {
    if (!isLoading && policyData) {
      setEmail(policyData?.driver_out.qt_email as string);
      setPhone(policyData?.driver_out.qt_phone as string);
      setFirstname(policyData?.driver_out.qt_first_name as string);
      setLastname(policyData?.driver_out.qt_last_name as string);
    }
  }, [isLoading, policyData]);

  const onChangePhone: ChangeEventHandler<HTMLInputElement> = (e) => {
    setCanSubmit(true);
    const rawPhone = e.target.value.replace(' ', '');
    if (rawPhone.length < 10) {
      setPhone(rawPhone);
      return;
    }
    if (rawPhone.length < 13) {
      const formatedPhoneNumber = parsePhoneNumber(rawPhone, 'FR');
      const number = formatedPhoneNumber.formatNational();
      setPhone(number);
      return;
    }
    const formatedPhoneNumber = parsePhoneNumber(rawPhone, 'FR');
    const number = formatedPhoneNumber.formatInternational();
    setPhone(number);
  };

  const onSubmit: FormEventHandler = (e) => {
    e.preventDefault();
    setPhoneError('');
    if (phone.length === 0 || !isValidPhoneNumber(phone, 'FR')) {
      setPhoneError("Le numéro de téléphone n'est pas valide");
      return;
    }
    updateDriver({
      qt_phone: phone,
    });
  };

  return {
    firstname,
    lastname,
    email,
    phone,
    phoneError,
    onChangePhone,
    canSubmit,
    onSubmit,
    isLoading,
  };
};

const ProfileForm = () => {
  const { t } = useTranslation();
  const { firstname, lastname, email, phone, phoneError, onChangePhone, canSubmit, onSubmit } =
    useProfileForm();
  return (
    <form onSubmit={onSubmit} className='space-y-6'>
      <div className='grid grid-cols-1 lg:grid-cols-2 gap-x-4 space-y-4 lg:space-y-0'>
        <TextField value={firstname} disabled label={t('profile.profileForm.firstname')} />
        <TextField value={lastname} disabled label={t('profile.profileForm.lastname')} />
      </div>
      <TextField
        disabled
        value={email}
        name='email'
        autoComplete='email'
        id='email'
        type='email'
        placeholder={t('profile.profileForm.emailPlaceholder')}
        label={t('profile.profileForm.email')}
      />
      <TextField
        value={phone}
        type='tel'
        name='phone'
        autoComplete='tel'
        id='phone'
        onChange={onChangePhone}
        placeholder={t('profile.profileForm.phonePlaceholder')}
        label={t('profile.profileForm.phone')}
        error={phoneError}
      />
      <div className='text-right'>
        <button
          type='submit'
          onClick={onSubmit}
          disabled={!canSubmit}
          className='sm:btn-solid btn btn-primary btn-xs lg:btn-sm'
        >
          {t('save')}
        </button>
      </div>
    </form>
  );
};

export default ProfileForm;
