import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

import WhiteCard from '@/components/pages/dashboard/_shared/WhiteCard';
import { Formule } from '@/components/utils/constants';

import ContractDetails from './ContractDetails';

interface ContractSummaryProps {
  distance: number;
  immatriculation: string;
  contractNumber: string;
  price: number;
  formule: Formule;
  franchiseBdgPrice: number;
  franchiseAccidentPrice: number;
  carMake: string;
  paymentfrequency: string;
  contractStart: string | undefined;
}

const ContractSummary = ({
  distance,
  immatriculation,
  contractNumber,
  price,
  formule,
  franchiseAccidentPrice,
  franchiseBdgPrice,
  carMake,
  paymentfrequency,
  contractStart,
}: ContractSummaryProps) => {
  const { t } = useTranslation();
  /*const [open, setOpen] = useState(true);
  const toggle = () => {
    setOpen((isOpen) => !isOpen);
  };*/

  return (
    <>
      <div>
        <h2
          // onClick={toggle}
          className='mb-4'
        >
          {t('garanties.summary.title', { carMake })}
          {/*  <div>
            <ArrowDown
              className={`transition-transform duration-200 transform ${
                open ? 'rotate-0' : 'rotate-180'
              }`}
            />
          </div> */}
        </h2>
        <WhiteCard>
          <div className='grid grid-cols-9 py-6 md:py-8 px-4 sm:px-8'>
            <div className='col-span-9 sm:col-span-2 xl:col-span-2 lg:pt-8 mb-5 lg:mb-0'>
              <img src='/img/manCarLarge.svg' width={147} />
            </div>
            <div className='col-span-9 sm:col-span-5 xl:col-span-3 sm:pl-8 md:pl-4 2xl:ml-8'>
              <h2 className='text-lg'>{t(`home.quoteName.${formule}`)}</h2>
              <p className='mb-4 text-xs text-gray-600'>
                {t('home.forfait', { distance, price, paymentfrequency })}
              </p>
              <p className='block xl:hidden'>
                <ValueDisplay
                  label={t('garanties.summary.contract')}
                  value={contractNumber ? contractNumber.replace('FLITTER_AUTO_', '') : ''}
                />
              </p>
              <ValueDisplay
                label={'Début du contract :'}
                value={contractStart ? dayjs(contractStart).format('DD/MM/YYYY') : ''}
              />
              <ValueDisplay
                label={t('garanties.summary.immatriculation')}
                value={immatriculation}
              />
            </div>
            <div className='hidden xl:block col-span-7 sm:col-span-4 pt-16 text-right'>
              <ValueDisplay
                label={t('garanties.summary.contract')}
                value={contractNumber ? contractNumber.replace('FLITTER_AUTO_', '') : ''}
              />
            </div>
          </div>
        </WhiteCard>
      </div>
      <ContractDetails
        franchiseAccidentPrice={franchiseAccidentPrice}
        franchiseBdgPrice={franchiseBdgPrice}
        formule={formule}
      />
    </>
  );
};

interface ValueDisplayProps {
  label: string;
  value: string;
}
const ValueDisplay = ({ label, value }: ValueDisplayProps) => (
  <p className='mb-2'>
    <span className='text-sm text-gray-700'>{label}</span>
    <span className='inline-block float-right xl:float-none py-1 px-2 ml-1 text-xs text-gray-900 break-all bg-gray-50 rounded-md'>
      {value}
    </span>
  </p>
);

export default ContractSummary;
