const IconEdit = ({ width = 16, height = 16 }) => {
  return (
    <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M11.6939 6.74664L9.25391 4.30664'
        stroke='#1E2044'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M4.16733 14H2V11.8327C2 11.656 2.07 11.486 2.19533 11.3614L11.0847 2.47138C11.3453 2.21071 11.7673 2.21071 12.0273 2.47138L13.528 3.97205C13.7887 4.23271 13.7887 4.65471 13.528 4.91471L4.63867 13.8047C4.514 13.93 4.344 14 4.16733 14V14Z'
        stroke='#1E2044'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export default IconEdit;
