import { TextField } from '@flitter-insurance/nessui';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useDashboardState } from '@/context/DashBoard';
import useUpdateDriver from '@/hooks/api/dashboard/useUpdateDriver';

export const useDriverAdress = () => {
  const [{ policyData: policyDataState }] = useDashboardState();
  const { mutate: updateDriver, isSuccess } = useUpdateDriver();
  const [canSubmit, setCanSubmit] = useState(false);

  const [streetNameAndNumber, setStreetNameAndNumber] = useState<string>(
    policyDataState?.driver_out.qt_line1 ? policyDataState?.driver_out.qt_line1 : ''
  );
  const [extraAddress, setExtraAddress] = useState<string>(
    policyDataState?.driver_out.qt_line2 ? policyDataState?.driver_out.qt_line2 : ''
  );
  const [postalCode, setPostalCode] = useState<string>(
    policyDataState?.driver_out.qt_postal_code ? policyDataState?.driver_out.qt_postal_code : ''
  );
  const [city, setCity] = useState<string>(
    policyDataState?.driver_out.qt_city ? policyDataState.driver_out.qt_city : ''
  );

  const updateStreetNameAndNumber = (e: any) => {
    setStreetNameAndNumber(e.target.value);
  };

  const updateExtraAddress = (e: any) => {
    setExtraAddress(e.target.value);
    setCanSubmit(true);
  };

  const validPostalCode = new RegExp('[0-9]{5}$');
  const updatePostalCode = (e: any) => {
    setPostalCode(e.target.value);
    if (validPostalCode.test(e.target.value)) {
      setCanSubmit(true);
    } else {
      setCanSubmit(false);
    }
  };

  const updateCity = (e: any) => {
    setCity(e.target.value);
    setCanSubmit(true);
  };

  const saveAddressBtn = () => {
    updateDriver({
      qt_line1: streetNameAndNumber,
      qt_line2: extraAddress,
      qt_city: city,
      qt_postal_code: postalCode,
    });
    if (isSuccess) setCanSubmit(false);
  };

  return {
    streetNameAndNumber,
    updateStreetNameAndNumber,
    extraAddress,
    updateExtraAddress,
    postalCode,
    updatePostalCode,
    city,
    updateCity,
    saveAddressBtn,
    canSubmit,
  };
};

interface ProfileAddressFormProps {
  isAddressEditModeActivated: boolean;
  setIsAddressEditModeActivated: (e: boolean) => void;
}

const ProfileAddressForm = ({
  isAddressEditModeActivated,
  setIsAddressEditModeActivated,
}: ProfileAddressFormProps) => {
  const { t } = useTranslation();
  const {
    extraAddress,
    updateExtraAddress,
    streetNameAndNumber,
    updateStreetNameAndNumber,
    postalCode,
    updatePostalCode,
    city,
    updateCity,
    saveAddressBtn,
    canSubmit,
  } = useDriverAdress();

  function handleSubmit() {
    saveAddressBtn();
    setIsAddressEditModeActivated(false);
  }

  return (
    <div className='space-y-6'>
      <div className='grid grid-cols-1 lg:grid-cols-2 gap-x-4 space-y-4 lg:space-y-0'>
        <TextField
          value={streetNameAndNumber}
          onChange={updateStreetNameAndNumber}
          name='streetNameAndNumber'
          id='streetNameAndNumber'
          type='text'
          label={t('profile.profileForm.postalCode')}
          disabled={!isAddressEditModeActivated}
        />
        <TextField
          value={postalCode}
          onChange={updatePostalCode}
          name='postalCode'
          id='postalCode'
          type='text'
          label={t('profile.profileForm.postalCode')}
          disabled={!isAddressEditModeActivated}
        />
      </div>

      <div className='grid grid-cols-1 lg:grid-cols-2 gap-x-4 space-y-4 lg:space-y-0'>
        <TextField
          value={extraAddress}
          onChange={updateExtraAddress}
          name='extraAdress'
          id='extraAdress'
          type='text'
          label={t('profile.profileForm.extraAddress')}
          disabled={!isAddressEditModeActivated}
        />
        <TextField
          value={city}
          onChange={updateCity}
          name='city'
          id='city'
          type='text'
          label={t('profile.profileForm.city')}
          disabled={!isAddressEditModeActivated}
        />
      </div>

      <div className='text-right'>
        <button
          className='btn btn-primary btn-xs lg:btn-sm sm:btn-solid'
          type='button'
          onClick={handleSubmit}
          disabled={!canSubmit || !isAddressEditModeActivated}
        >
          {t('save')}
        </button>
      </div>
    </div>
  );
};

export default ProfileAddressForm;
