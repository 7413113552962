export function download(url: string) {
  const link = document.createElement('a');
  link.href = url;

  let browser = '';
  const browserAgent = navigator.userAgent.toLocaleLowerCase();

  if (browserAgent.indexOf('safari') !== -1 && browserAgent.indexOf('chrome') === -1)
    browser = 'safari';
  if (browserAgent.indexOf('firefox') !== -1) browser = 'firefox';

  if (browser !== 'safari' && browser !== 'firefox') link.setAttribute('target', `_blank`);

  document.body.appendChild(link);
  link.click();
}
