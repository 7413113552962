import axios, { AxiosInstance } from 'axios';
import { createContext, useContext, useState } from 'react';

const PublicApiAxiosContext = createContext<AxiosInstance>(axios);

const baseURL = import.meta.env.VITE_API_PUBLIC_DOMAIN as string;

export const PublicApiAxiosProvider: React.FC = ({ children }) => {
  const [value] = useState(() => axios.create({ baseURL }));
  return <PublicApiAxiosContext.Provider value={value}>{children}</PublicApiAxiosContext.Provider>;
};

export const usePublicApiAxios = () => useContext(PublicApiAxiosContext);
