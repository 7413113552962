const IconChat = ({ width = 24, height = 24 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox={'0 0 24 24'}
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M7.13774 19.566C9.00874 20.7698 11.2588 21.2394 13.4551 20.8844C15.6514 20.5294 17.6389 19.3749 19.0354 17.6429C20.4319 15.9109 21.1387 13.7238 21.0199 11.5021C20.9011 9.28049 19.9651 7.1812 18.3919 5.60801C16.8187 4.03481 14.7194 3.09882 12.4978 2.98003C10.2761 2.86125 8.089 3.56806 6.35703 4.96453C4.62506 6.361 3.47055 8.34852 3.11555 10.5448C2.76054 12.7412 3.23011 14.9912 4.43394 16.8622'
        stroke='#1E2044'
        strokeWidth='1.8'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M4.43374 16.8622L4.00684 19.4174C3.99372 19.4959 3.99954 19.5764 4.02382 19.6523C4.04811 19.7281 4.09015 19.797 4.14645 19.8533C4.20275 19.9096 4.27168 19.9517 4.34751 19.9759C4.42333 20.0002 4.50386 20.006 4.5824 19.9929L7.1374 19.5659'
        stroke='#1E2044'
        strokeWidth='1.8'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M14.9961 12L15.0011 11.995L15.0061 12L15.0011 12.005L14.9961 12Z'
        stroke='#F45B69'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M11.9961 12L12.0011 11.995L12.0061 12L12.0011 12.005L11.9961 12Z'
        stroke='#F45B69'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M8.99609 12L9.00109 11.995L9.00609 12L9.00109 12.005L8.99609 12Z'
        stroke='#F45B69'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export default IconChat;
