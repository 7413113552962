const IconCreditCard = ({ width = 16, height = 16 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox={'0 0 16 16'}
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M2 6H14'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M6.3813 8.66663H4.66797'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M12 12.6666H4C2.89533 12.6666 2 11.7713 2 10.6666V5.33331C2 4.22865 2.89533 3.33331 4 3.33331H12C13.1047 3.33331 14 4.22865 14 5.33331V10.6666C14 11.7713 13.1047 12.6666 12 12.6666Z'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export default IconCreditCard;
