export const minPasswordLength = 6;

export enum AntecedentType {
  WITH_ANTECEDENT = 'avec_antecedent',
  WITHOUT_ANTECEDENT = 'sans_antecedent',
}

export enum Formule {
  TIERS = 'tiers',
  VOL_ICENDIE = 'vol_incendie',
  TOUS_RISQUES = 'tous_risques',
}

export enum QuoteGaranties {
  Assistance = 'Assistance 0 km',
  Responsabilite = 'Responsabilité civile',
  Defense = 'Défense et recours',
  Garantie = 'Garantie du conducteur',
  BrisGlace = 'Bris de glace',
  Vol = 'Vol, incendie, explosion',
  Tempetes = 'Tempêtes et intempéries',
  CatNaturelles = 'Catastrophes naturelles',
  Accidents = 'Dommages tous accidents',
}

export type Garantie = {
  key: QuoteGaranties;
  enabled: Formule[];
};
export const garanties: Garantie[] = [
  {
    key: QuoteGaranties.Assistance,
    enabled: [Formule.TIERS, Formule.VOL_ICENDIE, Formule.TOUS_RISQUES],
  },
  {
    key: QuoteGaranties.Responsabilite,
    enabled: [Formule.TIERS, Formule.VOL_ICENDIE, Formule.TOUS_RISQUES],
  },
  {
    key: QuoteGaranties.Defense,
    enabled: [Formule.TIERS, Formule.VOL_ICENDIE, Formule.TOUS_RISQUES],
  },
  {
    key: QuoteGaranties.Garantie,
    enabled: [Formule.TIERS, Formule.VOL_ICENDIE, Formule.TOUS_RISQUES],
  },
  {
    key: QuoteGaranties.BrisGlace,
    enabled: [Formule.VOL_ICENDIE, Formule.TOUS_RISQUES],
  },
  {
    key: QuoteGaranties.Vol,
    enabled: [Formule.VOL_ICENDIE, Formule.TOUS_RISQUES],
  },
  {
    key: QuoteGaranties.Tempetes,
    enabled: [Formule.VOL_ICENDIE, Formule.TOUS_RISQUES],
  },
  {
    key: QuoteGaranties.CatNaturelles,
    enabled: [Formule.VOL_ICENDIE, Formule.TOUS_RISQUES],
  },
  {
    key: QuoteGaranties.Accidents,
    enabled: [Formule.TOUS_RISQUES],
  },
];

export const imgSrcFromGarantie: Record<QuoteGaranties, string> = {
  [QuoteGaranties.Assistance]: '/img/truck.svg',
  [QuoteGaranties.Responsabilite]: '/img/health.svg',
  [QuoteGaranties.Defense]: '/img/defense.svg',
  [QuoteGaranties.Garantie]: '/img/seatBelt.svg',
  [QuoteGaranties.BrisGlace]: '/img/brokenGlass.svg',
  [QuoteGaranties.Vol]: '/img/fire.svg',
  [QuoteGaranties.Tempetes]: '/img/weather.svg',
  [QuoteGaranties.CatNaturelles]: '/img/storm.svg',
  [QuoteGaranties.Accidents]: '/img/accidentSingle.svg',
};

export enum BillingMode {
  Monthly = 'mensuel',
  Yearly = 'annuel',
}

export enum PaymentMethodType {
  Sepa = 'sepa_debit',
  Card = 'card',
}

export enum ForfaitAdditionType {
  Forfait = 'forfait',
  Recharge = 'recharge',
}

export const StripeIbanSupportedContries = ['SEPA'];

export const insuranceProvidersList = [
  'ACM (Ass. Crédit Mutuel du Nord)',
  'ACM (Ass. Crédit Mutuel)',
  'ACM (Ex. Serenis)',
  'AcommeAssure.com',
  'Acte IARD',
  'Active Assurances',
  "Affinéo'Assur",
  'AG2R La Mondiale',
  'AGPM Assurances',
  'AIG Europe Limited',
  'Allianz IARD',
  'Allsecur',
  'ALOA',
  'ALTIMA Assurances',
  'Amaguiz',
  'Amaline Assurances',
  'AMF Assurances',
  'AMV',
  'AON Assurances',
  'Apivia Mutuelle',
  'April',
  'April Partenaires',
  'Aréas Dommages',
  'ASSU 2000',
  'Assur One Group',
  'Assurance Unie',
  'Assurances Mutuelles de Picardie',
  'Assuravenue',
  'Assuréo',
  'ASSURIMA',
  'Assurimo',
  'Assuronline',
  'Assurpeople',
  'Autofirst',
  'AVANSSUR',
  'Aviva Assurances',
  'AXA France IARD',
  'AXA Partenaires',
  'Axeria IARD',
  'Banque Populaire (BPCE)',
  'BNP Paribas Cardif IARD',
  'BRED Assurances',
  "Caisse d'Epargne (BPCE)",
  'Caisse Mut. Marnaise Assurance',
  'Calypso',
  'CAM BTP',
  'Camca',
  'Carrefour Banque (CARMA)',
  'CAT Assurances',
  'CIC (Ex. Serenis)',
  'CMAM (Caisse Meusienne Ass. Mut.)',
  'Covéa Fleet',
  'Covéa Risks',
  'Crédit Agricole (PACIFICA)',
  'Diot Méditerrannée',
  'Direct Assurance',
  'ECA Assurances',
  'Equité',
  'Euralpha Assurance',
  'Euro Assurance (Assu 2000)',
  'Eurofil',
  'Fidelidade',
  'Filia MAIF',
  'Finistère Assurance',
  'Fortuneo - Symphonis',
  'Gan Assurances',
  'Gan Eurocourtage',
  'Generali Belgium',
  'Generali IARD',
  'GMF Assurances',
  'Groupama Centre Atlantique',
  'Groupama Centre Manche',
  "Groupama d'Oc",
  'Groupama Grand Est',
  'Groupama Loire Bretagne',
  'Groupama Mediterranée',
  'Groupama Nord Est',
  'Groupama Paris Val de Loire',
  'Groupama Rhône Alpes Auvergne',
  'HSBC Assurances',
  'INSURED SERVICES',
  'Interassurances',
  'La Banque Postale Assurances',
  'La Médicale de France',
  'LCL (PACIFICA)',
  'Lemonade Insurance',
  'LIBEA',
  'MAAF Assurances',
  'MACIF',
  'MACIFILIA',
  'MACSF',
  'MAE',
  'MAIF',
  'MALJ - Mutuelle Alsace Lorraine Jura',
  'MAPA Assurances',
  'Matmut',
  'MMA',
  'Monceau Générale Assurances',
  'Mutuelle de Poitiers',
  "Mutuelle Fraternelle d'Assurance",
  'Mutuelle Saint-Christophe assurances',
  'Natio Assurances',
  'Netvox',
  'Novelia',
  'Pro BTP',
  'SADA Assurances',
  'SMA',
  'SMACL Assurances',
  'Société Générale (Sogessur)',
  'Solly Azar',
  'Suravenir Assurances',
  'SwissLife Assurances',
  'Thelem Assurances',
  'Verspieren SA',
  'Zéphir',
];

export const toastOptions = {
  duration: 5000,
  style: {
    padding: '15px',
    color: '#fff',
    fontWeight: 700,
    fontSize: '14px',
    width: '100%',
    maxWidth: '450px',
  },
  success: {
    style: {
      background: '#039855',
    },
    iconTheme: {
      primary: '#fff',
      secondary: '#039855',
    },
  },
  error: {
    style: {
      background: '#f04438',
    },
    iconTheme: {
      primary: '#fff',
      secondary: '#f04438',
    },
  },
};

// use this to check the whole password
export const passwordCheckRegex =
  /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[~"'.:;,§€#?!@$%^&*\-+=(){}[\]_°]).{8,}$/;
// use these to check each rule independently
export const UpperCaseRegex = /^(?=.*?[A-Z]).{1,}$/;
export const LowerCaseRegex = /^(?=.*?[a-z]).{1,}$/;
export const NumericRegex = /^(?=.*?[0-9]).{1,}$/;
export const SpecialCharRegex = /^(?=.*?[~"'.:;,§€#?!@$%^&*\-+=(){}[\]_°]).{1,}$/;
