import toast from 'react-hot-toast';
import { useMutation } from 'react-query';

import { paths } from '@/../generated/publicApi';
import { useDashboardState } from '@/context/DashBoard';

import { usePublicApiAxios } from '../../../context/PublicApiAxios';

const usageUpdateDriverPath = 'api/v1/dashboard/driver';
export type UpdateDriver =
  paths['/api/v1/dashboard/driver']['put']['requestBody']['content']['application/json'];
type UpdateDriverResult =
  paths['/api/v1/dashboard/driver']['put']['responses']['200']['content']['application/json'];

const useUpdateDriver = () => {
  const axios = usePublicApiAxios();
  const [{ policyData }, setDashboard] = useDashboardState();

  const updateDriver = async (driver_in: UpdateDriver) => {
    const driver: UpdateDriver = {
      id: policyData?.driver_out.id,
      ...driver_in,
    };
    const { data } = await axios.put<UpdateDriverResult>(usageUpdateDriverPath, driver);
    return data;
  };

  return useMutation(updateDriver, {
    onSuccess: async (data) => {
      toast.success('Informations sauvegardées!');

      setDashboard((previousDashboard) => {
        if (previousDashboard && previousDashboard.policyData) {
          return {
            ...previousDashboard,
            policyData: {
              ...previousDashboard.policyData,
              driver_out: {
                ...previousDashboard.policyData?.driver_out,
                qt_postal_code: data.qt_postal_code,
                qt_line1: data.qt_line1,
                qt_city: data.qt_city,
                qt_line2: data.qt_line2,
              },
            },
          };
        }
        return previousDashboard;
      });
    },
    onError: (arg) => {
      toast.error("Une erreur s'est produite lors de la mis à jour de votre profil.");
    },
  });
};

export default useUpdateDriver;
