import { QuoteResult } from '@hooks/api/useQuote';
import React, { createContext, useContext } from 'react';

import { CreatePolicyResult } from '@/hooks/api/useCreatePolicy';
import { DriverResult } from '@/hooks/api/useDriver';
import { InvoiceResult } from '@/hooks/api/useInvoice';
import { PolicyResult } from '@/hooks/api/usePolicy';
import { QuotePricingResult } from '@/hooks/api/useQuotePricing';
import { GetSubscriptionResult } from '@/hooks/api/useRetrieveSub';

import useLocalStorage from '../hooks/useLocalStorage';

export type SubscriptionInput = {
  policy: CreatePolicyResult | null;
  policy_driver: PolicyResult | null;
  quote: QuoteResult | null;
  quote_pricing: QuotePricingResult | null;
  embedded_url: string | undefined;
  valid_ts: number | undefined;
  invoice: InvoiceResult | undefined;
  subscription: GetSubscriptionResult | undefined;
  driver: DriverResult | null;
  document_status: boolean | null;
};

const storageKey = 'subscriptionState';
const subscriptionStateObject =
  localStorage.getItem(storageKey) === null
    ? '{"document_status": false}'
    : (localStorage.getItem(storageKey) as string);

export const initialSubscriptionState: SubscriptionInput = {
  policy: null,
  policy_driver: null,
  quote: null,
  quote_pricing: null,
  embedded_url: undefined,
  valid_ts: undefined,
  invoice: undefined,
  subscription: undefined,
  driver: null,
  //document_status: true
  document_status: JSON.parse(subscriptionStateObject).document_status,
};

type SubscriptionStateContextType = readonly [
  SubscriptionInput,
  (value: SubscriptionInput | ((val: SubscriptionInput) => SubscriptionInput)) => void
];
export const SubscriptionStateContext = createContext<SubscriptionStateContextType>([
  initialSubscriptionState,
  () => {},
]);

export const SubscriptionStateProvider: React.FC = ({ children }) => {
  const [subscriptionState, setSubscriptionState] = useLocalStorage<SubscriptionInput>(
    storageKey,
    initialSubscriptionState
  );

  const setSubscriptionStateWithSegment = (
    subscription: SubscriptionInput | ((q: SubscriptionInput) => SubscriptionInput)
  ) => {
    let subscriptionValue;
    if (typeof subscription === 'function') subscriptionValue = subscription(subscriptionState);
    else subscriptionValue = subscription;

    const segment_event_name = window.location.href.split('?')[0].split('/')[
      window.location.href.split('?')[0].split('/').length - 1
    ];
    window.analytics.track(`quote_${segment_event_name}`, {
      //lead_id: getStoredValue('ajs_anonymous_id', '')(),
      ...subscriptionValue.policy,
      ...subscriptionValue.policy_driver,
      ...subscriptionValue.quote,
      ...subscriptionValue.quote_pricing,
      embedded_url: subscriptionValue.embedded_url,
      valid_ts: subscriptionValue.valid_ts,
      ...subscriptionValue.invoice,
      ...subscriptionValue.subscription,
      ...subscriptionValue.driver,
      ts: new Date().toISOString(),
    });

    setSubscriptionState(subscriptionValue);
  };
  return (
    <SubscriptionStateContext.Provider value={[subscriptionState, setSubscriptionStateWithSegment]}>
      {children}
    </SubscriptionStateContext.Provider>
  );
};

export const useSubscriptionState = () => useContext(SubscriptionStateContext);
