let trackingUrl = '';

if (typeof window !== 'undefined') {
  const urlParams = new URLSearchParams(window.location.search);
  if (window.location.href.indexOf('utm_source') > -1)
    trackingUrl += '?utm_source=' + urlParams.get('utm_source');

  if (window.location.href.indexOf('utm_campaign') > -1)
    trackingUrl += '&utm_campaign=' + urlParams.get('utm_campaign');

  if (window.location.href.indexOf('utm_content') > -1)
    trackingUrl += '&utm_content=' + urlParams.get('utm_content');

  if (window.location.href.indexOf('utm_terms') > -1)
    trackingUrl += '&utm_terms=' + urlParams.get('utm_terms');

  if (window.location.href.indexOf('utm_medium') > -1)
    trackingUrl += '&utm_medium=' + urlParams.get('utm_medium');

  if (window.location.href.indexOf('hsa_acc') > -1)
    trackingUrl += '&hsa_acc=' + urlParams.get('hsa_acc');

  if (window.location.href.indexOf('hsa_cam') > -1)
    trackingUrl += '&hsa_cam=' + urlParams.get('hsa_cam');

  if (window.location.href.indexOf('hsa_grp') > -1)
    trackingUrl += '&hsa_grp=' + urlParams.get('hsa_grp');

  if (window.location.href.indexOf('hsa_ad') > -1)
    trackingUrl += '&hsa_ad=' + urlParams.get('hsa_ad');

  if (window.location.href.indexOf('hsa_src') > -1)
    trackingUrl += '&hsa_src=' + urlParams.get('hsa_src');

  if (window.location.href.indexOf('hsa_net') > -1)
    trackingUrl += '&hsa_net=' + urlParams.get('hsa_net');

  if (window.location.href.indexOf('hsa_ver') > -1)
    trackingUrl += '&hsa_ver=' + urlParams.get('hsa_ver');

  if (window.location.href.indexOf('hsa_tgt') > -1)
    trackingUrl += '&hsa_tgt=' + urlParams.get('hsa_tgt');

  if (window.location.href.indexOf('fbclid') > -1)
    trackingUrl += '&fbclid=' + urlParams.get('fbclid');

  if (window.location.href.indexOf('gclid') > -1) trackingUrl += '&gclid=' + urlParams.get('gclid');

  if (window.location.href.indexOf('partner_quote_id') > -1) {
    if (trackingUrl === '') trackingUrl += '?partner_quote_id=' + urlParams.get('partner_quote_id');
    else trackingUrl += '&partner_quote_id=' + urlParams.get('partner_quote_id');
  }
}

export const trackingUrlFinal = trackingUrl;
export const urlParamConcat = trackingUrl === '' ? '?' : '&';

const routes = {
  ROOT: '/',
  // free routes
  SIGNIN: '/signin',
  SIGNUP: '/signup',
  RESETPASSWORDINVITE: '/resetPasswordInvite',
  RESETPASSWORD: '/resetPassword',
  SUBSCRIBE: '/subscribe',
  PICTURE: '/picture',
  HELLOSIGN: '/hellosign',
  // login protected routes
  HOME: '/home',
  PAYMENTS: '/payments',
  ADVANTAGES: '/advantages',
  CONTACT: '/contact',
  PROFILE: '/profile',
  FORFAIT: '/forfait',
  GARANTIES: '/garanties',
  DOCUMENTS: '/documents',
  FACTURES: '/factures',
  SINISTRES: '/sinistres',
  PAYMENT: '/payment',
};

export default routes;
