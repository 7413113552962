import { useQuery } from 'react-query';

import { useAuth } from '@/context/Auth';
import { useDashboardState } from '@/context/DashBoard';

import { paths } from '../../../../generated/publicApi';
import { usePublicApiAxios } from '../../../context/PublicApiAxios';

const paymentMethodDataPath = '/api/v1/dashboard/stripe/payment-methods';

export type PaymentMethodDataQuery =
  paths['/api/v1/dashboard/stripe/payment-methods']['get']['parameters']['query'];
export type PaymentMethodDataResult =
  paths['/api/v1/dashboard/stripe/payment-methods']['get']['responses']['200']['content']['application/json'];

const usePaymentMethodData = ({ policy_id }: PaymentMethodDataQuery) => {
  const axios = usePublicApiAxios();
  const [dashboardState, setDashboardState] = useDashboardState();
  const [{ token }] = useAuth();

  const getPaymentMethodData = async () => {
    const { data } = await axios.get<PaymentMethodDataResult>(paymentMethodDataPath, {
      params: { policy_id },
      headers: { Authorization: `Bearer ${token}` },
    });
    setDashboardState({
      ...dashboardState,
      paymentMethodData: data,
    });
    return data;
  };

  return useQuery(paymentMethodDataPath, getPaymentMethodData);
};

export default usePaymentMethodData;
