import { useQuery } from 'react-query';

import { useDashboardState } from '@/context/DashBoard';

import { paths } from '../../../../generated/publicApi';
import { usePublicApiAxios } from '../../../context/PublicApiAxios';

const cacheOptions = {
  staleTime: Infinity,
  refetchOnWindowFocus: false,
  cacheTime: Infinity,
  refetchOnMount: false,
};

const allInvoicePath = '/api/v1/dashboard/invoices';

export type allInvoiceQuery = paths['/api/v1/dashboard/invoices']['get']['parameters']['query'];
export type allInvoiceResult =
  paths['/api/v1/dashboard/invoices']['get']['responses']['200']['content']['application/json'];

const useGetInvoices = (query: allInvoiceQuery) => {
  const axios = usePublicApiAxios();

  const getAllInvoice = async () => {
    const { data } = await axios.get<allInvoiceResult>(allInvoicePath, { params: query });
    return data;
  };

  return useQuery(allInvoicePath, getAllInvoice, cacheOptions);
};

export default useGetInvoices;
