import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';

interface HeadTitleProps {
  titleKey: string;
}

/**
 * sets the title of the page
 */
const HeadTitle = ({ titleKey }: HeadTitleProps) => {
  const { t } = useTranslation();
  return (
    <Helmet>
      <title>{t('pageTitle', { title: t(titleKey) })}</title>
    </Helmet>
  );
};

export default HeadTitle;
