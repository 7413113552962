import { IbanElement } from '@stripe/react-stripe-js';
import { Dispatch, FormEventHandler, SetStateAction, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { StripeIbanSupportedContries } from '@/components/utils/constants';
import { useDashboardState } from '@/context/DashBoard';
import useSetupIntent from '@/hooks/api/dashboard/useSetupIntent';
import { inputOptions } from '@/services/stripe';

interface PaymentMethodFormProps {
  onClose: () => void;
  setDisplayRib: Dispatch<SetStateAction<boolean>>;
}

const PaymentMethodForm = ({ onClose, setDisplayRib }: PaymentMethodFormProps) => {
  const { t } = useTranslation();
  const { mutate: createSetupIntent, isSuccess } = useSetupIntent();
  const [{ policyData }] = useDashboardState();
  const [isLoading, setIsLoading] = useState(false);

  const onSubmit: FormEventHandler = (e) => {
    e.preventDefault();
    setIsLoading(true);
    createSetupIntent({
      policy_id: Number(policyData?.policy_out.id),
      payment_method_type: 'sepa_debit',
    });
  };

  if (isSuccess) {
    onClose();
    setDisplayRib(false);
  }

  return (
    <form onSubmit={onSubmit} className='space-y-6'>
      <div>
        <span className='text-xs font-bold text-gray-500'>IBAN</span>
        <IbanElement
          options={{ ...inputOptions, supportedCountries: StripeIbanSupportedContries }}
          className='p-3 text-sm text-gray-800 rounded-lg border border-gray-100'
        />
      </div>
      {/*
        <div className='flex items-center mb-8'>
          <Checkbox id={checkId} checked={defaultChecked} onChange={onChangeDefaultChecked} />
          <label className='pl-3 text-sm text-gray-600 cursor-pointer' htmlFor={checkId}>
            {t('ribPanel.useNewAsDefault')}
          </label>
        </div>
        */}
      <button
        type='submit'
        className='btn btn-primary btn-xs lg:btn-sm btn-fluid'
        disabled={isLoading}
      >
        {t('ribPanel.submitRib')}
      </button>
    </form>
  );
};

export default PaymentMethodForm;
