import React from 'react';

const WhiteCard: React.FC<{ thin?: boolean }> = ({ children, thin }) => {
  return (
    <div
      className={
        ' bg-white rounded-xl shadow-sm  ' +
        (thin ? '' /*'py-2'*/ : '') /*'py-6 md:py-8 px-4 sm:px-8'*/
      }
    >
      {children}
    </div>
  );
};

export default WhiteCard;
