import { Email, Lock, LottieAnimation, PasswordField, TextField } from '@flitter-insurance/nessui';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, Redirect } from 'react-router-dom';

import { useAuth } from '@/context/Auth';
import useSignInApi from '@/hooks/auth/useSignIn';
import routes from '@/routes';

import SignInLayout from '../../layouts/SignInLayout';
import HeadTitle from '../../utils/HeadTitle';

const useSignIn = () => {
  const { t } = useTranslation();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [emailError, setEmailError] = useState<string>();
  const [passwordError, setPasswordError] = useState<string>();

  const onSignIn = useSignInApi();

  useEffect(() => {
    localStorage.removeItem('dashboardState');
    localStorage.removeItem('subscriptionState');
    localStorage.removeItem('authState');
  }, []);

  const onLogin: React.FormEventHandler = (e) => {
    e.preventDefault();

    setEmailError('');
    setPasswordError('');

    if (email.length === 0) setEmailError(t('signIn.error.AUTH03'));
    //if (email.length > 0 && !regEmail.test(email)) setEmailError(t('signIn.error.AUTH05'));
    if (password.length === 0) setPasswordError(t('signIn.error.AUTH04'));

    if (email.length > 0 && password.length > 0 /* && regEmail.test(email) */) {
      setIsLoading(true);

      const data = onSignIn(email, password);
      data.catch((res) => {
        const errorCode = res.response.data.detail.payload;
        setPasswordError(t('signIn.error.' + errorCode));
        setIsLoading(false);
      });
    } else return null;
  };

  const onChangeEmail: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    setEmail(e.currentTarget.value);
  };

  const onChangePassword: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    setPassword(e.currentTarget.value);
  };

  return {
    onLogin,
    email,
    onChangeEmail,
    password,
    onChangePassword,
    emailError,
    passwordError,
    isLoading,
  };
};

const SignIn = () => {
  const { t } = useTranslation();
  const [{ isLogged, isReady }] = useAuth();
  const {
    email,
    onChangeEmail,
    onLogin,
    password,
    onChangePassword,
    emailError,
    passwordError,
    isLoading,
  } = useSignIn();

  if (!isReady) return null;

  if (isLogged) {
    const to = routes.HOME;
    return <Redirect to={to} />;
  }

  return (
    <SignInLayout>
      <HeadTitle titleKey='signIn.header' />
      <h1 className='mb-10 text-center'>{t('signIn.header')}</h1>
      <form className='space-y-6' onSubmit={onLogin}>
        <TextField
          value={email}
          onChange={onChangeEmail}
          type='email'
          name='email'
          leftIcon={<Email size={24} />}
          autoComplete='username'
          error={emailError}
          placeholder={t('signIn.emailPlaceholder')}
          label={t('signIn.emailLabel')}
        />
        <PasswordField
          value={password}
          onChange={onChangePassword}
          name='password'
          leftIcon={<Lock size={24} />}
          autoComplete='current-password'
          error={passwordError}
          placeholder={t('signIn.pwdPlaceholder')}
          label={t('signIn.pwdLabel')}
        />
        <div className='grid grid-cols-2 grid-flow-col gap-2'>
          <Link
            to={routes.RESETPASSWORDINVITE}
            className='col-span-1 pt-1 sm:pt-3 text-xs font-bold text-gray-800'
          >
            {t('signIn.forgotPasswordLink')}
          </Link>
          <div className='text-right'>
            <button className='col-span-1 max-w-max btn btn-primary btn-xs lg:btn-sm' type='submit'>
              {isLoading ? <LottieAnimation type='white-dots' /> : t('signIn.confirmBtn')}
            </button>
          </div>
        </div>
      </form>
    </SignInLayout>
  );
};

export default SignIn;
