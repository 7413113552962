import { Logout, Silhouette } from '@flitter-insurance/nessui';
import { Menu, Transition } from '@headlessui/react';
import { FC, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import IconUser from '@/assets/svg/IconUser';
import { useAuth } from '@/context/Auth';
import routes from '@/routes';

const UserDropdown: FC = ({ children }) => {
  const { t } = useTranslation();
  const [, setAuthState] = useAuth();
  const signOut = () => {
    setAuthState((auth) => ({
      ...auth,
      isLogged: false,
      isReady: true,
      token: undefined,
    }));
  };
  return (
    <Menu as='div' className='relative sm:p-2 xl:p-3 bg-white rounded-md lg:border border-gray-100'>
      <div className='flex items-center text-sm'>
        <span className='hidden lg:flex'>
          <IconUser width={16} height={16} /> &nbsp;&nbsp;
        </span>{' '}
        <Menu.Button>{children}</Menu.Button>
      </div>
      <Transition
        as={Fragment}
        enter='transition ease-out duration-100'
        enterFrom='transform opacity-0 scale-95'
        enterTo='transform opacity-100 scale-100'
        leave='transition ease-in duration-75'
        leaveFrom='transform opacity-100 scale-100'
        leaveTo='transform opacity-0 scale-95'
      >
        <Menu.Items className='absolute right-0 p-4 mt-5 w-60 text-gray-900 bg-white rounded-xl divide-y divide-gray-50 shadow-lg origin-top-right focus:outline-none'>
          <Menu.Item>
            <Link
              to={routes.PROFILE}
              className='flex items-center px-4 space-x-3 w-full h-11 text-xs text-gray-600'
            >
              <Silhouette size={18} />
              <span>{t('nav.profile')}</span>
            </Link>
          </Menu.Item>
          <Menu.Item>
            <button
              className='flex items-center px-4 pt-3 space-x-3 w-full h-11 text-xs text-gray-600'
              onClick={signOut}
            >
              <Logout size={18} />
              <span>{t('nav.logout')}</span>
            </button>
          </Menu.Item>
        </Menu.Items>
      </Transition>
    </Menu>
  );
};

export default UserDropdown;
