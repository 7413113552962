import toast from 'react-hot-toast';
import { useMutation } from 'react-query';

import { paths } from '@/../generated/publicApi';
import { useAuth } from '@/context/Auth';
import { useDashboardState } from '@/context/DashBoard';

import { usePublicApiAxios } from '../../../context/PublicApiAxios';
const uploadFilePath = '/api/v1/dashboard/upload_file';
export type UploadFileQuery = paths['/api/v1/dashboard/upload_file']['post']['parameters']['query'];
export type UploadFileBody =
  paths['/api/v1/dashboard/upload_file']['post']['requestBody']['content']['multipart/form-data'];

export type UploadFileResult =
  paths['/api/v1/dashboard/upload_file']['post']['responses']['200']['content']['application/json'];

const useUploadFile = () => {
  const axios = usePublicApiAxios();
  const [, setDashboardState] = useDashboardState();
  const [{ token }] = useAuth();

  const uploadFile = async ({
    params,
    file,
  }: {
    params: UploadFileQuery;
    file: FileList | null;
  }) => {
    const formData = new FormData();
    if (!file) return null;

    if (params.file_name === null) return;

    formData.append('file', file[0]);
    const { data } = await axios.post(uploadFilePath, formData, {
      params,
      headers: { Authorization: `Bearer ${token}` },
    });
    return data;
  };

  return useMutation(uploadFilePath, uploadFile, {
    onSuccess: (data, { params: { file_name }, file }) => {
      if (file) {
        switch (file_name) {
          case 'permis-recto':
            setDashboardState((previousDashboard) => ({
              ...previousDashboard,
              permisRecto: {
                ...previousDashboard.permisRecto,
                uploaded: true,
                name: file![0].name,
              },
            }));
            break;
          case 'permis-verso':
            setDashboardState((previousDashboard) => ({
              ...previousDashboard,
              permisVerso: {
                ...previousDashboard.permisRecto,
                uploaded: true,
                name: file![0].name,
              },
            }));
            break;
          case 'releve-information':
            setDashboardState((previousDashboard) => ({
              ...previousDashboard,
              releveInformation: {
                ...previousDashboard.releveInformation,
                uploaded: true,
                name: file![0].name,
              },
            }));
            break;
          case 'compteur-kilometrique':
            setDashboardState((previousDashboard) => ({
              ...previousDashboard,
              compteurKilometrique: {
                ...previousDashboard.compteurKilometrique,
                uploaded: true,
                name: file![0].name,
              },
            }));
            break;
          case 'carte-grise':
            setDashboardState((previousDashboard) => ({
              ...previousDashboard,
              cartegrise: {
                ...previousDashboard.cartegrise,
                uploaded: true,
                name: file![0].name,
              },
            }));
            break;
          case 'permis-recto-second-conducteur':
            setDashboardState((previousDashboard) => ({
              ...previousDashboard,
              permisSecondDriverRecto: {
                ...previousDashboard.permisSecondDriverRecto,
                uploaded: true,
                name: file![0].name,
              },
            }));
            break;
          case 'permis-verso-second-conducteur':
            setDashboardState((previousDashboard) => ({
              ...previousDashboard,
              permisSecondDriverVerso: {
                ...previousDashboard.permisSecondDriverVerso,
                uploaded: true,
                name: file![0].name,
              },
            }));
            break;
          default:
            break;
        }
      }
    },
    onError: (error) => {
      console.log(error);
      toast.error("Une erreur s'est produite lors lors du téléchargement du fichier.");
    },
  });
};

export default useUploadFile;
