import { useMutation } from 'react-query';

import { paths } from '@/../generated/publicApi';
import { useAuth } from '@/context/Auth';
import { download } from '@/hooks/useDownloadFile';

import { usePublicApiAxios } from '../../../context/PublicApiAxios';

const downloadFilePath = '/api/v1/dashboard/download_file';
export type DownloadFileQuery =
  paths['/api/v1/dashboard/download_file']['post']['parameters']['query'];
export type DownloadFileResult =
  paths['/api/v1/dashboard/download_file']['post']['responses']['200']['content']['application/json'];

const useDownloadFile = () => {
  const axios = usePublicApiAxios();
  const [{ token }] = useAuth();

  const downloadFile = async ({ params }: { params: DownloadFileQuery }) => {
    const { data } = await axios.post(
      downloadFilePath,
      {},
      {
        params,
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return data;
  };

  return useMutation(downloadFilePath, downloadFile, {
    onSuccess: (data) => {
      download(data.url);
    },
    onError: (error) => {
      console.log(error);
    },
  });
};

export default useDownloadFile;
