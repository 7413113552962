import { useCallback } from 'react';

import { useAuth } from '@/context/Auth';
import { useDashboardState } from '@/context/DashBoard';
import { usePublicApiAxios } from '@/context/PublicApiAxios';
import { useSubscriptionState } from '@/context/Subscription';

import { DocumentDataResult } from '../api/dashboard/useDocument';
import { PaymentMethodDataResult } from '../api/dashboard/usePaymentMethods';
import { PolicyDataQuery, PolicyDataResult } from '../api/dashboard/usePolicyData';

const policyDataPath = '/api/v1/dashboard/policy';
const documentDataPath = '/api/v1/dashboard/uploaded_document';
const paymentMethodDataPath = '/api/v1/dashboard/stripe/payment-methods';
import { paths } from '../../../generated/publicApi';

const signInPath = '/api/v1/authentication/signin';
type SignInRequest = paths['/api/v1/authentication/signin']['post'];
type SignInQuery = SignInRequest['parameters']['query'];
type SignInResponse = SignInRequest['responses']['200']['content']['application/json'];

const useSignInApi = () => {
  const axios = usePublicApiAxios();
  const [, setAuthState] = useAuth();
  const [, setSubcriptionState] = useSubscriptionState();
  const [, setDashBoardState] = useDashboardState();
  return useCallback(
    async (email: string, password: string) => {
      // get the customtoken from the public api
      const params: SignInQuery = { email, password };
      const {
        data: { access_token, driver_id },
      } = await axios.post<SignInResponse>(signInPath, {}, { params });

      const { data: policyData } = await axios.get<PolicyDataResult>(policyDataPath, {
        params: { driver_id },
        headers: { Authorization: `Bearer ${access_token}` },
      });

      const { data: documentData } = await axios.get<DocumentDataResult>(documentDataPath, {
        params: { driver_id },
        headers: { Authorization: `Bearer ${access_token}` },
      });

      const { data: paymentMethodData } = await axios.get<PaymentMethodDataResult>(
        paymentMethodDataPath,
        {
          params: { policy_id: policyData?.policy_out.id },
          headers: { Authorization: `Bearer ${access_token}` },
        }
      );
      if (!access_token) throw new Error('did not get custom token');

      if (policyData && documentData && paymentMethodData) {
        setAuthState((auth) => ({
          ...auth,
          isLogged: true,
          isReady: true,
          token: access_token,
          driver_id,
        }));

        // initialize Subscription State
        setSubcriptionState({
          policy: null,
          policy_driver: null,
          quote: null,
          quote_pricing: null,
          embedded_url: undefined,
          valid_ts: undefined,
          invoice: undefined,
          subscription: undefined,
          driver: null,
          document_status: null,
        });
        setDashBoardState((prevState) => ({
          ...prevState,
          policyData: policyData,
          documentData: documentData,
          paymentMethodData: paymentMethodData,
          cartegrise: {
            type: 'carte-grise',
            uploaded: documentData.carte_grise_out?.storage_path ? true : false,
            name: documentData.carte_grise_out?.local_path,
            validated: documentData.carte_grise_out?.status === 'valid',
          },
          releveInformation: {
            type: 'releve-information',
            uploaded: documentData.releve_information_out?.storage_path ? true : false,
            name: documentData.releve_information_out?.local_path,
            validated: documentData.releve_information_out?.status === 'valid',
          },
          permisRecto: {
            type: 'permis-recto',
            uploaded: documentData.driving_license_recto_out?.storage_path ? true : false,
            name: documentData.driving_license_recto_out?.local_path,
            validated: documentData.driving_license_recto_out?.status === 'valid',
          },
          permisVerso: {
            type: 'permis-verso',
            uploaded: documentData.driving_license_verso_out?.storage_path ? true : false,
            name: documentData.driving_license_verso_out?.local_path,
            validated: documentData.driving_license_verso_out?.status === 'valid',
          },
          permisSecondDriverRecto: {
            type: 'permis-recto',
            uploaded: documentData.driving_license_recto_second_out?.storage_path ? true : false,
            name: documentData.driving_license_recto_second_out?.local_path,
            validated: documentData.driving_license_recto_second_out?.status === 'valid',
          },
          permisSecondDriverVerso: {
            type: 'permis-verso',
            uploaded: documentData.driving_license_verso_second_out?.storage_path ? true : false,
            name: documentData.driving_license_verso_second_out?.local_path,
            validated: documentData.driving_license_verso_second_out?.status === 'valid',
          },
        }));
      }

      // // initialize Dashboard State
      // setDashBoardState({
      //   permisRecto: undefined,
      //   permisVerso: undefined,
      //   cartegrise: undefined,
      //   releveInformation: undefined,
      //   compteurKilometrique: undefined,
      //   policyData: null,
      //   documentData: undefined,
      //   paymentMethodData: undefined,
      // });
    },
    [axios, setAuthState]
  );
};

export default useSignInApi;
