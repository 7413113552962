import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';

import { paths } from '@/../generated/publicApi';
import { useDashboardState } from '@/context/DashBoard';
import { getCurrentUser } from '@/services/firebase';

import { usePublicApiAxios } from '../../../context/PublicApiAxios';

const cacheOptions = {
  staleTime: Infinity,
  refetchOnWindowFocus: false,
  cacheTime: Infinity,
  refetchOnMount: false,
};

const usageUpdateDriverPath = 'api/v1/authentication/update_password';
export type UpdatePassword =
  paths['/api/v1/authentication/update_password']['put']['requestBody']['content']['application/json'];
type UpdatePasswordResult =
  paths['/api/v1/authentication/update_password']['put']['responses']['200']['content']['application/json'];

const useUpdatePassword = () => {
  const { t } = useTranslation();
  const axios = usePublicApiAxios();
  const [{ policyData }] = useDashboardState();

  const updatePassword = async (newPassword: string) => {
    console.log('new pass', newPassword);
    const creds: UpdatePassword = {
      firebase_user_id: '',
      driver_id: policyData?.driver_out.id as number,
      password: newPassword,
    };
    const { data } = await axios.put<UpdatePasswordResult>(usageUpdateDriverPath, creds);
    return data;
  };

  return useMutation(updatePassword, {
    onSuccess: async (data) => {
      toast.success(t('profile.passwordForm.passwordSuccess'));
      // toast.success('Informations sauvegardées!');
    },
    onError: (arg) => {
      console.log('error on quote call', arg);
      toast.error("Une erreur s'est produite lors de la mis à jour de votre profil.");
    },
  });
};

export default useUpdatePassword;
