import '../../../assets/css/topNav.css';

import { ArrowRight, Check, FlitterLogoBlue } from '@flitter-insurance/nessui';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import routes from '@/routes';

const TopMenu = (props: any) => {
  const { t } = useTranslation();

  return (
    <>
      <div className='container flex mx-auto'>
        <div className='flex sm:hidden items-center ml-0 lg:ml-5 text-gray-400'>
          <a href={props.backRoute}>
            <ArrowRight size={24} className='transform rotate-180 cursor-pointer' />
          </a>
        </div>
        <div className='flex justify-center w-full sm:w-auto'>
          <Link to={routes.HOME}>
            <FlitterLogoBlue className='mr-2 sm:ml-0' />
          </Link>
        </div>
        <nav className='flex mx-auto space-x-6 lg:space-x-12'>
          <span
            className={
              'hidden sm:block leading-8 font-bodyMedium ' +
              (props.stepSelected === 1 ? 'text-gray-900' : '')
            }
          >
            {props.stepSelected > 1 ? (
              <Check width={24} height={24} />
            ) : (
              <span className='mr-3'>1.</span>
            )}
            {t('topNav.formule')}
          </span>
          <span
            className={
              'hidden sm:block leading-8 font-bodyMedium ' +
              (props.stepSelected === 2
                ? 'text-gray-900'
                : props.stepSelected < 2
                ? 'text-gray-300'
                : '')
            }
          >
            {props.stepSelected > 2 ? (
              <Check width={24} height={24} />
            ) : (
              <span className='mr-3'>2.</span>
            )}
            {t('topNav.recapitulatif')}
          </span>
          <span
            className={
              'hidden sm:block leading-8 font-bodyMedium ' +
              (props.stepSelected === 3
                ? 'text-gray-900'
                : props.stepSelected < 3
                ? 'text-gray-300'
                : '')
            }
          >
            {props.stepSelected > 3 ? (
              <Check width={24} height={24} />
            ) : (
              <span className='mr-3'>3.</span>
            )}
            {t('topNav.paiement')}
          </span>
          <span
            className={
              'hidden sm:block leading-8 font-bodyMedium ' +
              (props.stepSelected === 4
                ? 'text-gray-900'
                : props.stepSelected < 4
                ? 'text-gray-300'
                : '')
            }
          >
            {props.stepSelected > 4 ? (
              <Check width={24} height={24} />
            ) : (
              <span className='mr-3'>4.</span>
            )}
            {t('topNav.signature')}
          </span>
        </nav>
      </div>
      <div className='container hidden sm:block items-center mx-auto mt-5'>
        <div className='text-gray-400 cursor-pointer'>
          <a href={props.backRoute}>
            <ArrowRight size={24} className='inline transform rotate-180' />
            <span className='relative top-px ml-2 text-gray-400'>{t('payment.back')}</span>
          </a>
        </div>
      </div>
    </>
  );
};

export default TopMenu;
