import { useMutation } from 'react-query';

import { paths } from '@/../generated/publicApi';
import { useAuth } from '@/context/Auth';
import { download } from '@/hooks/useDownloadFile';

import { usePublicApiAxios } from '../../../context/PublicApiAxios';

const downloadInvoicePath = '/api/v1/dashboard/download_invoice';
export type DownloadInvoiceQuery =
  paths['/api/v1/dashboard/download_invoice']['post']['parameters']['query'];
export type DownloadInvoiceResult =
  paths['/api/v1/dashboard/download_invoice']['post']['responses']['200']['content']['application/json'];

const useDownloadInvoice = () => {
  const axios = usePublicApiAxios();
  const [{ token }] = useAuth();

  const downloadInvoice = async ({ params }: { params: DownloadInvoiceQuery }) => {
    const { data } = await axios.post(
      downloadInvoicePath,
      {},
      {
        params,
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return data;
  };

  return useMutation(downloadInvoicePath, downloadInvoice, {
    onSuccess: (data) => {
      download(data.url);
    },
    onError: (error) => {
      console.log(error);
    },
  });
};

export default useDownloadInvoice;
