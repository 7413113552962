import { CardCvcElementProps } from '@stripe/react-stripe-js';
import { Appearance, loadStripe } from '@stripe/stripe-js';

export const stripePromise = loadStripe(import.meta.env.VITE_STRIPE_PUBLISHABLE_KEY as string);

export const appearance: Appearance = {
  theme: 'stripe',
  variables: {
    spacingUnit: '4px',
    colorBackground: 'white',
    borderRadius: '6px',
    colorPrimary: '#F45B69',
    colorText: '#010038',
    colorDanger: '#B32318',
    fontSizeBase: '16px',
    colorTextPlaceholder: '#8F8F98',
    colorIcon: '#8F8F98',
  },
  rules: {
    '.Input': {
      border: '2px solid #DDDDDE',
    },
  },
};

export const inputOptions: CardCvcElementProps['options'] = {
  style: {
    base: {
      color: '#010038',
      fontSize: '16px',
      '::placeholder': {
        color: '#8F8F8F',
      },
    },
  },
};
