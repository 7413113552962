import { ModalSlider } from '@flitter-insurance/nessui';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import useResetPwdApi, { useGetDriverByTokenApi } from '@/hooks/auth/useResetPwd';
import routes from '@/routes';

import SignInLayout from '../../layouts/SignInLayout';
import PasswordFields from './_login/PasswordFields';

const useResetPwd = () => {
  const { t } = useTranslation();
  const [displayPopup, setDisplayPopup] = useState(false);
  const [error, setError] = useState<string>();

  const [pwd, setPwd] = useState('');
  const [confirmPwd, setConfirmPwd] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const [email, setEmail] = useState('...');
  const [driverId, setDriverId] = useState(0);
  const token = new URLSearchParams(location.search).get('token');

  const onGetDriverByTokenApi = useGetDriverByTokenApi();
  const onResetPwdApi = useResetPwdApi();

  useEffect(() => {
    if (token && token.length > 0)
      onGetDriverByTokenApi(token)
        .then((response) => {
          setEmail(response.data.email);
          setDriverId(response.data.driver_id);
        })
        .catch(() => {
          setError(t('resetPwd.error.general'));
          setIsLoading(false);
        });
    else setError(t('resetPwd.error.tokenError'));
  }, []);

  const onResetPwd = () => {
    if (driverId !== 0) {
      setIsLoading(true);
      onResetPwdApi(pwd, driverId)
        .then(() => {
          setDisplayPopup(true);
          setIsLoading(false);
        })
        .catch(() => {
          setError(t('resetPwd.error.pwdChangeError'));
          setIsLoading(false);
        });
    }
  };

  return {
    pwd,
    setPwd,
    confirmPwd,
    setConfirmPwd,
    isLoading,
    onResetPwd,
    displayPopup,
    setDisplayPopup,
    email,
    error,
    token,
  };
};

const ResetPassword = () => {
  const { t } = useTranslation();
  const {
    pwd,
    setPwd,
    confirmPwd,
    setConfirmPwd,
    isLoading,
    onResetPwd,
    displayPopup,
    setDisplayPopup,
    email,
    error,
    token,
  } = useResetPwd();

  return (
    <SignInLayout>
      <h1 className='mb-5 text-center'>{t('resetPwd.header')}</h1>
      <p className='mt-1 mb-6'>{t('resetPwd.headerHelper', { email: email })}</p>
      {error ? (
        <p className='mt-1 text-red-500'>{error}</p>
      ) : (
        <>
          {token && token.length > 0 && (
            <form className='space-y-6'>
              <PasswordFields
                pwd={pwd}
                setPwd={setPwd}
                confirmPwd={confirmPwd}
                setConfirmPwd={setConfirmPwd}
                onSubmit={onResetPwd}
                displayHelper={true}
                isLoading={isLoading}
              />
            </form>
          )}
          {displayPopup && (
            <ModalSlider
              title={t('resetPwd.modal.title')}
              open={true}
              onClose={() => setDisplayPopup(true)}
              onCloseOutside={false}
              displayCloseButton={false}
            >
              <p className='pt-4 text-gray-600'>{t('resetPwd.modal.text')}</p>

              <div className='pt-11'>
                <Link to={routes.HOME}>
                  <button className='btn btn-primary btn-xs lg:btn-sm'>
                    {t('resetPwd.modal.linkBtn')}
                  </button>
                </Link>
              </div>
            </ModalSlider>
          )}
        </>
      )}
    </SignInLayout>
  );
};

export default ResetPassword;
