const IconRIB = ({ width = 24, height = 24 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox={'0 0 24 24'}
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M3.875 14V7.70245'
        stroke='#855F0B'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M8 14V7.70245'
        stroke='#855F0B'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M12.125 14V7.70245'
        stroke='#855F0B'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M2.5 14H13.5'
        stroke='#FFC84E'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M2.5 7.7025H13.5V5.25088L8 3L2.5 5.25088V7.7025Z'
        fill='#FFC84E'
        stroke='#FFC84E'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export default IconRIB;
