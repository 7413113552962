import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';

import routes from '@/routes';

import IconClipboard from '../../../../assets/svg/IconClipboard';
import IconCreditCard from '../../../../assets/svg/IconCreditCard';
import IconDocumentCheck from '../../../../assets/svg/IconDocumentCheck';
import IconHeadphone from '../../../../assets/svg/IconHeadphone';
import IconHome from '../../../../assets/svg/IconHome';
import IconLabel from '../../../../assets/svg/IconLabel';
import IconPaperClip from '../../../../assets/svg/IconPaperClip';
import IconStar from '../../../../assets/svg/IconStar';
import IconUmbrella from '../../../../assets/svg/IconUmbrella';

interface NavLink {
  to: string;
  labelKey: string;
  icon: any;
}
const links: NavLink[] = [
  {
    to: routes.HOME,
    labelKey: 'nav.home',
    icon: IconHome,
  },
  /*
  {
    to: routes.FORFAIT,
    labelKey: 'nav.forfait',
    icon: IconLabel,
  },
  {
    to: routes.SINISTRES,
    labelKey: 'nav.sinistres',
    icon: IconUmbrella,
  },
*/
  {
    to: routes.DOCUMENTS,
    labelKey: 'nav.documents',
    icon: IconPaperClip,
  },
  {
    to: routes.FACTURES,
    labelKey: 'nav.factures',
    icon: IconDocumentCheck,
  },
  {
    to: routes.GARANTIES,
    labelKey: 'nav.garanties',
    icon: IconClipboard,
  },
  {
    to: routes.PAYMENTS,
    labelKey: 'nav.payments',
    icon: IconCreditCard,
  },
  /*
  {
    to: routes.ADVANTAGES,
    labelKey: 'nav.advantages',
    icon: IconStar,
  },
*/
  {
    to: routes.CONTACT,
    labelKey: 'nav.contact',
    icon: IconHeadphone,
  },
];

interface SidebarProps {
  onNavigate?: () => void;
  openModal: (open: boolean) => void;
}
const Sidebar = ({ onNavigate, openModal }: SidebarProps) => {
  const { t } = useTranslation();

  return (
    <nav className='sm:h-auto h-screen-90'>
      <ul>
        {links.map((link) => (
          <SidebarLink key={link.to} {...link} onNavigate={onNavigate} />
        ))}
        <li className='pt-6 lg:pt-10'>
          <button className='btn btn-primary btn-xs lg:btn-sm' onClick={() => openModal(true)}>
            {t('nav.declareSinistre')}
          </button>
        </li>
      </ul>
    </nav>
  );
};

interface SidebarLinkProps extends NavLink {
  onNavigate?: () => void;
}
const SidebarLink = ({ to, labelKey, icon: Icon, onNavigate }: SidebarLinkProps) => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const isActive = pathname.startsWith(to);
  return (
    <li onClick={onNavigate} className='py-3 h-10'>
      <Link
        to={to}
        className={`flex flex-nowrap items-center space-x-4 ${
          isActive ? 'text-gray-900' : 'text-gray-400'
        }`}
      >
        <Icon />
        <span className={`text-base ${isActive ? 'text-gray-900 font-bold' : 'text-gray-400'}`}>
          {t(labelKey)}
        </span>
      </Link>
    </li>
  );
};

export default Sidebar;
