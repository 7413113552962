import toast from 'react-hot-toast';
import { useMutation } from 'react-query';

import { paths } from '@/../generated/publicApi';
import { useDashboardState } from '@/context/DashBoard';
import useLocalStorage from '@/hooks/useLocalStorage';

import { usePublicApiAxios } from '../../../context/PublicApiAxios';

const usageUpdatePolicyPath = 'api/v1/underwriting/policy';
export type UpdatePolicy =
  paths['/api/v1/underwriting/policy']['put']['requestBody']['content']['application/json'];
type UpdatePolicyResult =
  paths['/api/v1/underwriting/policy']['put']['responses']['200']['content']['application/json'];

const useUpdatePolicy = () => {
  const axios = usePublicApiAxios();
  const [{ policyData }, setDashboard] = useDashboardState();
  const [, setUpdatePolicyRes] = useLocalStorage('updatePolicyRes', false);
  const updatePolicy = async (policy_in: UpdatePolicy) => {
    const policy: UpdatePolicy = {
      uw_policy_uuid: policyData?.policy_out?.uw_policy_uuid,
      ...policy_in,
    };
    const { data } = await axios.put<UpdatePolicyResult>(usageUpdatePolicyPath, policy);
    return data;
  };

  return useMutation(updatePolicy, {
    onSuccess: async (data) => {
      toast.success('Vos informations ont bien été enregistrées !');
      setUpdatePolicyRes(true);
      setDashboard((previousDashboard) => {
        if (previousDashboard && previousDashboard.policyData) {
          return {
            ...previousDashboard,
            policyData: {
              ...previousDashboard.policyData,
              policy_out: {
                ...previousDashboard.policyData?.policy_out,
                uw_past_insurer_name: data.uw_past_insurer_name,
                uw_past_insurer_policy_id: data.uw_past_insurer_policy_id,
                uw_past_subscriber_name: data.uw_past_insurer_name,
              },
            },
          };
        }
        return previousDashboard;
      });
    },
    onError: (arg) => {
      setUpdatePolicyRes(false);
    },
  });
};

export default useUpdatePolicy;
