import { Formule } from '@/components/utils/constants';

import FranchiseOptions from './FranchiseOptions';
import FranchiseSummary from './FranchiseSummary';
import { GarantiesCovered } from './GarantiesCovered';
import { GarantiesNotCovered } from './GarantiesNotCovered';
import InsuredDrivers from './InsuredDrivers';

interface ContractDetailsProps {
  franchiseBdgPrice: number;
  franchiseAccidentPrice: number;
  formule: Formule;
}

const ContractDetails = ({
  franchiseAccidentPrice,
  franchiseBdgPrice,
  formule,
}: ContractDetailsProps) => {
  return (
    <>
      <InsuredDrivers />
      {formule && <GarantiesCovered formule={formule} />}
      {/*  <FranchiseOptions /> */}
      {formule !== Formule.TOUS_RISQUES && formule && <GarantiesNotCovered formule={formule} />}
      {formule !== Formule.TIERS && (
        <FranchiseSummary
          formule={formule}
          bdgPrice={franchiseBdgPrice}
          accidentPrice={franchiseAccidentPrice}
        />
      )}
    </>
  );
};

export default ContractDetails;
