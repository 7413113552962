const IconLogo = ({ width = 87, height = 24 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox={'0 0 87 24'}
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M14.8992 23.677C14.4039 23.677 14.1562 23.4939 14.1562 23.1278V22.9986C14.1562 22.7833 14.3608 22.4495 14.77 21.9973C15.2007 21.5451 15.416 21.0713 15.416 20.576V5.39955C15.416 4.83965 15.2007 4.3336 14.77 3.88138C14.3608 3.42916 14.1562 3.11691 14.1562 2.94463V2.81543C14.1562 2.55702 14.3716 2.34167 14.8023 2.1694L18.8723 0.166706C19.303 -0.00556772 19.626 -0.0271017 19.8413 0.102104C20.0782 0.231309 20.1966 0.446652 20.1966 0.748133V20.576C20.1966 21.0713 20.4227 21.5451 20.875 21.9973C21.3487 22.4495 21.5856 22.7833 21.5856 22.9986V23.1278C21.5856 23.4939 21.3379 23.677 20.8427 23.677H14.8992Z'
        fill='#010038'
      />
      <path
        d='M24.1462 23.677C23.6725 23.677 23.4356 23.4939 23.4356 23.1278V22.9986C23.4356 22.7833 23.6402 22.4495 24.0493 21.9973C24.4585 21.5451 24.6631 21.0605 24.6631 20.5437V13.2436C24.6631 12.7052 24.4585 12.1992 24.0493 11.7254C23.6402 11.2517 23.4356 10.9286 23.4356 10.7564V10.6595C23.4356 10.4441 23.6617 10.218 24.1139 9.98114L28.1516 7.97845C28.5608 7.80617 28.8838 7.7954 29.1207 7.94614C29.3575 8.07535 29.476 8.30146 29.476 8.62448V20.5437C29.476 21.0605 29.7021 21.5451 30.1543 21.9973C30.6065 22.4495 30.8326 22.7833 30.8326 22.9986V23.1278C30.8326 23.4939 30.585 23.677 30.0897 23.677H24.1462Z'
        fill='#010038'
      />
      <path
        d='M39.0077 24C37.5003 24 36.3051 23.5262 35.4222 22.5787C34.5609 21.6097 34.1302 20.2207 34.1302 18.4118V10.0457H32.612C32.1813 10.0457 31.966 9.8304 31.966 9.39971V8.85059C31.966 8.39837 32.1813 8.17226 32.612 8.17226H33.4195C34.3024 8.17226 35.0454 7.88154 35.6483 7.30012C36.2728 6.71869 36.6712 5.94345 36.8435 4.97441C36.9512 4.45758 37.1988 4.19917 37.5864 4.19917H38.2325C38.7062 4.19917 38.9431 4.45758 38.9431 4.97441L38.9754 8.17226H45.9114C46.0837 8.17226 46.2344 8.23686 46.3636 8.36606C46.4928 8.49527 46.5574 8.65678 46.5574 8.85059V9.39971C46.5574 9.59352 46.4928 9.75503 46.3636 9.88423C46.2344 9.99191 46.0837 10.0457 45.9114 10.0457H38.9754V18.4764C38.9754 19.1009 39.1261 19.607 39.4276 19.9946C39.7506 20.3607 40.149 20.5437 40.6228 20.5437C41.7426 20.5437 42.4747 20.0592 42.8193 19.0902C43.0131 18.8102 43.25 18.7025 43.5299 18.7671C43.8314 18.8102 43.9821 18.9825 43.9821 19.284C43.9821 20.533 43.5299 21.6312 42.6255 22.5787C41.7426 23.5262 40.5366 24 39.0077 24Z'
        fill='#010038'
      />
      <path
        d='M51.5308 24C50.0234 24 48.8283 23.5262 47.9454 22.5787C47.084 21.6097 46.6533 20.2207 46.6533 18.4118V10.0457H45.1351C44.7045 10.0457 44.4891 9.8304 44.4891 9.39971V8.85059C44.4891 8.39837 44.7045 8.17226 45.1351 8.17226H45.9427C46.8256 8.17226 47.5685 7.88154 48.1715 7.30012C48.796 6.71869 49.1944 5.94345 49.3666 4.97441C49.4743 4.45758 49.7219 4.19917 50.1096 4.19917H50.7556C51.2293 4.19917 51.4662 4.45758 51.4662 4.97441L51.4985 8.17226H55.2132C55.3855 8.17226 55.5362 8.23686 55.6654 8.36606C55.7946 8.49527 55.8592 8.65678 55.8592 8.85059V9.39971C55.8592 9.59352 55.7946 9.75503 55.6654 9.88423C55.5362 9.99191 55.3855 10.0457 55.2132 10.0457H51.4985V18.4764C51.4985 19.1009 51.6493 19.607 51.9507 19.9946C52.2738 20.3607 52.6721 20.5437 53.1459 20.5437C54.2657 20.5437 54.9979 20.0592 55.3424 19.0902C55.5362 18.8102 55.7731 18.7025 56.053 18.7671C56.3545 18.8102 56.5053 18.9825 56.5053 19.284C56.5053 20.533 56.053 21.6312 55.1486 22.5787C54.2657 23.5262 53.0598 24 51.5308 24Z'
        fill='#010038'
      />
      <path
        d='M70.7111 18.7671C70.9049 18.5087 71.1418 18.4226 71.4217 18.5087C71.7017 18.5949 71.8309 18.7994 71.8093 19.1225C71.7017 20.2422 71.0556 21.3405 69.8713 22.4172C68.6869 23.4724 67.1472 24 65.2521 24C62.9695 24 61.096 23.2463 59.6317 21.7389C58.1674 20.2315 57.4352 18.2503 57.4352 15.7954C57.4352 13.4912 58.1997 11.5962 59.7286 10.1103C61.2575 8.60294 63.0987 7.84924 65.2521 7.84924C67.2118 7.84924 68.7192 8.31223 69.7743 9.2382C70.8295 10.1642 71.3571 11.2301 71.3571 12.4361C71.3571 13.4266 70.8834 14.1373 69.9359 14.5679L62.7649 17.8304C63.6478 19.7039 65.0691 20.6406 67.0287 20.6406C67.847 20.6406 68.5792 20.4683 69.2252 20.1238C69.8928 19.7577 70.3881 19.3055 70.7111 18.7671ZM64.6707 9.27051C63.8309 9.27051 63.1633 9.67966 62.668 10.498C62.1727 11.2947 61.9251 12.3176 61.9251 13.5666C61.9251 14.6218 62.0328 15.5478 62.2481 16.3445L66.415 14.2449C66.9749 13.965 67.2548 13.4589 67.2548 12.7268C67.2548 11.9946 67.018 11.2409 66.5442 10.4657C66.0705 9.66889 65.446 9.27051 64.6707 9.27051Z'
        fill='#010038'
      />
      <path
        d='M83.3155 7.84924C84.2415 7.84924 84.9629 8.12919 85.4797 8.68908C86.018 9.22744 86.2872 9.9273 86.2872 10.7887C86.2872 12.4899 85.5981 13.3405 84.2199 13.3405C83.66 13.3405 83.2186 13.2005 82.8956 12.9206C82.5941 12.6191 82.411 12.2961 82.3464 11.9515C82.2818 11.607 82.1526 11.2947 81.9588 11.0148C81.765 10.7133 81.4743 10.5626 81.0867 10.5626C80.5052 10.5626 80.0746 10.9071 79.7946 11.5962C79.5147 12.2853 79.3747 13.1251 79.3747 14.1157V20.576C79.3747 20.9636 79.5039 21.3082 79.7623 21.6097C80.0423 21.8896 80.3114 22.1373 80.5699 22.3526C80.8498 22.5679 80.9898 22.7833 80.9898 22.9986V23.1278C80.9898 23.4939 80.7529 23.677 80.2792 23.677H74.3034C73.8081 23.677 73.5604 23.4939 73.5604 23.1278V22.9986C73.5604 22.7833 73.765 22.4495 74.1742 21.9973C74.6049 21.5451 74.8202 21.0713 74.8202 20.576V13.0175C74.8202 12.5007 74.5726 12.0377 74.0773 11.6285C73.582 11.2194 73.3343 10.9071 73.3343 10.6918V10.5626C73.3343 10.2611 73.5604 10.0027 74.0127 9.78733L77.8888 7.97845C78.5779 7.65543 78.9225 7.87078 78.9225 8.62448V11.0471C79.6547 8.91519 81.119 7.84924 83.3155 7.84924Z'
        fill='#010038'
      />
      <path
        d='M27.0885 5.87908C28.036 5.87908 28.7897 5.62066 29.3496 5.10384C29.931 4.56548 30.2217 3.87638 30.2217 3.03655C30.2217 2.21824 29.931 1.55068 29.3496 1.03385C28.7897 0.495497 28.036 0.226318 27.0885 0.226318C26.141 0.226318 25.3657 0.495497 24.7628 1.03385C24.1813 1.55068 23.8906 2.21824 23.8906 3.03655C23.8906 3.87638 24.1813 4.56548 24.7628 5.10384C25.3657 5.62066 26.141 5.87908 27.0885 5.87908Z'
        fill='#5B93FF'
      />
      <path
        d='M11.7324 1.13055C10.7848 0.376851 9.64353 0 8.3084 0C6.28417 0 4.74447 0.57066 3.68929 1.71198C2.63411 2.8533 2.10652 4.3284 2.10652 6.13728V8.17227H0.678331C0.484522 8.17227 0.323015 8.23687 0.193809 8.36608C0.064603 8.49529 0 8.64603 0 8.8183V9.43203C0 9.84118 0.22611 10.0458 0.678331 10.0458H2.10652V20.5437C2.10652 21.0606 1.89117 21.5451 1.46049 21.9973C1.0298 22.4495 0.814458 22.7833 0.814458 22.9986V23.1279C0.814458 23.4939 1.04057 23.677 1.49279 23.677H7.79158C8.20073 23.677 8.4053 23.4939 8.4053 23.1279V22.9986C8.4053 22.7833 8.26533 22.568 7.98538 22.3526C7.72697 22.1373 7.45779 21.8789 7.17785 21.5774C6.91944 21.2759 6.79023 20.9314 6.79023 20.5437V10.0458H10.6018C10.7741 10.0458 10.9248 9.99192 11.054 9.88425C11.1832 9.75504 11.2478 9.6043 11.2478 9.43203V8.8183C11.2478 8.64603 11.1832 8.49529 11.054 8.36608C10.9248 8.23687 10.7741 8.17227 10.6018 8.17227H6.66102C6.28571 7 5.68064 3.07556 6.28571 2C6.55031 1.52967 6.94251 1.28937 7.48087 1.28937C7.93309 1.28937 8.30105 1.44014 8.57143 1.71198C9.06228 2.20548 9.32986 3.19479 9.57143 4C10 5.42857 11.7902 5.92413 12.7143 5C13 4.71428 13.1859 4.20996 13.1859 3.77927C13.1859 2.76716 12.7014 1.88425 11.7324 1.13055Z'
        fill='#010038'
      />
    </svg>
  );
};

export default IconLogo;
