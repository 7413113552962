import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';

import common from '../../translations/common';

i18next.use(initReactI18next).init({
  resources: {
    fr: {
      common,
    },
  },
  lng: 'fr',
  ns: ['common'],
  defaultNS: 'common',
  load: 'languageOnly',
  debug: import.meta.env.DEV,
  interpolation: {
    escapeValue: false,
  },
});

export default i18next;
