import { useEffect } from 'react';
import { Route, Switch, useHistory } from 'react-router-dom';

import { useAuth } from '@/context/Auth';
import routes from '@/routes';

import SideBarLayout from '../layouts/SideBarLayout';
/*import Advantages from '../pages/dashboard/Advantages';*/
import Contact from '../pages/dashboard/Contact';
import Documents from '../pages/dashboard/Documents';
import Factures from '../pages/dashboard/Factures';
import Forfait from '../pages/dashboard/Forfait';
import Garanties from '../pages/dashboard/Garanties';
import Home from '../pages/dashboard/Home';
import Payments from '../pages/dashboard/Payments';
import Profile from '../pages/dashboard/Profile';
import Sinistres from '../pages/dashboard/Sinistres';
import PrivateRoute from './PrivateRoute';

const PrivatePages = () => {
  const [{ isLogged }] = useAuth();
  const { push } = useHistory();

  useEffect(() => {
    if (!isLogged)
      push({
        pathname: routes.SIGNIN,
      });
  }, []);

  return (
    <SideBarLayout>
      <Switch>
        <PrivateRoute path={routes.HOME} component={Home} />
        <PrivateRoute path={routes.PAYMENTS} component={Payments} />
        {/*<PrivateRoute path={routes.ADVANTAGES} component={Advantages} />*/}
        <PrivateRoute path={routes.CONTACT} component={Contact} />
        <PrivateRoute path={routes.PROFILE} component={Profile} />
        {/*<PrivateRoute path={routes.FORFAIT} component={Forfait} />*/}
        <PrivateRoute path={routes.GARANTIES} component={Garanties} />
        <PrivateRoute path={routes.DOCUMENTS} component={Documents} />
        <PrivateRoute path={routes.FACTURES} component={Factures} />
        {/*<PrivateRoute path={routes.SINISTRES} component={Sinistres} />*/}
        <PrivateRoute path={routes.ROOT} exact component={Home} />
        <Route path='*' component={Home} />
      </Switch>
    </SideBarLayout>
  );
};

export default PrivatePages;
