import { Redirect, Route, RouteProps, useLocation } from 'react-router-dom';

import { useAuth } from '@/context/Auth';
import routes from '@/routes';

const PrivateRoute = (props: RouteProps) => {
  const location = useLocation();
  const [{ isLogged, isReady }] = useAuth();

  if (!isReady) return null;

  if (isLogged) return <Route {...props} />;

  return <Redirect to={{ pathname: routes.SIGNIN, state: { from: location } }} />;
};

export default PrivateRoute;
