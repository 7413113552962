const IconPaymentCardFlat = ({ width = 24, height = 24 }) => {
  return (
    <svg width='50' height='50' viewBox='0 0 50 50' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <rect width='50' height='50' rx='25' fill='#F6F6F6' />

      <path
        d='M16 22H34'
        stroke='#1E2044'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M22.57 26H20'
        stroke='#1E2044'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M31 32H19C17.343 32 16 30.657 16 29V21C16 19.343 17.343 18 19 18H31C32.657 18 34 19.343 34 21V29C34 30.657 32.657 32 31 32Z'
        stroke='#1E2044'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export default IconPaymentCardFlat;
